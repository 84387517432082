import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  formRadio: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  radioLabel: {
    background: 'white',
    borderRadius: '5px',
    border: 'rgba(0, 0, 0, 0.23) 1px solid',
    paddingRight: '1rem',
  },

  labelForm: {
    color: 'gray',
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingRight: '2rem',
    textTransform: 'uppercase',
    minWidth: '110px',
    textAlign: 'end',
  },
}))

export interface OwnProps {
  correctAnswer: number
  onChangeAnswer: (correctAnswer: number) => void
}

export type Props = OwnProps

const QuestionRadioButton = ({ correctAnswer, onChangeAnswer }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentAnswer, setCurrentAnswer] = useState<number>(correctAnswer)

  useEffect(() => {
    setCurrentAnswer(correctAnswer)
  }, [correctAnswer])

  const handleChangeReponse = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCorrectAnswer = (event.target as HTMLInputElement).value
      const newFloatCorrectAnswer = parseFloat(newCorrectAnswer)
      if (!Number.isNaN(newFloatCorrectAnswer)) {
        setCurrentAnswer(newFloatCorrectAnswer)
        onChangeAnswer(newFloatCorrectAnswer)
      }
    },
    [onChangeAnswer]
  )

  return (
    <>
      <FormControl className={classes.formRadio} component="fieldset" fullWidth color="secondary">
        <p className={classes.labelForm}>{t('reponse')}</p>
        <RadioGroup row aria-label="gender" name="gender1" value={currentAnswer} onChange={handleChangeReponse}>
          <FormControlLabel className={classes.radioLabel} value={0} control={<Radio />} label={`${t('r')} 1`} />
          <FormControlLabel className={classes.radioLabel} value={1} control={<Radio />} label={`${t('r')} 2`} />
          <FormControlLabel className={classes.radioLabel} value={2} control={<Radio />} label={`${t('r')} 3`} />
          <FormControlLabel className={classes.radioLabel} value={3} control={<Radio />} label={`${t('r')} 4`} />
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default QuestionRadioButton
