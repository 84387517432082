import { AnyAction } from 'redux'
import {
  call, put, takeLatest,
} from 'redux-saga/effects'

import IHistoryCalculations from '../../interfaces/IHistoryCalculations'
import ISessionPaginationResults from '../../interfaces/ISessionPaginationResults'
import SessionModel from '../../models/session.model'
import * as CONSTANTS from '../actions/constants'
import {
  getSessionsByIdClientActionDone,
  getSessionsByIdClientActionFailed,
  getSessionsPaginationActionDone,
  getSessionsPaginationActionFailed,
  getSessionsHistoryActionDone,
  getSessionsHistoryActionFailed,
} from '../actions/session.actions'
import Api from '../apis/api-factory'

function* getSessionsById(action: AnyAction) {
  const clientId = action.payload

  try {
    const sessions: SessionModel[]|[] = yield call(Api.getSessionsByIdClient, clientId)

    yield put(getSessionsByIdClientActionDone(sessions))
  } catch (e) {
    yield put(getSessionsByIdClientActionFailed(e as Error))
  }
}

function* getSessionsPagination(action: AnyAction) {
  try {
    const {
      limit, page, clientId, startDate, endDate, isFacturable,
    } = action.payload
    const results: ISessionPaginationResults = yield call(
      Api.getSessionsPagination, limit, page, clientId, startDate, endDate, isFacturable,
    )

    if (results.error) {
      yield put(getSessionsPaginationActionFailed(new Error(results.message)))
    } else {
      yield put(getSessionsPaginationActionDone({
        sessions: results.sessions,
        total: results.total,
      }))
    }
  } catch (e) {
    yield put(getSessionsPaginationActionFailed(e as Error))
  }
}

function* getSessionsHistory(action: AnyAction) {
  const { clientId, startDate, endDate } = action.payload

  try {
    const historyCalculations: IHistoryCalculations = yield call(Api.getSessionsHistory, clientId, startDate, endDate)

    yield put(getSessionsHistoryActionDone(historyCalculations))
  } catch (e) {
    yield put(getSessionsHistoryActionFailed(e as Error))
  }
}

export default function* roomsSaga() {
  yield takeLatest(CONSTANTS.GET_SESSIONS_BY_ID_CLIENT, getSessionsById)
  yield takeLatest(CONSTANTS.GET_SESSIONS_PAGINATION, getSessionsPagination)
  yield takeLatest(CONSTANTS.GET_SESSIONS_HISTORY, getSessionsHistory)
}
