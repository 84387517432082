import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface StateProps {
  title?: string
  isOpenDialog: boolean
  setOpenDialog: (isOpen: boolean) => void
  confirmeDelete: () => void
  questiontitle: string
  validationText: string
}

export type Props = StateProps

export default function DialogDelete({
  questiontitle,
  title,
  isOpenDialog,
  validationText,
  setOpenDialog,
  confirmeDelete,
}: Props) {
  // Translation
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    setOpenDialog(false)
  }, [setOpenDialog])

  const deleteObject = useCallback(() => {
    confirmeDelete()
  }, [confirmeDelete])

  return (
    <div>
      <Dialog
        open={isOpenDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {questiontitle}{' '}
          <strong>
            <em>{title}</em>
          </strong>{' '}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{validationText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('annuler')}
          </Button>
          <Button onClick={deleteObject} color="secondary" autoFocus>
            {t('valider')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
