import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import {
  getQuestionsAction,
  updateMultipleQuestionsAction,
  updateQuestionAction,
} from '../../redux/actions/question.actions'
import {
  addQuestionnaireAction,
  getQuestionnaireAction,
  updateQuestionnaireAction,
} from '../../redux/actions/questionnaire.actions'
import { getTagsAction } from '../../redux/actions/tag.actions'
import { getThemesAction } from '../../redux/actions/theme.actions'
import { RootStore } from '../../redux/store/root.store'
import QuestionPage, { DispatchesProps, StateProps, OwnProps } from './index'

function mapStateToProps(store: RootStore, own: OwnProps): StateProps & OwnProps {
  return {
    questionnaire: store.questionnaires.selectedQuestionnaire,
    questions: store.questions,
    idQuestionnaire: own.idQuestionnaire,
    themes: store.themes,
    tags: store.tags,
    toEdit: own.toEdit,
    currentUser: store.clients.currentUser,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getQuestions: (idQuestionnaire: number) => dispatch(getQuestionsAction(idQuestionnaire)),
    getQuestionnaire: (idQuestionnaire: number) => dispatch(getQuestionnaireAction(idQuestionnaire)),
    addQuestionnaire: (questionnaire: QuestionnaireModel) => dispatch(addQuestionnaireAction(questionnaire)),
    updateQuestionnaire: (questionnaire: QuestionnaireModel) => dispatch(updateQuestionnaireAction(questionnaire)),
    getThemes: (idClient: number) => dispatch(getThemesAction(idClient)),
    getTags: () => dispatch(getTagsAction()),
    updateQuestion: (question: QuestionModel) => dispatch(updateQuestionAction(question)),
    updateMultipleQuestions: (questions: QuestionModel[]) => dispatch(updateMultipleQuestionsAction(questions)),
  }
}

const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionPage)

export default QuestionContainer
