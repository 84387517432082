import { Checkbox, makeStyles } from '@material-ui/core'
import React, { useCallback, ReactNode } from 'react'

export interface TextsStyle {
  fullWidth: boolean | undefined
  backgroundColor: string | undefined
}
const useStyles = makeStyles(() => ({
  textIcon: {
    color: 'red',
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    padding: '.2rem',
  },
  textsSelectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props: TextsStyle) => (typeof props.fullWidth !== 'undefined' ? '85%' : '6rem'),
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: (props: TextsStyle) =>
      typeof props.backgroundColor !== 'undefined' ? props.backgroundColor : 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    height: '100%',
    color: '#7d7b7b !important',
    fontWeight: 600,
    margin: '0 auto',
  },
  textCheckBox: {
    padding: '.4rem',
    color: '#7d7b7b !important',
  },
}))

export interface OwnProps {
  onClick: (isChecked: boolean) => void
  currentChecked: boolean
  fullWidth?: boolean
  backgroundColor?: string
  children: ReactNode
}

export type Props = OwnProps

const TextCheckBox = ({ onClick, currentChecked, fullWidth, backgroundColor, children }: Props) => {
  const classes = useStyles({ fullWidth, backgroundColor })

  const handlOnChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onClick(checked)
    },
    [onClick]
  )

  return (
    <div className={classes.textsSelectContainer}>
      {children}
      <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }}
        onChange={handlOnChange}
        checked={currentChecked}
        className={classes.textCheckBox}
      />
    </div>
  )
}
export default TextCheckBox
