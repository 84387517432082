import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as localStorage from 'local-storage'
import { initReactI18next } from 'react-i18next'

import EN from './assets/locales/EN/translation.json'
import FR from './assets/locales/FR/translation.json'

const resources = {
  EN: {
    translation: EN,
  },
  FR: {
    translation: FR,
  },
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: (localStorage.get('i18nextLng') as string) || 'FR',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  })
export default i18n
