import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import ThemeModel from '../../models/theme.model'
import * as CONSTANTS from '../actions/constants'
import {
  addThemeActionDone,
  addThemeActionFailed,
  deleteThemeActionDone,
  deleteThemeActionFailed,
  getThemeActionDone,
  getThemeActionFailed,
  getThemesActionDone,
  getThemesActionFailed,
  updateThemeActionDone,
  updateThemeActionFailed,
} from '../actions/theme.actions'
import Api from '../apis/api-factory'

function* getThemes(action: AnyAction) {
  try {
    const clientId = action.payload
    const themes: ThemeModel[] | [] = yield call(Api.getThemes, clientId)
    yield put(getThemesActionDone(themes))
  } catch (e) {
    yield put(getThemesActionFailed(e as Error))
  }
}

function* getTheme(action: AnyAction) {
  try {
    const themeId = action.payload
    const theme: ThemeModel = yield call(Api.getTheme, themeId)
    yield put(getThemeActionDone(theme))
  } catch (e) {
    yield put(getThemeActionFailed(e as Error))
  }
}

function* addTheme(action: AnyAction) {
  try {
    const theme = action.payload
    const themeSaved: ThemeModel = yield call(Api.addTheme, theme)
    yield put(addThemeActionDone(themeSaved))
  } catch (e) {
    yield put(addThemeActionFailed(e as Error))
  }
}

function* updateTheme(action: AnyAction) {
  try {
    const theme = action.payload
    yield call(Api.updateTheme, theme)
    yield put(updateThemeActionDone(theme))
  } catch (e) {
    yield put(updateThemeActionFailed(e as Error))
  }
}

function* deleteTheme(action: AnyAction) {
  try {
    const themeId = action.payload
    yield call(Api.deleteTheme, themeId)
    yield put(deleteThemeActionDone(themeId))
  } catch (e) {
    yield put(deleteThemeActionFailed(e as Error))
  }
}

export default function* themeSaga() {
  yield takeLatest(CONSTANTS.GET_THEMES, getThemes)
  yield takeLatest(CONSTANTS.GET_THEME, getTheme)
  yield takeLatest(CONSTANTS.ADD_THEME, addTheme)
  yield takeLatest(CONSTANTS.UPDATE_THEME, updateTheme)
  yield takeLatest(CONSTANTS.DELETE_THEME, deleteTheme)
}
