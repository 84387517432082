import { Button, TextField, Paper, Box, MenuItem, FormControl, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as localStorage from 'local-storage'
import React, { useEffect } from 'react'
import './App.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as config from '../package.json'
import logo from './assets/logo.png'
import { loginClientAction } from './redux/actions/client.actions'
import { RootStore } from './redux/store/root.store'
import isNotEmpty from './utils/string-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    marginBottom: '5rem',
  },
  paper: {
    width: 'fit-content',
    padding: '4rem 6rem 1rem 6rem',
    alignItems: 'center',
    border: '.1rem solid #9c9c9c',
    borderRadius: '.5rem',
    backgroundColor: '#555',
    backgroundImage:
      // eslint-disable-next-line max-len
      'linear-gradient(45deg, #575757 25%, transparent 25%, transparent 75%, #575757 75%, #575757), linear-gradient(-45deg, #575757 25%, transparent 25%,  transparent 75%, #575757 75%, #575757);',
    backgroundSize: '.7rem .7rem',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: '#ffffff',
  },
  select: {
    color: '#ffffff',
    textAlign: 'left',
    fontWeight: 'bold',
    '&:before': {
      borderColor: 'rgb(255, 255, 255)',
    },
    '&:after': {
      borderColor: 'rgb(255, 255, 255)',
    },
  },
  icon: {
    fill: 'rgb(255, 255, 255)',
  },
  textField: {
    minWidth: '300px',
    paddingBottom: '1rem',
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.91)',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgb(255,255,255,0.91)',
    },
    '& > div, & input': {
      borderRadius: '.5rem',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    width: 'fit-content',
    fontWeight: 'bold',
    color: '#222222',
    padding: '.45rem 2.8rem',
    borderRadius: '.5rem',
    marginTop: '.7rem',
    fontSize: '.6rem',
    '& .MuiButton-label': {
      alignItems: 'flex-end',
      '& .first-letter': {
        fontSize: '2.4em',
        marginBottom: '-0.089em',
      },
      '& .rest': {
        fontSize: '2em',
      },
    },
  },
  wrongData: {
    marginTop: '2rem',
    marginBottom: '1rem',
    fontWeight: 900,
    fontStyle: 'italic',
    color: '#ffffff',
  },
}))

interface State {
  mail: string
  motDePasse: string
}

function App() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  // Translation
  const { t, i18n } = useTranslation()

  const initialLang = (localStorage.get('i18nextLng') as string) || 'FR'

  const [values, setValues] = React.useState<State>({
    mail: '',
    motDePasse: '',
  })
  const [loginFaild, setLoginFaild] = React.useState<boolean>(false)
  const [lang, setLang] = React.useState<string>(initialLang)

  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)
  const isLoading = useSelector((state: RootStore) => state.clients.loading)
  const errorMsg = useSelector((state: RootStore) => state.clients.error)

  console.log('Heure du navigateur : ', new Date(Date.now()))
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  console.log('Timezone du navigateur : ', timeZone)
  const ank = new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })
  console.log('Heure du navigateur avec la timezone FR : ', ank)

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleLogin = () => {
    dispatch(loginClientAction(values))
  }

  const handleChangeLanguage = (event: any) => {
    setLang(event.target.value)
    i18n.changeLanguage(event.target.value)
    localStorage.set('i18nextLng', event.target.value)
    setLoginFaild(false)
  }

  useEffect(() => {
    if (currentClient && localStorage.get('quiz.admin.jwt')) {
      if (currentClient.isAdmin) {
        history.push('/home')
      } else {
        history.push('/questionnaires')
      }
    }
  }, [currentClient, history])

  useEffect(() => {
    if (errorMsg && errorMsg?.length) {
      setLoginFaild(true)
    }
  }, [errorMsg])

  return (
    <div className="App">
      <img src={logo} width="300" alt="" />
      <div className={classes.root}>
        <Paper className={classes.paper} variant="outlined">
          <form>
            <TextField
              className={classes.textField}
              label={t('login')}
              variant="filled"
              name="login"
              color="secondary"
              value={values.mail}
              onChange={handleChange('mail')}
            />
            <TextField
              className={classes.textField}
              label={t('password')}
              type="password"
              variant="filled"
              name="password"
              color="secondary"
              value={values.motDePasse}
              onChange={handleChange('motDePasse')}
            />
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonLogin}
              disabled={isLoading}
              onClick={handleLogin}
            >
              <span className="rest">{t('loginButton')}</span>
            </Button>
            <Box visibility={loginFaild ? 'visible' : 'hidden'} className={classes.wrongData}>
              {isNotEmpty(errorMsg) && t('loginError')}
            </Box>
          </form>
        </Paper>

        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            onChange={handleChangeLanguage}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            className={classes.select}
          >
            <MenuItem value="FR">FR</MenuItem>
            <MenuItem value="EN">EN</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          color: 'rgb(255 255 255 / 24%)',
          position: 'fixed',
          right: 0,
          bottom: 0,
          padding: '1rem 4rem',
          fontSize: '12px',
        }}
      >
        {`v${config.version}`}
      </div>
    </div>
  )
}

export default App
