import './Dashboard.css'
import { Container, Grid, Button, AppBar, Tabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as localStorage from 'local-storage'
import React, { useCallback, useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import logo from './assets/logo.png'
import LogoutIcon from './assets/logout_white_24dp.svg'
import ClientForm from './components/clients/client-form/container'
import Clients from './components/clients/container'
import QuestionForm from './components/question-form/container'
import Questions from './components/questionnaire-form/container'
import Questionnaires from './components/questionnaires/container'
import QuestionnaireQuestionsDetails from './components/questionnaires/questionnaire-questions-details/container'
import QuestionnaireQuestions from './components/questionnaires/questionnaire-questions/container'
import Sessions from './components/sessions/container'
import TabPanel from './components/tab-panel'
import Themes from './components/themes/container'
import ClientModel from './models/client.model'
import QuestionModel from './models/question.model'
import { authClientAction, logoutClientAction } from './redux/actions/client.actions'
import { RootStore } from './redux/store/root.store'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    minWidth: '100%',
    width: '100%',
    textAlign: 'left',
    margin: '0',
    padding: '0',
  },
  header: {
    height: '15%',
    minWidth: '100%',
    width: '100%',
    verticalAlign: 'middle',
    background: 'linear-gradient( #000000, #2e2e2e)',
    margin: '0',
    minHeight: '150px',
  },
  logo: {
    paddingTop: '2.5rem',
  },
  body: {
    height: '85%',
    minWidth: '100%',
    margin: '0',
    padding: '0',
    backgroundColor: '#f1F1F1',
  },
  textField: {
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
      borderRadius: '10px 10px 10px 10px',
      innerWidth: '100px',
    },
  },

  link: {
    color: '#ffffff',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  centerGrid: {
    display: 'grid',
    placeContent: 'center',
    color: '#ffffff',
    marginTop: '-0.3rem',
  },
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  tabs: {
    minHeight: '48px',
    '& .MuiButtonBase-root': {
      fontWeight: 'bold',
    },
    '& .MuiTabs-indicator': {
      background: 'white',
    },
  },
  tab: {
    backgroundColor: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  panelContainer: {
    backgroundColor: '#f1f1f1',
    minHeight: '80vh',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    color: '#ffffff',
    '&:before': {
      borderColor: 'rgb(255, 255, 255)',
    },
    '&:after': {
      borderColor: 'rgb(255, 255, 255)',
    },
  },
  icon: {
    fill: 'rgb(255, 255, 255)',
  },

  questionnairesList: {
    width: '100%',
    minWidth: '100%',
  },
  welcomeHeader: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  containerLogout: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

interface State {
  tab: number
  page: string
  data: number
  idQuestion: number
  question: QuestionModel
  client: ClientModel
  toEdit: boolean
  adminDisplay: boolean
}

function Dashboard() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  // Translation
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState((location?.state as State)?.tab ?? 0)
  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)
  const errorMsg = useSelector((state: RootStore) => state.clients.error)
  const currentQuestionnaire = useSelector((state: RootStore) => state.questionnaires.selectedQuestionnaire)

  const adminDisplay = currentClient?.isAdmin || currentQuestionnaire?.idClient !== -1

  useLayoutEffect(() => {
    if (!currentClient) {
      dispatch(authClientAction())
    }
    // check if authenticated
  }, [currentClient, dispatch])

  const getQuestionnairesTabPanel = useCallback(() => {
    const currentLocation: string | undefined = (location?.state as State)?.page
    const idQuestionnaire: number | undefined = (location?.state as State)?.data
    const question: QuestionModel | undefined = (location?.state as State)?.question
    const EditQuestionnaire: boolean | undefined = (location?.state as State)?.toEdit
    const idQuestion: number | undefined = (location?.state as State)?.idQuestion

    switch (currentLocation) {
      case 'questionnaires':
        return <Questionnaires />
      case 'questionnaire':
        return adminDisplay ? (
          <Questions idQuestionnaire={idQuestionnaire} toEdit={EditQuestionnaire} />
        ) : (
          <QuestionnaireQuestions idQuestionnaire={idQuestionnaire} />
        )
      case 'questions-details':
        return <QuestionnaireQuestionsDetails idQuestion={idQuestion} />
      case 'question/new':
        return <QuestionForm toEdit={false} question={question} />
      case 'question/update':
        return <QuestionForm toEdit question={question} />
      default:
        return <Questionnaires />
    }
  }, [adminDisplay, location?.state])

  const getClientsTabPanel = useCallback(() => {
    const currentLocation: string | undefined = (location?.state as State)?.page
    const idClient: number | undefined = (location?.state as State)?.data
    const client: ClientModel | undefined = (location?.state as State)?.client

    if (typeof currentLocation !== 'undefined') {
      if (currentLocation === 'clients') {
        return <Clients />
      }
      if (currentLocation === 'client') {
        return <ClientForm toEdit={false} />
      }
      if (currentLocation === 'EditClient') {
        return <ClientForm toEdit idClient={idClient} client={client} />
      }
      if (
        currentLocation === 'sessions' &&
        typeof client?.raisonSocial !== 'undefined' &&
        typeof client?.id !== 'undefined' &&
        typeof client?.createdAt !== 'undefined'
      ) {
        return <Sessions nameClient={client.raisonSocial} idClient={client.id} />
      }
    }
    return <Clients />
  }, [location?.state])

  
  const logout = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(logoutClientAction())
      localStorage.set('quiz.admin.jwt', null)
      history.push('/')
    },
    [dispatch, history]
  )

 const getPageByTabId = useCallback((id) => {
    switch (id) {
      case 0:
        return 'clients'
      case 1:
        return 'questionnaires'
      case 2:
        return 'themes'

      default:
        return ''
    }
  }, [])

  const handleChangeTab = useCallback(
    (_, tabId: number) => {
      const page: string = getPageByTabId(tabId)
      history.push({
        pathname: `/${page}`,
        state: { tab: tabId, page },
      })
      setSelectedTab(tabId)
    },
    [history, getPageByTabId]
  )

  useLayoutEffect(() => {
    if (errorMsg || !localStorage.get('quiz.admin.jwt')) {
      localStorage.set('quiz.admin.jwt', null)
      history.push('/')
    }
  }, [currentClient, errorMsg, history])

  return (
    <Container className={classes.root}>
      <Container className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.logo}>
            <img src={logo} width="200px" alt="" />
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={3}>
            <div className={classes.containerLogout}>
              <p className={classes.welcomeHeader}>{`${t('welcome')} ${currentClient?.nom || ''} ${
                currentClient?.isAdmin ? '(ADMIN)' : ''
              }`}</p>
              <Button className={classes.centerGrid} onClick={logout}>
                <img src={LogoutIcon} alt="logout" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
         <Container className={classes.body}>
        <div className={classes.tabsContainer}>
          <AppBar position="static">
            <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="tabs" className={classes.tabs}>
              {currentClient?.isAdmin && (
                <Tab
                  label={t('clients')}
                  id="client-tab-1"
                  aria-controls="client-tabpanel-1"
                  classes={{ selected: classes.tab }}
                />
              )}
              <Tab
                label={t('questionnaires')}
                id="simple-tab-2"
                aria-controls="simple-tabpanel-2"
                classes={{ selected: classes.tab }}
              />
              <Tab
                label={t('theme')}
                id="simple-tab-3"
                aria-controls="simple-tabpanel-3"
                classes={{ selected: classes.tab }}
              />
            </Tabs>
          </AppBar>

          {currentClient?.isAdmin && (
            <TabPanel value={selectedTab} index={0} className={classes.panelContainer}>
              {getClientsTabPanel()}
            </TabPanel>
          )}

          <TabPanel value={selectedTab} index={currentClient?.isAdmin ? 1 : 0} className={classes.panelContainer}>
            {getQuestionnairesTabPanel()}
          </TabPanel>

          <TabPanel value={selectedTab} index={currentClient?.isAdmin ? 2 : 1} className={classes.panelContainer}>
            <Themes />
          </TabPanel>
        </div>
      </Container>
    </Container>
  )
}
export default Dashboard
