import { all, fork } from 'redux-saga/effects'

import clientSaga from './client.saga'
import questionSaga from './question.saga'
import questionnaireSaga from './questionnaire.saga'
import sessionSaga from './session.saga'
import tagSaga from './tag.saga'
import themeSaga from './theme.saga'

export default function* rootSaga() {
  yield all(
    [fork(questionnaireSaga), fork(questionSaga), fork(themeSaga), fork(tagSaga), fork(clientSaga), fork(sessionSaga)],
  )
}
