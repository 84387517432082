import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ISessionPaginationRequest from '../../interfaces/ISessionPaginationRequest'
import {
  getSessionsHistoryAction, getSessionsPaginationAction,
} from '../../redux/actions/session.actions'
import { RootStore } from '../../redux/store/root.store'
import SessionsPage, { DispatchesProps, StateProps, OwnProps } from './index'

function mapStateToProps(store: RootStore, own: OwnProps): StateProps & OwnProps {
  return {
    idClient: own.idClient,
    nameClient: own.nameClient,
    sessions: store.sessions.sessions,
    sessionsTotal: store.sessions.sessionPaginationTotalItems,
    historyCalculations: store.sessions.historyCalculations,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getSessionsPagination: (ipaginationRequestInput: ISessionPaginationRequest) => dispatch(
      getSessionsPaginationAction(ipaginationRequestInput),
    ),
    getSessionsHistory: (clientId: number, startDate: string, endDate: string) => dispatch(getSessionsHistoryAction({
      clientId,
      startDate,
      endDate,
    })),
  }
}

const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(SessionsPage)

export default QuestionContainer
