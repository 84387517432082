import { AnyAction } from 'redux'

import QuestionModel from '../../models/question.model'
import * as CONSTANTS from '../actions/constants'

export type QuestionsState = QuestionModel[]

const initialState: QuestionsState = []

export default function questionReducer(state = initialState, action: AnyAction): QuestionsState {
  switch (action.type) {
    case CONSTANTS.GET_QUESTIONS_DONE:
      return action.payload || []
    case CONSTANTS.GET_QUESTION_DONE:
      return action.payload || {}
    case CONSTANTS.ADD_QUESTION_DONE:
      return [...state, { ...action.payload, questionnaire: undefined }]
    case CONSTANTS.DELETE_QUESTION_DONE:
      return state.filter((question) => question.id !== action.payload)
    case CONSTANTS.UPDATE_QUESTION_DONE:
      return state.map((question) => {
        if (question.id === action.payload.id) {
          return action.payload
        }
        return question
      })
    case CONSTANTS.UPDATE_MULTIPLE_QUESTIONS_DONE:
      return state.map((question) => {
        const target = action.payload.find((o: QuestionModel) => o.id === question.id)
        if (target) return { ...target, questionnaire: undefined }
        return question
      })
    default:
      return state
  }
}
