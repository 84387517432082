/* eslint-disable no-console */
import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import ClientModel from '../../models/client.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import TagModel from '../../models/tag.model'
import ThemeModel from '../../models/theme.model'
import KidCheckBox from '../kid-checkbox'
import TagsTextFields from '../tags-autocomplete-textfield'
import TargetCheckBox from '../target-checkbox'
import TextCheckBox from '../text-checkbox'

const useStyles = makeStyles(() => ({
  headerContainer: {
    backgroundColor: '#dadadb',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    color: '#ffffff',
    display: 'flex',
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
  },
  kidsSelectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '5rem',
    height: '56px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  questionnaireNameTextFieldContainer: {
    flexGrow: 6,
    paddingRight: '1rem',
  },
  questionnaireTagTextFieldContainer: {
    flexGrow: 5,
    paddingRight: '1rem',
  },
  formContainer: {
    flexGrow: 2,
    paddingRight: '1rem',
  },
  kidCheckBoxContainer: {
    paddingRight: '1rem',
  },
  buttonContainer: {},
  addQuestionButton: {
    height: '100%',
    color: 'white',
    borderRadius: '12px',
  },
  startIcon: {
    margin: 'unset',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
}))

export interface OwnProps {
  questionnaire: QuestionnaireModel
  themes: ThemeModel[]
  tags: TagModel[]
  currentUser: ClientModel | undefined
  setShowActionButtonAddOrUpdate: (show: boolean) => void
  setIsMissingFieldsError: (isError: boolean) => void
  updateQuestionnaire: (questionnaire: QuestionnaireModel) => void
}

export type Props = OwnProps

const HeaderQuestion = ({
  questionnaire,
  themes,
  tags,
  currentUser,
  updateQuestionnaire,
  setShowActionButtonAddOrUpdate,
  setIsMissingFieldsError,
}: Props) => {
  const classes = useStyles()
  const history = useHistory()

  // Translation
  const { t } = useTranslation()

  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<QuestionnaireModel>(questionnaire)

  useEffect(() => {
    setCurrentQuestionnaire(questionnaire)
  }, [questionnaire])

  const handleChangeTheme = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newThemeId = event.target.value as number
      const currentTheme = themes.filter((theme) => theme.id === newThemeId)[0]
      const newQuestionnare = { ...currentQuestionnaire, theme: currentTheme }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, themes, updateQuestionnaire]
  )

  const handleChangeLang = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newLang = event.target.value as string
      const newQuestionnare = { ...currentQuestionnaire, lang: newLang }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  const handleOnClickKidCheckbox = useCallback(
    (isChecked: boolean) => {
      const newQuestionnare = { ...currentQuestionnaire, child: isChecked }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  const handleChangeQuestionnaireName = useCallback(
    (e) => {
      const newTitle = e.target.value as string
      const newQuestionnare = { ...currentQuestionnaire, title: newTitle }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  const handleChangeQuestionnaireTags = useCallback(
    (myTags) => {
      const newTags = myTags as TagModel[]
      const newQuestionnare = { ...currentQuestionnaire, tags: newTags }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  const handleOnClickAddQuestion = useCallback(
    (e) => {
      e.preventDefault()
      history.push({
        pathname: `/questionnaire/${currentQuestionnaire.id}/question/new`,
        state: { tab: 1, page: 'question/new', data: currentQuestionnaire.id },
      })
    },
    [currentQuestionnaire.id, history]
  )

  const handleOnClickTargetBox = useCallback(
    (isChecked: boolean) => {
      const newQuestionnare = { ...currentQuestionnaire, isTarget: isChecked }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  const handleOnClickMonoBox = useCallback(
    (isChecked: boolean) => {
      const newQuestionnare = { ...currentQuestionnaire, isMono: isChecked }
      setCurrentQuestionnaire(newQuestionnare)
      updateQuestionnaire(newQuestionnare)
      setShowActionButtonAddOrUpdate(true)
      setIsMissingFieldsError(false)
    },
    [currentQuestionnaire, setIsMissingFieldsError, setShowActionButtonAddOrUpdate, updateQuestionnaire]
  )

  return (
    <div className={classes.headerContainer}>
      <div className={classes.questionnaireNameTextFieldContainer}>
        <TextField
          label={t('nomQuestionnaire')}
          variant="outlined"
          fullWidth
          className={classes.textField}
          color="secondary"
          onChange={handleChangeQuestionnaireName}
          value={currentQuestionnaire.title}
        />
      </div>
      <div className={classes.questionnaireTagTextFieldContainer}>
        <TagsTextFields Tags={currentQuestionnaire.tags} allTags={tags} handleTags={handleChangeQuestionnaireTags} />
      </div>
      {currentUser && !currentUser.isAdmin && (
        <div className={classes.kidCheckBoxContainer}>
          <TargetCheckBox
            onClick={handleOnClickTargetBox}
            currentChecked={currentQuestionnaire.isTarget}
            backgroundColor="white"
          />
        </div>
      )}{' '}
      <div className={classes.kidCheckBoxContainer}>
        <TextCheckBox onClick={handleOnClickMonoBox} currentChecked={currentQuestionnaire.isMono} backgroundColor="white">
          <span style={{ fontWeight: 400 }}>Mono</span>
        </TextCheckBox>
      </div>
      <div className={classes.formContainer}>
        <FormControl variant="filled" className={classes.formControl} color="secondary">
          <InputLabel>{t('themeFinal')}</InputLabel>
          <Select value={currentQuestionnaire?.theme?.id || ''} onChange={handleChangeTheme}>
            {themes.map(
              (themeSelected: ThemeModel) =>
                themeSelected.visible &&
                (themeSelected.idClient === -1 || // if theme is created by ADMIN
                  themeSelected.idClient === currentUser?.id || // if theme created by the current client
                  (themeSelected.idClient !== currentUser?.id && themeSelected.inProd && currentUser?.isAdmin)) && ( // if theme from another client but is inProd and the current user is ADMIN
                  <MenuItem key={themeSelected.label} value={themeSelected.id}>
                    {themeSelected.label}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </div>
      <div className={classes.formContainer}>
        <FormControl variant="filled" className={classes.formControl} color="secondary">
          <InputLabel id="demo-simple-select-label">{t('langue')}</InputLabel>
          <Select
            labelId="lang-select-label"
            id="demo-simple-select"
            value={currentQuestionnaire.lang}
            onChange={handleChangeLang}
          >
            <MenuItem value="FR">FR</MenuItem>
            <MenuItem value="EN">EN</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.kidCheckBoxContainer}>
        <KidCheckBox
          onClick={handleOnClickKidCheckbox}
          currentChecked={currentQuestionnaire.child}
          backgroundColor="white"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          disabled={typeof currentQuestionnaire.id === 'undefined'}
          startIcon={<AddCircle style={{ fontSize: '2.5rem' }} />}
          className={classes.addQuestionButton}
          classes={{ startIcon: classes.startIcon }}
          onClick={handleOnClickAddQuestion}
        />
      </div>
    </div>
  )
}

export default React.memo(HeaderQuestion)
