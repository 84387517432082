import { IconButton, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { DeleteOutline, SettingsOutlined } from '@material-ui/icons'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import QuestionModel from '../../models/question.model'

const useStyles = makeStyles(() => ({
  delete: {
    color: red[500],
  },
}))
interface Props {
  onDelete: () => void
  question: QuestionModel
}

const ActionQuestionItem = ({ onDelete, question }: Props) => {
  const classes = useStyles()

  const { id, idQuestionnaire } = question

  const handleOnDeleteClickButton = useCallback(() => {
    onDelete()
  }, [onDelete])
  return (
    <>
      <Link
        to={{
          pathname: `/questionnaire/${idQuestionnaire}/question/update/${id}`,
          state: {
            tab: 1,
            page: 'question/update',
            data: idQuestionnaire,
            question,
          },
        }}
      >
        <IconButton aria-label="settings" component="span" onClick={() => {}}>
          <SettingsOutlined />
        </IconButton>
      </Link>
      <IconButton aria-label="delete" component="span" onClick={handleOnDeleteClickButton}>
        <DeleteOutline className={classes.delete} />
      </IconButton>
    </>
  )
}

export default ActionQuestionItem
