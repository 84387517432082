import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { getQuestionAction } from '../../../redux/actions/question.actions'
import { RootStore } from '../../../redux/store/root.store'
import QuestionFormPage, { DispatchesProps, StateProps, OwnProps } from './index'

function mapStateToProps(store: RootStore, own: OwnProps): StateProps & OwnProps {
  return {
    questionnaire: store.questionnaires.selectedQuestionnaire,
    idQuestionnaire: own.idQuestionnaire,
    question: own.question,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getQuestion: (idQuestion: number) => dispatch(getQuestionAction(idQuestion)),
  }
}

const QuestionFormContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionFormPage)

export default QuestionFormContainer
