import * as localStorage from 'local-storage'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import ClientModel from '../../models/client.model'
import {
  addClientActionDone,
  addClientActionFailed,
  deleteClientActionDone,
  deleteClientActionFailed,
  getClientActionDone,
  getClientActionFailed,
  getClientsActionDone,
  getClientsActionFailed,
  updateClientActionDone,
  updateClientActionFailed,
  loginClientActionDone,
  loginClientActionFailed,
  loadingStart,
  loadingDone,
} from '../actions/client.actions'
import * as CONSTANTS from '../actions/constants'
import Api from '../apis/api-factory'

function* clientAuth() {
  try {
    put(loadingStart())
    const { client } = yield call(Api.authClient)
    yield put(loginClientActionDone(client))
  } catch (e) {
    yield put(loginClientActionFailed(e as Error))
  } finally {
    put(loadingDone())
  }
}

function* loginClient(action: AnyAction) {
  try {
    put(loadingStart())
    const body = action.payload
    const { client, jwt } = yield call(Api.loginClient, body)
    localStorage.set('quiz.admin.jwt', jwt as string)
    yield put(loginClientActionDone(client))
  } catch (e) {
    yield put(loginClientActionFailed(e as Error))
  } finally {
    put(loadingDone())
  }
}

function* getClients() {
  try {
    const clients: ClientModel[] | [] = yield call(Api.getClients)

    yield put(getClientsActionDone(clients))
  } catch (e) {
    yield put(getClientsActionFailed(e as Error))
  }
}

function* getClient(action: AnyAction) {
  try {
    const clientId = action.payload
    const client: ClientModel = yield call(Api.getClient, clientId)
    yield put(getClientActionDone(client))
  } catch (e) {
    yield put(getClientActionFailed(e as Error))
  }
}

function* addClient(action: AnyAction) {
  try {
    const client = action.payload
    const clientSaved: ClientModel = yield call(Api.addClient, client)
    yield put(addClientActionDone(clientSaved))
  } catch (e) {
    yield put(addClientActionFailed(e as Error))
  }
}

function* updateClient(action: AnyAction) {
  try {
    const client = action.payload
    const data: ClientModel = yield call(Api.updateClient, { ...client, sessions: undefined })
    yield put(updateClientActionDone(data))
  } catch (e) {
    yield put(updateClientActionFailed(e as Error))
  }
}

function* deleteClient(action: AnyAction) {
  try {
    const clientId = action.payload
    const client: ClientModel = yield call(Api.getClient, clientId)
    yield call(Api.updateClient, { ...client, active: false, sessions: undefined })
    yield put(deleteClientActionDone(clientId))
  } catch (e) {
    yield put(deleteClientActionFailed(e as Error))
  }
}

export default function* roomsSaga() {
  yield takeLatest(CONSTANTS.AUTH_CLIENT, clientAuth)
  yield takeLatest(CONSTANTS.LOGIN_CLIENT, loginClient)
  yield takeLatest(CONSTANTS.GET_CLIENTS, getClients)
  yield takeLatest(CONSTANTS.GET_CLIENT, getClient)
  yield takeLatest(CONSTANTS.ADD_CLIENT, addClient)
  yield takeLatest(CONSTANTS.UPDATE_CLIENT, updateClient)
  yield takeLatest(CONSTANTS.DELETE_CLIENT, deleteClient)
}
