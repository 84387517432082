import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { getQuestionnaireAction } from '../../../redux/actions/questionnaire.actions'
import { RootStore } from '../../../redux/store/root.store'
import QuestionPage, { DispatchesProps, StateProps, OwnProps } from './index'

function mapStateToProps(store: RootStore, own: OwnProps): StateProps {
  return {
    questionnaire: store.questionnaires.selectedQuestionnaire,
    idQuestion: own.idQuestion,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getQuestionnaire: (idQuestionnaire: number) => dispatch(getQuestionnaireAction(idQuestionnaire)),
  }
}

const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionPage)

export default QuestionContainer
