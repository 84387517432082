import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ThemeModel from '../../models/theme.model'
import { addThemeAction, updateThemeAction, getThemesAction } from '../../redux/actions/theme.actions'
import { RootStore } from '../../redux/store/root.store'
import ThemePage, { DispatchesProps, StateProps } from './index'

function mapStateToProps(store: RootStore): StateProps {
  return {
    themes: store.themes,
    questionnaires: store.questionnaires.questionnaires,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getThemes: (idClient: number) => dispatch(getThemesAction(idClient)),
    addTheme: (theme: ThemeModel) => dispatch(addThemeAction(theme)),
    deleteTheme: (theme: ThemeModel) => dispatch(updateThemeAction(theme)),
  }
}

const ThemeContainer = connect(mapStateToProps, mapDispatchToProps)(ThemePage)

export default ThemeContainer
