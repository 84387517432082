import { makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'

import TeamModel from '../../../../models/team.model'
import TooltipSessionCompleteItem from '../tooltip-session-complete-item'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export interface OwnProps {
  teams: TeamModel[] | undefined
}

export type Props = OwnProps

const TooltipSessionComplete = ({ teams }: Props) => {
  const classes = useStyles()

  const displayTooltipTeamItem = useCallback(() => teams?.map((team) => (
    <TooltipSessionCompleteItem team={team} key={team.id} />
  )), [teams])

  return (
    <div className={classes.container}>
      {displayTooltipTeamItem()}
    </div>
  )
}

export default TooltipSessionComplete
