import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  formRadio: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '2rem',
  },
  radioLabel: {
    background: 'white',
    borderRadius: '5px',
    border: 'rgba(0, 0, 0, 0.23) 1px solid',
    paddingRight: '1rem',
  },

  labelForm: {
    color: 'gray',
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingRight: '2rem',
    textTransform: 'uppercase',
    minWidth: '110px',
    textAlign: 'end',
  },
}))

export interface OwnProps {
  correctAnswer: number
}

export type Props = OwnProps

const QuestionRadioButton = ({ correctAnswer }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentAnswer] = useState<number>(correctAnswer)

  return (
    <FormControl className={classes.formRadio} component="fieldset" fullWidth color="secondary" disabled>
      <p className={classes.labelForm}>{t('reponse')}</p>
      <RadioGroup row aria-label="gender" name="gender1" value={currentAnswer}>
        {currentAnswer === 0 && (
          <FormControlLabel
            className={classes.radioLabel}
            value={0}
            control={<Radio checked={currentAnswer === 0} />}
            label={`${t('reponse')} 1`}
          />
        )}
        {currentAnswer === 1 && (
          <FormControlLabel
            className={classes.radioLabel}
            value={1}
            control={<Radio checked={currentAnswer === 1} />}
            label={`${t('reponse')} 2`}
          />
        )}
        {currentAnswer === 2 && (
          <FormControlLabel
            className={classes.radioLabel}
            value={2}
            control={<Radio checked={currentAnswer === 2} />}
            label={`${t('reponse')} 3`}
          />
        )}
        {currentAnswer === 3 && (
          <FormControlLabel
            className={classes.radioLabel}
            value={3}
            control={<Radio checked={currentAnswer === 3} />}
            label={`${t('reponse')} 4`}
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default QuestionRadioButton
