/* eslint-disable react/jsx-wrap-multilines */
import { Container, FormControl, InputLabel, makeStyles, MenuItem, Select, Snackbar, TextField } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { ArrowBackIos } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import AnswerModel from '../../models/answer.model'
import QuestionModel, { answerType, questionType } from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import TagModel from '../../models/tag.model'
// import Api from '../../redux/apis/api-factory'
import isNotEmpty from '../../utils/string-utils'
import Alert from '../alert'
import ActionQuestionFormButton from './action-button'
import QuestionAnswers from './question-answers'
import QuestionsAnswersPhotos from './question-answers-photos'
import QuestionMedia from './question-media'
import QuestionRadioButton from './question-radio-button'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    color: '#000000',
  },
  rootContainer: {},
  titleIconContainer: {
    display: 'flex',
    padding: '2rem 0',
  },
  titleContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    padding: '0rem 2rem 0rem 1rem',
    color: '#000000DE',
    minHeight: '50px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  iconContainer: {
    backgroundColor: '#f3e703',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    color: 'rgba(0, 0, 0, 0.7)',
    alignSelf: 'center',
    minHeight: '50px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  formContainer: {
    display: 'flex',
  },
  leftFormulaire: {
    flexGrow: 1,
    padding: '1rem',
    flexBasis: 0,
  },
  rigthFormulaire: {
    flexGrow: 1,
    flexBasis: 0,
  },
  textField: {
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  questionnaireRed: {
    color: red[500],
  },
  questionnaireContent: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
  },
  formRadio: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  radioLabel: {
    background: 'white',
    borderRadius: '5px',
    border: 'rgba(0, 0, 0, 0.23) 1px solid',
    paddingRight: '1rem',
  },
  formInputLabel: {
    background: 'white',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  formSelect: {
    background: 'white',
  },
  formControl: {
    paddingBottom: '1rem',
    color: '#ffffff',
    display: 'flex',
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },
  labelForm: {
    color: 'gray',
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingRight: '2rem',
    textTransform: 'uppercase',
    minWidth: '110px',
    textAlign: 'end',
  },
}))

export interface OwnProps {
  question: QuestionModel
}

export interface toEditType {
  toEdit: boolean
}

export interface StateProps {
  questionnaire: QuestionnaireModel | undefined
  questions: QuestionModel[]
}

export interface DispatchesProps {
  addQuestion: (question: QuestionModel) => void
  updateQuestion: (question: QuestionModel) => void
  uploadQuestionMedia: (file: FormData | undefined) => void
  uploadAnswerMedia: (file: FormData | undefined) => void
  updateQuestionnaire: (questionnaire: QuestionnaireModel) => void
}
export type Props = OwnProps & DispatchesProps & StateProps & toEditType

const defaultCurrentQuestion: QuestionModel = {
  visible: true,
  title: '',
  theme: '',
  questionType: 'text',
  answerType: 'text',
  explanation: '',
  firstAnswer: '',
  secondAnswer: '',
  thirdAnswer: '',
  forthAnswer: '',
  firstAnswerMedia: '',
  secondAnswerMedia: '',
  thirdAnswerMedia: '',
  forthAnswerMedia: '',
  correctAnswer: 0,
  position: 0,
  questionMedia: '',
  points: 0,
  timing: 0,
}

const QuestionForm = ({
  questionnaire,
  question,
  toEdit,
  addQuestion,
  updateQuestion,
  uploadQuestionMedia,
  uploadAnswerMedia,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<QuestionnaireModel>(questionnaire as QuestionnaireModel)
  const [currentQuestion, setCurrentQuestion] = useState<QuestionModel>({
    ...(question || defaultCurrentQuestion),
    idQuestionnaire: currentQuestionnaire.id,
  })

  const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false)
  const [fileName, setFileName] = useState(question ? question.questionMedia : '')
  // storing the question media that will be sent to the ftp server
  const [myFileQuestion, setMyFileQuestion] = useState<FormData>()
  // storing the answers media that will be sent to the ftp server
  const [myFileAnswers, setMyFileAnswers] = useState<AnswerModel[]>([])
  const [isMissingFieldError, setIsMissingFieldError] = useState<boolean>(false)
  const [questionMediaError, setQuestionMediaError] = useState<string>('')
  const [answerMediaError, setAnswerMediaError] = useState<string>('')

  const isQuestionnaireFinal = () => {
    let isFinal = false
    if (currentQuestionnaire) {
      currentQuestionnaire.tags.forEach((tag: TagModel) => {
        if (tag.label.toLocaleLowerCase() === 'final') {
          isFinal = true
          return isFinal
        }
        return 0
      })
    }
    return isFinal
  }

  function isNotEmptyFinal(string: string | undefined, finalTheme: boolean): boolean {
    let stateString:boolean=false
    if (finalTheme===true) {
     if (typeof string !== 'undefined' && typeof string === 'string' && string.length > 0) {
         stateString = true
     }else{
         stateString = false
     }
    }else{
     stateString = true
    }
    return stateString
   }

  const isValidQuestion = useCallback(
    () =>
      typeof currentQuestion !== 'undefined' &&
      currentQuestion.timing >= 10 &&
      currentQuestion.timing <= 40 &&
      isNotEmpty(currentQuestion.title) &&
      isNotEmpty(currentQuestion.answerType) &&
      isNotEmptyFinal(currentQuestion.theme, isQuestionnaireFinal()) &&
      ((currentQuestion.answerType === 'text' &&
        isNotEmpty(currentQuestion.firstAnswer) &&
        isNotEmpty(currentQuestion.secondAnswer) &&
        isNotEmpty(currentQuestion.thirdAnswer) &&
        isNotEmpty(currentQuestion.forthAnswer)) ||
        (currentQuestion.answerType !== 'text' &&
          isNotEmpty(currentQuestion.firstAnswerMedia) &&
          isNotEmpty(currentQuestion.secondAnswerMedia) &&
          isNotEmpty(currentQuestion.thirdAnswerMedia) &&
          isNotEmpty(currentQuestion.forthAnswerMedia) &&
          !isNotEmpty(answerMediaError))) &&
      (currentQuestion.questionType === 'text' ||
        (currentQuestion.questionType &&
          ['image', 'video'].includes(currentQuestion.questionType) &&
          !isNotEmpty(questionMediaError) &&
          isNotEmpty(currentQuestion.questionMedia))),
    [answerMediaError, currentQuestion, questionMediaError]
  )

  useEffect(() => {
    if (questionnaire) {
      setCurrentQuestionnaire(questionnaire)
    }
  }, [questionnaire])


  const handleSaveQuestion = useCallback(() => {
    const getNextPosition = (): number => {
      if (!currentQuestionnaire.questions) return -1
      if (currentQuestionnaire.questions.filter(({ visible }) => visible).length === 0) return 1
      return (
        currentQuestionnaire.questions.filter(({ visible }) => visible).sort((a, b) => b.position - a.position)[0].position +
        1
      )
    }

    // const redirectToQuestionnaire = () => {
    //   history.push({
    //     pathname: `/questionnaire/${currentQuestionnaire.id}`,
    //     state: { page: 'questionnaire', data: currentQuestionnaire.id, toEdit: true },
    //   })
    // }
    const clearInputs = () => {
      //   setCurrentQuestionnaire(questionnaire as QuestionnaireModel)
      setCurrentQuestion({
        ...(question || defaultCurrentQuestion),
        idQuestionnaire: currentQuestionnaire.id,
      })
      //   setOpenValidationSnackbar(false)
      //   setFileName('')
      //   setMyFileQuestion(undefined)
      //   setMyFileAnswers([])
    }

    if (isValidQuestion()) {
      setIsMissingFieldError(false)
      setOpenValidationSnackbar(true)
      const lastModifiedAt = new Date(Date.now())
      if (toEdit) {
        updateQuestion({ ...currentQuestion, lastModifiedAt })
        setCurrentQuestion({ ...currentQuestion, lastModifiedAt })
      } else {
        addQuestion({
          ...currentQuestion,
          lastModifiedAt,
          position: getNextPosition(),
          idQuestionnaire: currentQuestionnaire.id,
        })
        clearInputs()
      }
      if (currentQuestion.questionType !== 'text' && myFileQuestion) {
        uploadQuestionMedia(myFileQuestion)
      }
      if (currentQuestion.answerType !== 'text' && myFileAnswers) {
        myFileAnswers.forEach((answer: AnswerModel) => {
          uploadAnswerMedia(answer.data)
        })
      }
    } else {
      setIsMissingFieldError(true)
      setOpenValidationSnackbar(true)
    }
  }, [
    currentQuestion,
    toEdit,
    myFileQuestion,
    myFileAnswers,
    updateQuestion,
    currentQuestionnaire.questions,
    addQuestion,
    uploadQuestionMedia,
    uploadAnswerMedia,
    currentQuestionnaire.id,
    isValidQuestion,
    question,
  ])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationSnackbar(false)
  }

  const handleOnChangeTitle = useCallback(
    (e) => {
      const newTitle = e.target.value as string
      setCurrentQuestion({ ...currentQuestion, title: newTitle })
    },
    [currentQuestion]
  )

  const handleOnChangeTypeQuestion = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newType = event.target.value as questionType
      setFileName('')
      setCurrentQuestion({ ...currentQuestion, questionType: newType })
    },
    [currentQuestion]
  )

  const handleOnChangeQuestionMedia = useCallback(
    (name) => {
      const newQuestionMedia = name as string
      setCurrentQuestion({ ...currentQuestion, questionMedia: newQuestionMedia })
    },
    [currentQuestion]
  )

  const handleOnChangeTypeAnswer = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newAnswerType = event.target.value as answerType
      setCurrentQuestion({ ...currentQuestion, answerType: newAnswerType })
    },
    [currentQuestion]
  )

  const handleOnChangeTheme = useCallback(
    (e) => {
      const newTheme = e.target.value as answerType
      setCurrentQuestion({ ...currentQuestion, theme: newTheme })
    },
    [currentQuestion]
  )

  const handleOnChangeTiming = useCallback(
    (e) => {
      const newTiming = e.target.value as string
      if (newTiming === '') {
        setCurrentQuestion({ ...currentQuestion, timing: 0 })
      } else if (!Number.isNaN(parseFloat(newTiming))) {
        setCurrentQuestion({
          ...currentQuestion,
          timing: parseFloat(newTiming),
        })
      }
    },
    [currentQuestion]
  )

  const handleOnChangeExplication = useCallback(
    (e) => {
      const newExplication = e.target.value as answerType
      setCurrentQuestion({ ...currentQuestion, explanation: newExplication })
    },
    [currentQuestion]
  )

  const onChangeAnswer = useCallback(
    (newAnswer: number) => {
      setCurrentQuestion({ ...currentQuestion, correctAnswer: newAnswer })
    },
    [currentQuestion]
  )

  const handleOnChangeAnswers = useCallback(
    (firstAnswer: string, secondAnswer: string, thirdAnswer: string, forthAnswer: string) => {
      setCurrentQuestion({
        ...currentQuestion,
        firstAnswer,
        secondAnswer,
        thirdAnswer,
        forthAnswer,
      })
    },
    [currentQuestion]
  )

  const handleOnChangeAnswersMedia = useCallback(
    (firstAnswerMedia: string, secondAnswerMedia: string, thirdAnswerMedia: string, forthAnswerMedia: string) => {
      setCurrentQuestion({
        ...currentQuestion,
        firstAnswerMedia,
        secondAnswerMedia,
        thirdAnswerMedia,
        forthAnswerMedia,
      })
    },
    [currentQuestion]
  )

  const getAlertContent = useCallback(() => {
    if (isMissingFieldError) {
      return (
        <strong>
          <em>{t('questionFormErrorMessage')}</em>
        </strong>
      )
    }
    let phrase: string = ''
    if (typeof currentQuestion.id !== 'undefined') {
      phrase = t('updateQuestion')
    } else {
      phrase = t('addQuestion')
    }
    return (
      <>
        {`${t('laQuestion')} `}
        <strong>
          <em>{currentQuestion.title}</em>
        </strong>{' '}
        {phrase}{' '}
        <strong>
          <em>{currentQuestionnaire.title}</em>
        </strong>{' '}
        !
      </>
    )
  }, [currentQuestion.id, currentQuestion.title, currentQuestionnaire.title, isMissingFieldError, t])
  return (
    <div className={classes.rootContainer}>
      <Link
        to={{
          pathname: `/questionnaire/${currentQuestionnaire.id}`,
          state: { tab: 1, page: 'questionnaire', data: currentQuestionnaire.id },
        }}
      >
        <div className={classes.titleIconContainer}>
          <div className={classes.iconContainer}>
            <ArrowBackIos />
          </div>
          <div className={classes.titleContainer}>
            <p className={classes.questionnaireContent}>
              <span className={classes.questionnaireRed}>{currentQuestionnaire?.theme?.label || 'THÈME SUPPRIMÉ'} : </span>{' '}
              <span>{currentQuestionnaire.title}</span>
            </p>
          </div>
        </div>
      </Link>
      <Container className={classes.formContainer}>
        <div className={classes.leftFormulaire}>
          <TextField
            label={t('question')}
            variant="outlined"
            className={classes.textField}
            color="secondary"
            fullWidth
            value={currentQuestion.title}
            onChange={handleOnChangeTitle}
            inputProps={{ maxLength: 63 }}
          />
          <FormControl variant="outlined" className={classes.formControl} color="secondary">
            <InputLabel className={classes.formInputLabel}>{`${t('type')} ${t('question')}`}</InputLabel>
            <Select
              value={currentQuestion.questionType}
              onChange={handleOnChangeTypeQuestion}
              className={classes.formSelect}
            >
              <MenuItem value="text">{t('text')}</MenuItem>
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="video">Video</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl} color="secondary">
            <InputLabel className={classes.formInputLabel}>{`${t('type')} ${t('reponse')}`}</InputLabel>
            <Select className={classes.formSelect} value={currentQuestion.answerType} onChange={handleOnChangeTypeAnswer}>
              <MenuItem value="text">{t('text')}</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
          </FormControl>
          <QuestionRadioButton correctAnswer={currentQuestion.correctAnswer} onChangeAnswer={onChangeAnswer} />
          <div style={{ marginBottom: '2rem' }}>
            <TextField
              label={t('duration')}
              variant="outlined"
              className={classes.textField}
              color="secondary"
              fullWidth
              value={currentQuestion.timing}
              onChange={handleOnChangeTiming}
              type="number"
              style={{ paddingBottom: 0 }}
            />
            <span style={{ color: 'gray' }}>{t('dureeSeconds')}</span>
          </div>
          {isQuestionnaireFinal() && (
            <TextField
              label={t('themeFinal')}
              variant="outlined"
              className={classes.textField}
              color="secondary"
              fullWidth
              value={currentQuestion.theme}
              onChange={handleOnChangeTheme}
            />
          )}
          <TextField
            label={t('explication')}
            className={classes.textField}
            multiline
            color="secondary"
            fullWidth
            rows={4}
            value={currentQuestion.explanation}
            onChange={handleOnChangeExplication}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className={classes.rigthFormulaire}>
          {currentQuestion.questionType && currentQuestion.questionType !== 'text' && (
            <QuestionMedia
              handleOnChange={handleOnChangeQuestionMedia}
              type={currentQuestion.questionType.toUpperCase()}
              setMyFile={setMyFileQuestion}
              fileName={fileName}
              setFileName={setFileName}
              questionMediaError={questionMediaError}
              setQuestionMediaError={setQuestionMediaError}
            />
          )}
          {currentQuestion.answerType === 'text' ? (
            <QuestionAnswers
              firstAnswer={currentQuestion.firstAnswer}
              secondAnswer={currentQuestion.secondAnswer}
              thirdAnswer={currentQuestion.thirdAnswer}
              forthAnswer={currentQuestion.forthAnswer}
              onChangeAnswers={handleOnChangeAnswers}
            />
          ) : (
            <QuestionsAnswersPhotos
              firstAnswerMedia={currentQuestion.firstAnswerMedia}
              secondAnswerMedia={currentQuestion.secondAnswerMedia}
              thirdAnswerMedia={currentQuestion.thirdAnswerMedia}
              forthAnswerMedia={currentQuestion.forthAnswerMedia}
              onChangeAnswersMedia={handleOnChangeAnswersMedia}
              allAnswers={myFileAnswers}
              setMyFiles={setMyFileAnswers}
              answerMediaError={answerMediaError}
              setAnswerMediaError={setAnswerMediaError}
            />
          )}
          <ActionQuestionFormButton idQuestionnaire={currentQuestionnaire.id || -1} saveQuestion={handleSaveQuestion} />
        </div>
      </Container>
      <Snackbar open={openValidationSnackbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={isMissingFieldError ? 'error' : 'success'}>
          {getAlertContent()}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default QuestionForm
