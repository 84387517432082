export const APP_ERROR = 'APP_ERROR'
export const APP_ERROR_CLEAN = 'APP_ERROR_CLEAN'

// Questionnaire Constants
export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES'
export const GET_QUESTIONNAIRES_DONE = 'GET_QUESTIONNAIRES_DONE'
export const GET_QUESTIONNAIRES_FAILED = 'GET_QUESTIONNAIRES_FAILED'

export const PUBLIC_QUESTIONNAIRE = 'PUBLIC_QUESTIONNAIRE'

export const GET_QUESTIONNAIRES_PAGINATION = 'GET_QUESTIONNAIRES_PAGINATION'
export const GET_QUESTIONNAIRES_PAGINATION_DONE = 'GET_QUESTIONNAIRES_PAGINATION_DONE'
export const GET_QUESTIONNAIRES_PAGINATION_FAILED = 'GET_QUESTIONNAIRES_PAGINATION_FAILED'

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE'
export const GET_QUESTIONNAIRE_DONE = 'GET_QUESTIONNAIRE_DONE'
export const GET_QUESTIONNAIRE_FAILED = 'GET_QUESTIONNAIRE_FAILED'

export const ADD_QUESTIONNAIRE = 'ADD_QUESTIONNAIRE'
export const ADD_QUESTIONNAIRE_DONE = 'ADD_QUESTIONNAIRE_DONE'
export const ADD_QUESTIONNAIRE_FAILED = 'ADD_QUESTIONNAIRE_FAILED'

export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE'
export const DELETE_QUESTIONNAIRE_DONE = 'DELETE_QUESTIONNAIRE_DONE'
export const DELETE_QUESTIONNAIRE_FAILED = 'DELETE_QUESTIONNAIRE_FAILED'

export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE'
export const UPDATE_QUESTIONNAIRE_DONE = 'UPDATE_QUESTIONNAIRE_DONE'
export const UPDATE_QUESTIONNAIRE_FAILED = 'UPDATE_QUESTIONNAIRE_FAILED'

export const CLEAN_QUESTIONNAIRE_SELECTED = 'CLEAN_QUESTIONNAIRE_SELECTED'

// Question Constants
export const GET_QUESTIONS = 'GET_QUESTIONS'
export const GET_QUESTIONS_DONE = 'GET_QUESTIONS_DONE'
export const GET_QUESTIONS_FAILED = 'GET_QUESTIONS_FAILED'

export const GET_QUESTION = 'GET_QUESTION'
export const GET_QUESTION_DONE = 'GET_QUESTION_DONE'
export const GET_QUESTION_FAILED = 'GET_QUESTION_FAILED'

export const ADD_QUESTION = 'ADD_QUESTION'
export const ADD_QUESTION_DONE = 'ADD_QUESTION_DONE'
export const ADD_QUESTION_FAILED = 'ADD_QUESTION_FAILED'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_DONE = 'DELETE_QUESTION_DONE'
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED'

export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const UPDATE_QUESTION_DONE = 'UPDATE_QUESTION_DONE'
export const UPDATE_QUESTION_FAILED = 'UPDATE_QUESTION_FAILED'

export const UPDATE_MULTIPLE_QUESTIONS = 'UPDATE_MULTIPLE_QUESTIONS'
export const UPDATE_MULTIPLE_QUESTIONS_DONE = 'UPDATE_MULTIPLE_QUESTIONS_DONE'
export const UPDATE_MULTIPLE_QUESTIONS_FAILED = 'UPDATE_MULTIPLE_QUESTIONS_FAILED'

// Upload Question Media to FTP
export const UPLOAD_QUESTION_MEDIA = 'UPLOAD_QUESTION_MEDIA'
export const UPLOAD_QUESTION_MEDIA_DONE = 'UPLOAD_QUESTION_MEDIA_DONE'
export const UPLOAD_QUESTION_MEDIA_FAILED = 'UPLOAD_QUESTION_MEDIA_FAILED'

// Upload Answer Media to FTP
export const UPLOAD_ANSWER_MEDIA = 'UPLOAD_ANSWER_MEDIA'
export const UPLOAD_ANSWER_MEDIA_DONE = 'UPLOAD_ANSWER_MEDIA_DONE'
export const UPLOAD_ANSWER_MEDIA_FAILED = 'UPLOAD_ANSWER_MEDIA_FAILED'

// Theme Constants
export const GET_THEMES = 'GET_THEMES'
export const GET_THEMES_DONE = 'GET_THEMES_DONE'
export const GET_THEMES_FAILED = 'GET_THEMES_FAILED'

export const GET_THEME = 'GET_THEME'
export const GET_THEME_DONE = 'GET_THEME_DONE'
export const GET_THEME_FAILED = 'GET_THEME_FAILED'

export const ADD_THEME = 'ADD_THEME'
export const ADD_THEME_DONE = 'ADD_THEME_DONE'
export const ADD_THEME_FAILED = 'ADD_THEME_FAILED'

export const UPDATE_THEME = 'UPDATE_THEME'
export const UPDATE_THEME_DONE = 'UPDATE_THEME_DONE'
export const UPDATE_THEME_FAILED = 'UPDATE_THEME_FAILED'

export const LOGOUT_CLIENT = 'LOGOUT_CLIENT'

export const AUTH_CLIENT = 'AUTH_CLIENT'

export const DELETE_THEME = 'DELETE_THEME'
export const DELETE_THEME_DONE = 'DELETE_THEME_DONE'
export const DELETE_THEME_FAILED = 'DELETE_THEME_FAILED'

// Tag Constants
export const GET_TAGS = 'GET_TAGS'
export const GET_TAGS_DONE = 'GET_TAGS_DONE'
export const GET_TAGS_FAILED = 'GET_TAGS_FAILED'

export const GET_TAG = 'GET_TAG'
export const GET_TAG_DONE = 'GET_TAG_DONE'
export const GET_TAG_FAILED = 'GET_TAG_FAILED'

export const ADD_TAG = 'ADD_TAG'
export const ADD_TAG_DONE = 'ADD_TAG_DONE'
export const ADD_TAG_FAILED = 'ADD_TAG_FAILED'

export const ADD_MULTIPLE_TAGS = 'ADD_MULTIPLE_TAGS'
export const ADD_MULTIPLE_TAGS_DONE = 'ADD_MULTIPLE_TAGS_DONE'
export const ADD_MULTIPLE_TAGS_FAILED = 'ADD_MULTIPLE_TAGS_FAILED'

export const DELETE_TAG = 'DELETE_TAG'
export const DELETE_TAG_DONE = 'DELETE_TAG_DONE'
export const DELETE_TAG_FAILED = 'DELETE_TAG_FAILED'

// Client Constants
export const LOGIN_CLIENT = 'LOGIN_CLIENT'
export const LOGIN_CLIENT_DONE = 'LOGIN_CLIENT_DONE'
export const LOGIN_CLIENT_FAILED = 'LOGIN_CLIENT_FAILED'

export const LOADING_START = 'LOADING_START'
export const LOADING_DONE = 'LOADING_DONE'

export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENTS_DONE = 'GET_CLIENTS_DONE'
export const GET_CLIENTS_FAILED = 'GET_CLIENTS_FAILED'

export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENT_DONE = 'GET_CLIENT_DONE'
export const GET_CLIENT_FAILED = 'GET_CLIENT_FAILED'

export const ADD_CLIENT = 'ADD_CLIENT'
export const ADD_CLIENT_DONE = 'ADD_CLIENT_DONE'
export const ADD_CLIENT_FAILED = 'ADD_CLIENT_FAILED'

export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_DONE = 'DELETE_CLIENT_DONE'
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED'

export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const UPDATE_CLIENT_DONE = 'UPDATE_CLIENT_DONE'
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED'
// Session Constants
export const GET_SESSIONS_BY_ID_CLIENT = 'GET_SESSIONS_BY_ID_CLIENT'
export const GET_SESSIONS_BY_ID_CLIENT_DONE = 'GET_SESSIONS_BY_ID_CLIENT_DONE'
export const GET_SESSIONS_BY_ID_CLIENT_FAILED = 'GET_SESSIONS_BY_ID_CLIENT_FAILED'

export const GET_SESSIONS_PAGINATION = 'GET_SESSIONS_PAGINATION'
export const GET_SESSIONS_PAGINATION_DONE = 'GET_SESSIONS_PAGINATION_DONE'
export const GET_SESSIONS_PAGINATION_FAILED = 'GET_SESSIONS_PAGINATION_FAILED'

export const GET_SESSIONS_HISTORY = 'GET_SESSIONS_HISTORY'
export const GET_SESSIONS_HISTORY_DONE = 'GET_SESSIONS_HISTORY_DONE'
export const GET_SESSIONS_HISTORY_FAILED = 'GET_SESSIONS_HISTORY_FAILED'
