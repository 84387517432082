import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import TeamModel from '../../../../models/team.model'

const useStyles = makeStyles(() => ({
  pupitreLine: {
    width: '100%',
    fontVariant: 'petite-caps',
    fontSize: '18px',
  },
}))

interface Props {
  team: TeamModel
}

const SessionCompleteItem = ({ team }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const getTeamPlayersName = useCallback(() => {
    if (typeof team.players !== 'undefined') {
      if (team.players.length === 1) {
        return `${team.players[0].name}`
      }
      if (team.players.length === 2) {
        return `${team.players[0].name} & ${team.players[1].name}`
      }
    }
    return t('aucunPlayer')
  }, [t, team.players])

  return <span className={classes.pupitreLine}>{`${t('pupitre')} ${team.pupitre} : ${getTeamPlayersName()}`}</span>
}

export default SessionCompleteItem
