// helper to get dimensions of an image
export const imageDimensions = (file: File) =>
  new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject(new Error("L'image doit respecter les indications ci-dessous"))
    }

    img.src = URL.createObjectURL(file)
  })

const onSelectVideo = (file: File) =>
  new Promise((resolve, reject) => {
    const videoFile = file
    const video = document.createElement('video')
    video.src = URL.createObjectURL(videoFile)
    video.onloadedmetadata = function () {
      resolve({
        videoFile,
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }
  })

export const isImageValid = async (file: File) => {
  // height and width validation
  const { width, height } = (await imageDimensions(file)) as { height: number; width: number }
  // and type and size validation
  return width === 900 && height === 330 && file.type === 'image/jpeg' && file.size < 300 * 1024
}

export const isVideoValid = async (file: File) => {
  const { width, height } = (await onSelectVideo(file)) as { height: number; width: number }
  console.log('{ width, height }', { width, height })
  return file.type === 'video/mp4' && width === 900 && height === 330 && file.size < 10 * 1024 * 1024
}

export const isAnswerImageValid = async (file: File) => {
  // height and width validation
  const { width, height } = (await imageDimensions(file)) as { height: number; width: number }
  // and type and size validation
  return width === 455 && height === 255 && file.type === 'image/jpeg' && file.size < 100 * 1024
}
