import { createAction } from 'redux-actions'

import IQuestionnairePaginationRequest from '../../interfaces/IQuestionnairePaginationRequest'
import IQuestionnairePaginationResults from '../../interfaces/IQuestionnairePaginationResults'
import Questionnaire from '../../models/questionnaire.model'
import * as CONSTANTS from './constants'

export const getQuestionnairesAction = createAction<number>(CONSTANTS.GET_QUESTIONNAIRES)
export const getQuestionnairesActionDone = createAction<Questionnaire[]>(CONSTANTS.GET_QUESTIONNAIRES_DONE)
export const getQuestionnairesActionFailed = createAction<Error>(CONSTANTS.GET_QUESTIONNAIRES_FAILED)

export const getQuestionnairesPaginationAction = createAction<IQuestionnairePaginationRequest>(
  CONSTANTS.GET_QUESTIONNAIRES_PAGINATION
)
export const getQuestionnairesPaginationActionDone = createAction<IQuestionnairePaginationResults>(
  CONSTANTS.GET_QUESTIONNAIRES_PAGINATION_DONE
)
export const getQuestionnairesPaginationActionFailed = createAction<Error>(CONSTANTS.GET_QUESTIONNAIRES_PAGINATION_FAILED)

export const publicQuestionnaireAction = createAction<number>(CONSTANTS.PUBLIC_QUESTIONNAIRE)

export const getQuestionnaireAction = createAction<number>(CONSTANTS.GET_QUESTIONNAIRE)
export const getQuestionnaireActionDone = createAction<Questionnaire>(CONSTANTS.GET_QUESTIONNAIRE_DONE)
export const getQuestionnaireActionFailed = createAction<Error>(CONSTANTS.GET_QUESTIONNAIRE_FAILED)

export const addQuestionnaireAction = createAction<Questionnaire>(CONSTANTS.ADD_QUESTIONNAIRE)
export const addQuestionnaireActionDone = createAction<Questionnaire>(CONSTANTS.ADD_QUESTIONNAIRE_DONE)
export const addQuestionnaireActionFailed = createAction<Error>(CONSTANTS.ADD_QUESTIONNAIRE_FAILED)

export const updateQuestionnaireAction = createAction<Questionnaire>(CONSTANTS.UPDATE_QUESTIONNAIRE)
export const updateQuestionnaireActionDone = createAction<Questionnaire>(CONSTANTS.UPDATE_QUESTIONNAIRE_DONE)
export const updateQuestionnaireActionFailed = createAction<Error>(CONSTANTS.UPDATE_QUESTIONNAIRE_FAILED)

export const deleteQuestionnaireAction = createAction<Questionnaire>(CONSTANTS.DELETE_QUESTIONNAIRE)
export const deleteQuestionnaireActionDone = createAction<number>(CONSTANTS.DELETE_QUESTIONNAIRE_DONE)
export const deleteQuestionnaireActionFailed = createAction<Error>(CONSTANTS.DELETE_QUESTIONNAIRE_FAILED)

export const cleanQuestionnaireSelectedAction = createAction(CONSTANTS.CLEAN_QUESTIONNAIRE_SELECTED)
