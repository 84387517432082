import { Typography, makeStyles, IconButton } from '@material-ui/core'
import { DeleteOutlined } from '@material-ui/icons'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import React, { useState, ChangeEvent, FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// import logoServeur from '../../../assets/logoServeur.jpg'
import AnswerModel from '../../../models/answer.model'
import { isAnswerImageValid } from '../../../utils/image-validation'
import isNotEmpty from '../../../utils/string-utils'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem',
    width: '100%',
  },
  reponse: {
    position: 'relative',
  },
  iconCard: {
    height: '10rem',
    width: '100%',
    backgroundColor: '#FFF',
    color: '#6E6E6E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1rem',
    cursor: 'pointer',
  },
  icon: {
    height: '50%',
    width: '50%',
  },
  title: {
    textAlign: 'center',
    fontSize: '0.75rem',
    padding: '0.66rem',
    color: 'black',
    fontWeight: 'bold',
  },
  image: {
    height: '66%',
    maxWidth: '88%',
    marginTop: '11px',
    borderRadius: '22px',
  },
  input: {
    display: 'none',
  },
  delete: {
    color: 'red',
    backgroundColor: 'white',
    border: '1px solid red',
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '0.25rem',
    width: '33%',
    height: 'auto',
    '&:hover': {
      color: 'white',
      backgroundColor: 'red',
    },
  },
}))

interface Props {
  num: number
  answer: string
  allAnswers: AnswerModel[]
  handleOnChange: (mediaName: string) => void
  setMyFiles: (files: AnswerModel[]) => void
  setError: (err: boolean) => void
}

const Reponse: FC<Props> = ({ num, answer, allAnswers, handleOnChange, setMyFiles, setError }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  // use States
  const [Source, setSource] = useState('')

  const addFileData = useCallback(
    (file: File, mediaName: string) => {
      const data = new FormData()
      data.append('name', mediaName)
      data.append('file_answer', file)
      const newAnswers = allAnswers
      newAnswers.push({
        id: num,
        data,
      })
      setMyFiles(newAnswers)
    },
    [allAnswers, num, setMyFiles]
  )

  // HandleChange
  const handleChange = useCallback(
    async (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      if (typeof file !== 'undefined') {
        const mediaName = `${Date.now()}_${file.name}` || 'noNameFile'
        setSource(URL.createObjectURL(file))
        handleOnChange(mediaName)

        const validity = await isAnswerImageValid(file)
        if (validity) {
          addFileData(file, mediaName)
          setError(false)
        } else {
          setError(true)
        }
      }
    },
    [addFileData, handleOnChange, setError]
  )

  // HandleDelete
  const handleOnDeleteTheme = useCallback(() => {
    setSource('')
    handleOnChange('')
    const newAnswers = allAnswers.filter((myAnswer) => myAnswer.id !== num)
    setMyFiles(newAnswers)
  }, [allAnswers, handleOnChange, num, setMyFiles])

  return (
    <div className={classes.container}>
      <div className={classes.reponse}>
        {isNotEmpty(answer) ? (
          <div className={classes.iconCard}>
            <img
              src={isNotEmpty(Source) ? Source : `https://licences.i-quiz.fr/dev/answers/${answer}`}
              alt=""
              className={classes.image}
            />
            <span style={{ textAlign: 'center' }}>{`${answer?.split('_')[1]}`}</span>
            <IconButton onClick={handleOnDeleteTheme} className={classes.delete}>
              <DeleteOutlined />
            </IconButton>
          </div>
        ) : (
          <label htmlFor={`file_${num}`} className={classes.iconCard}>
            <SaveAltIcon className={classes.icon} />
            <input
              className={classes.input}
              type="file"
              id={`file_${num}`}
              accept=".jpg,.jpeg,.png"
              onChange={handleChange}
            />
          </label>
        )}

        <Typography className={classes.title}>{`${t('reponse')} ${num}`}</Typography>
      </div>
    </div>
  )
}

export default Reponse
