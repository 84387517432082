import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ClientModel from '../../models/client.model'
import {
  getClientsAction,
  deleteClientAction,
  updateClientAction,
} from '../../redux/actions/client.actions'
import { RootStore } from '../../redux/store/root.store'
import ClientPage, { DispatchesProps, StateProps } from './index'

function mapStateToProps(store: RootStore): StateProps {
  return {
    clients: store.clients.clients,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getClients: () => dispatch(getClientsAction()),
    deleteClient: (idClient: number) => dispatch(deleteClientAction(idClient)),
    UpdateClient: (client: ClientModel) => dispatch(updateClientAction(client)),
  }
}

const ClientContainer = connect(mapStateToProps, mapDispatchToProps)(ClientPage)

export default ClientContainer
