import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircle } from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import IQuestionnairePaginationRequest from '../../interfaces/IQuestionnairePaginationRequest'
import ClientModel from '../../models/client.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import ThemeModel from '../../models/theme.model'
import { RootStore } from '../../redux/store/root.store'
import CustomCheckBox from '../custom-checkbox'
import DialogDelete from '../dialog-delete'
import KidCheckBox from '../kid-checkbox'
import TargetCheckBox from '../target-checkbox'
import QuestionnaireItem from './questionnaire-item'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '1.5rem',
    maxWidth: 'unset',
    height: '100%',
    fontSize: '.85rem',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#e5c219',
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderRadius: '9px',
    padding: '4px 8px',
    '& svg': {
      fontSize: '2rem !important',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: '#ffffff',
    display: 'flex',
    '& > label': {
      fontWeight: 900,
    },
    '& > div': {
      height: '3.4rem',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '26px',
    },
    '& .MuiFormLabel-root': {
      fontSize: 'inherit',
    },
  },
  childGrid: {
    height: '3.4rem',
  },
  questionnaire_pagination_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1.5em',
    '& > nav': {
      width: 'fit-content',
    },
  },
  resultMatchingTerm: {
    backgroundColor: '#ffffff',
    marginBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > nav': {
      width: 'fit-content',
    },
    '& > p': {
      marginLeft: '1em',
      color: 'black',
    },
  Link: {
    backgroundColor: '#ffffff',
    marginBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > nav': {
      width: 'fit-content',
    },
    '& > p': {
      marginLeft: '1em',
      color: 'black',
    },
  }
  },
}))

export interface StateProps {
  questionnaires: QuestionnaireModel[]
  questionnairesTotal: number | undefined
  themes: ThemeModel[]
  currentUser: ClientModel | undefined
}

export interface DispatchesProps {
  getQuestionnairesPagination: (ipaginationRequestInput: IQuestionnairePaginationRequest) => void
  updateQuestionnaire: (questionnaire: QuestionnaireModel) => void
  deleteQuestionnaire: (questionnaire: QuestionnaireModel) => void
  cleanSelectedQuestionnaire: () => void
  getThemes: (idClient: number) => void
}

export type Props = StateProps & DispatchesProps

const Questionnaires = ({
  questionnaires,
  questionnairesTotal,
  themes,
  currentUser,
  getQuestionnairesPagination,
  updateQuestionnaire,
  deleteQuestionnaire,
  cleanSelectedQuestionnaire,
  getThemes,
}: Props) => {
  const classes = useStyles()
  const history = useHistory()
  // Translation
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState('')
  const [currentQuestionnaires, setCurrentQuestionnaires] = useState<QuestionnaireModel[]>()
  const [isOpenDialogDeleteQuestionnaire, setOpenDialogDeleteQuestionnaire] = useState(false)
  const [lang, setLang] = useState<string>('TOUS')
  const [themeId, setThemeId] = useState<number>(-1)
  const [isChildChecked, setChildChecked] = useState<boolean>(false)
  const [isTargetChecked, setTargetChecked] = useState<boolean>(false)
  const [isCustomChecked, setCustomChecked] = useState<boolean>(false)

  const [questionnaireToDelete, setQuestionnaireToDelete] = useState<QuestionnaireModel>()
  const [titleDeleteQuestionnaire, setTitleDeleteQuestionnaire] = useState<string>('')

  const [paginationLimit, setPaginationLimit] = useState(10)
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1)
  const [paginationPagesCount, setPaginationPagesCount] = useState<number | undefined>()

  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)

  const handleFilterChild = (checked: boolean) => {
    setChildChecked(checked)
    setPaginationCurrentPage(1)
  }

  const handleFilterTarget = (checked: boolean) => {
    setTargetChecked(checked)
    setPaginationCurrentPage(1)
  }

  const handleFilterCustom = (checked: boolean) => {
    setCustomChecked(checked)
    setPaginationCurrentPage(1)
  }

  const handleChangeLang = (event: React.ChangeEvent<{ value: unknown }>) => {
    const currentLang = event.target.value as string
    setLang(currentLang)
    setPaginationCurrentPage(1)
  }

  const handleChangeTheme = (event: React.ChangeEvent<{ value: unknown }>) => {
    const currentTheme = event.target.value as number
    setThemeId(currentTheme)
    setPaginationCurrentPage(1)
  }






  const handleSearch = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchTerm(event.target.value)
    
  }

  const handleClickDeleteQuestionnaire = useCallback(
    (myQuestionnaire: QuestionnaireModel) => {
      setQuestionnaireToDelete(myQuestionnaire)
      const leQuestionnaireToDelete = questionnaires.filter((questionnaire) => questionnaire.id === myQuestionnaire.id)
      setTitleDeleteQuestionnaire(leQuestionnaireToDelete[0].title)
      setOpenDialogDeleteQuestionnaire(true)
    },
    [questionnaires]
  )

  const confirmeDeleteQuestionnaire = useCallback(() => {
    if (typeof questionnaireToDelete !== 'undefined') {
      deleteQuestionnaire({ ...questionnaireToDelete, visible: false, lastModifiedAt: new Date(Date.now()) })
      setOpenDialogDeleteQuestionnaire(false)
    }
  }, [questionnaireToDelete, deleteQuestionnaire])

 
  const displayQuestionnaires = useCallback(() => {
    if (!currentQuestionnaires) {
      return []
    }






















   // const handleClick = (questionId: number | undefined, questionnaireId: number | undefined, questionnaireOwner: number) => {
     

  //  history.push({
  //    pathname: `/questionnaire/${questionnaireId}/question/${questionId}`,
   //   state: {
  //      page: 'questions-details', 
  //      idQuestion: questionId,
  //      
  //    },
  //  })
 // }

    return currentQuestionnaires
      .filter((questionnaire) => 

      
        
          questionnaire.visible &&
          ((currentClient?.isAdmin &&
            (questionnaire.idClient === -1 || questionnaire.enProd)) ||
            (!currentClient?.isAdmin &&
              (questionnaire.idClient === currentClient?.id ||
                questionnaire.idClient === -1)))
        
      )
      
      .filter((questionnaire) => {
        // Add additional conditions based on your search requirements
        const lowerCaseSearchTerm = searchTerm.toLowerCase()
        
        return (
          questionnaire.title.toLowerCase().includes(lowerCaseSearchTerm) ||
          questionnaire.raisonSocial.toLowerCase().includes(lowerCaseSearchTerm) ||
          questionnaire.questions.some((question) => question.title.toLowerCase().includes(lowerCaseSearchTerm)) ||
          questionnaire.questions.some((question) => question.explanation.toLowerCase().includes(lowerCaseSearchTerm)) ||
          questionnaire.questions.some((question) => question.firstAnswer.toLowerCase().includes(lowerCaseSearchTerm)) ||
          questionnaire.questions.some((question) => question.secondAnswer.toLowerCase().includes(lowerCaseSearchTerm)) ||
          questionnaire.questions.some((question) => question.thirdAnswer.toLowerCase().includes(lowerCaseSearchTerm)) ||
          questionnaire.questions.some((question) => question.forthAnswer.toLowerCase().includes(lowerCaseSearchTerm)) 
          
        )
      
      })

       
      .map((questionnaire) => (
        
        
        <div key={questionnaire.id?.toString()}>
        <QuestionnaireItem
          key={questionnaire.id?.toString()}
          questionnaire={questionnaire}
          onUpdateQuestionnaire={updateQuestionnaire}
          onClickDeleteQuestionnaire={handleClickDeleteQuestionnaire}
        />
        
        {searchTerm && // Conditionally render the inline display only if there's a search term
          questionnaire.questions
            .filter((question) =>
            
            
              question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.explanation.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.firstAnswer.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.secondAnswer.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.thirdAnswer.toLowerCase().includes(searchTerm.toLowerCase()) ||
              question.forthAnswer.toLowerCase().includes(searchTerm.toLowerCase()) 
            )
            .map((matchingQuestion) => (

              
              <Card /* onClick={() => handleClick(matchingQuestion.id, questionnaire.id, questionnaire.idClient)} */ >
                {console.log(questionnaire.idClient)}
                
              <div className={classes.resultMatchingTerm}>
                
              {matchingQuestion.title.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>Question: {matchingQuestion.title}</p>
    )}

           
    {matchingQuestion.explanation.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>Explication: {matchingQuestion.explanation}
      {matchingQuestion.title && (
          <span> ( Question: {matchingQuestion.title})</span>
        )}
        </p>
    )}
    {matchingQuestion.firstAnswer.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>
        Reponse 1: {matchingQuestion.firstAnswer}
        {matchingQuestion.title && (
          <span> ( Question: {matchingQuestion.title})</span>
        )}
      </p>
    )}
    {matchingQuestion.secondAnswer.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>
         Reponse 2: {matchingQuestion.secondAnswer}
        {matchingQuestion.title && (
          <span> ( Question: {matchingQuestion.title})</span>
        )}
      </p>
    )}
    {matchingQuestion.thirdAnswer.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>
        Reponse 3: {matchingQuestion.thirdAnswer}
        {matchingQuestion.title && (
          <span> ( Question: {matchingQuestion.title})</span>
        )}
      </p>
    )}
    {matchingQuestion.forthAnswer.toLowerCase().includes(searchTerm.toLowerCase()) && (
      <p>
        Reponse 4: {matchingQuestion.forthAnswer}
        {matchingQuestion.title && (
          <span> ( Question: {matchingQuestion.title})</span>
        )}
      </p>
    
    )}
  </div>
  </Card>
            ))}
      </div>
      ))
  }, [currentQuestionnaires, searchTerm, updateQuestionnaire, handleClickDeleteQuestionnaire])

  const handleOnClickCreateQuestionnaire = useCallback(() => {
    cleanSelectedQuestionnaire()
    history.push({ pathname: '/questionnaire/new', state: { tab: 1, page: 'questionnaire', toEdit: false } })
  }, [cleanSelectedQuestionnaire, history])

  const handleOnChangePaginationCurrentPage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPaginationCurrentPage(page)
  }


  
  const handleChangePaginationLimit = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const currentPaginationLimit = event.target.value as number
    setPaginationLimit(currentPaginationLimit)
  }, [])

 

  useEffect(() => {
    
    
    const effectivePaginationLimit = searchTerm ? 99999999 : paginationLimit
    console.log('Fetching questionnaires with searchTerm:', searchTerm)
    if (effectivePaginationLimit >= 0 && paginationCurrentPage > 0) {
      getQuestionnairesPagination({
        searchTerm,
        limit: effectivePaginationLimit,
        page: paginationCurrentPage,
        child: isChildChecked,
        lang: lang === 'TOUS' ? '' : lang,
        themeId,
        idClient: currentClient?.isAdmin ? -1 : currentClient?.id!,
        isTarget: isTargetChecked,
        isCustom: isCustomChecked,
      })
    }
  }, [
    getQuestionnairesPagination,
    paginationLimit,
    paginationCurrentPage,
    isChildChecked,
    lang,
    themeId,
    currentClient,
    isTargetChecked,
    isCustomChecked,
    searchTerm,
    t,
  ])

  useEffect(() => {
    if (questionnairesTotal) {
      setPaginationPagesCount(Math.ceil(questionnairesTotal / paginationLimit))
    }
  }, [questionnairesTotal, paginationLimit])

  useEffect(() => {
    setCurrentQuestionnaires(questionnaires)
  }, [questionnaires])

  useEffect(() => {
    if (currentClient && currentClient.id) {
      getThemes(currentClient?.isAdmin ? -1 : currentClient?.id)
    }
  }, [currentClient, getThemes])

  return (
    <Container className={classes.rootContainer}>
      <DialogDelete
        title={titleDeleteQuestionnaire}
        isOpenDialog={isOpenDialogDeleteQuestionnaire}
        setOpenDialog={setOpenDialogDeleteQuestionnaire}
        confirmeDelete={confirmeDeleteQuestionnaire}
        validationText={t('questionnaireDeleteValidation')}
        questiontitle={`${t('questionnaireDeleteTitle')} `}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            startIcon={<AddCircle />}
            onClick={handleOnClickCreateQuestionnaire}
          >
            {t('newQuestionnaire')}
          </Button>
        </Grid>

        <Grid item xs={2}>
        
        <TextField
        type="text"
        label={t('search')}
        value={searchTerm}
        variant='outlined'
        onChange={handleSearch}
      />
        </Grid>
        
        <Grid item xs={2}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">{t('themeFinal')}</InputLabel>
            <Select labelId="theme-select-label" id="demo-simple-select" value={themeId || -1} onChange={handleChangeTheme}>
              <MenuItem value={-1}>
                <em>{t('tous')}</em>
              </MenuItem>
              {themes.map(
                (themeSelected: ThemeModel) =>
                  themeSelected.visible &&
                  (themeSelected.idClient === -1 || // if theme is created by ADMIN
                    themeSelected.idClient === currentUser?.id || // if theme created by the current client
                    (themeSelected.idClient !== currentUser?.id && themeSelected.inProd && currentUser?.isAdmin)) && ( // if theme from another client but is inProd and the current user is ADMIN
                    <MenuItem key={themeSelected.label} value={themeSelected.id}>
                      {themeSelected.label}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">{t('langue')}</InputLabel>
            <Select labelId="lang-select-label" id="demo-simple-select" value={lang || ''} onChange={handleChangeLang}>
              <MenuItem value="TOUS">
                <em>{t('tous')}</em>
              </MenuItem>
              <MenuItem value="FR">FR</MenuItem>
              <MenuItem value="EN">EN</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.childGrid} justifyContent="flex-end">
          <KidCheckBox onClick={handleFilterChild} currentChecked={isChildChecked} fullWidth backgroundColor="#c7c7c7" />
        </Grid>
        <Grid xs={1} className={classes.childGrid} justifyContent="flex-end">
          <TargetCheckBox
            onClick={handleFilterTarget}
            currentChecked={isTargetChecked}
            fullWidth
            backgroundColor="#c7c7c7"
          />
        </Grid>
        {currentUser && !currentUser.isAdmin && (
          <Grid xs={1} className={classes.childGrid} justifyContent="flex-end">
            <CustomCheckBox
              onClick={handleFilterCustom}
              currentChecked={isCustomChecked}
              fullWidth
              backgroundColor="#c7c7c7"
            />
          </Grid>
        )}
      </Grid>
      {displayQuestionnaires()}
      {!searchTerm &&
      <div className={classes.questionnaire_pagination_container}>
        <Pagination
          page={paginationCurrentPage}
          count={paginationPagesCount}
          onChange={handleOnChangePaginationCurrentPage}
          showFirstButton
          showLastButton
          color="secondary"
          shape="rounded"
        />

        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Items {t('in')} page</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paginationLimit}
            onChange={handleChangePaginationLimit}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>
      }
    </Container>
  )
}

export default Questionnaires
