import { Button, createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Update, Block } from '@material-ui/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    padding: '2rem',
  },
  button: {
    margin: '1rem',
    color: 'white',
    fontWeight: 'bold',
  },
}))
const theme = createTheme({
  palette: {
    primary: red,
  },
})

export interface OwnProps {
  saveQuestion: () => void
  idQuestionnaire: number
}

export type Props = OwnProps

const ActionQuestionFormButton = ({ saveQuestion, idQuestionnaire }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const handleOnClickUpdate = useCallback(
    (e) => {
      e.preventDefault()
      saveQuestion()
    },
    [saveQuestion]
  )
  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<Update />}
          onClick={handleOnClickUpdate}
        >
          {t('valider')}
        </Button>
        <ThemeProvider theme={theme}>
          <Link
            to={{
              pathname: `/questionnaire/${idQuestionnaire}`,
              state: { tab: 1, page: 'questionnaire', data: idQuestionnaire },
            }}
          >
            <Button variant="contained" className={classes.button} color="primary" startIcon={<Block />}>
              {t('annuler')}
            </Button>
          </Link>
        </ThemeProvider>
      </div>
    </>
  )
}

export default ActionQuestionFormButton
