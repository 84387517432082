/* eslint-disable jsx-a11y/label-has-associated-control */
import { makeStyles, Typography, IconButton } from '@material-ui/core'
import { DeleteOutlined, CloudDoneTwoTone } from '@material-ui/icons'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import React, { useState, useCallback, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { isImageValid, isVideoValid } from '../../../utils/image-validation'
// import logoServeur from '../../../assets/logoServeur.jpg'
import mediaAccepted from '../../../utils/media-types'
import isNotEmpty from '../../../utils/string-utils'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem',
  },
  question: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  iconCard: {
    height: '15rem',
    width: '100%',
    backgroundColor: '#FFF',
    color: '#6E6E6E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1rem',
    paddingBottom: '0.5rem',
  },
  image: {
    height: '66%',
    width: 'auto',
    marginTop: '11px',
    borderRadius: '22px',
  },
  icon: {
    height: '50%',
    width: '50%',
  },
  title: {
    fontSize: '1.5rem',
    padding: '0.66rem 0',
    color: 'black',
    fontWeight: 'bold',
  },
  input: {
    display: 'none',
  },
  delete: {
    color: 'red',
    backgroundColor: 'white',
    border: '2px solid red',
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '1rem',
    width: '3rem',
    '&:hover': {
      color: 'white',
      backgroundColor: 'red',
      border: '2px solid white',
    },
  },
  name: {
    color: 'green',
    fontSize: '1.5rem',
    padding: '0.66rem 0',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
}))
interface Props {
  type: string
  fileName: string | undefined
  questionMediaError: string | undefined
  handleOnChange: (name: string) => void
  setMyFile: (file: FormData) => void
  setFileName: (name: string) => void
  setQuestionMediaError: (error: string) => void
}

const QuestionMediaPhoto = ({
  type,
  fileName,
  questionMediaError,
  setFileName,
  setQuestionMediaError,
  handleOnChange,
  setMyFile,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [Source, setSource] = useState('')

  const createFileData = useCallback(
    (file: File, mediaName: string) => {
      const data = new FormData()
      data.append('name', mediaName)
      data.append('file', file)
      setMyFile(data)
    },
    [setMyFile]
  )

  const handleChange = useCallback(
    async (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      if (typeof file !== 'undefined') {
        const mediaName = `${Date.now()}_${file.name}` || 'noNameFile'
        setSource(URL.createObjectURL(file))
        setFileName(mediaName)
        handleOnChange(mediaName)
        if (type === 'IMAGE') {
          const validity = await isImageValid(file)
          if (validity) {
            createFileData(file, mediaName)
            setQuestionMediaError('')
          } else {
            setQuestionMediaError(t('uploadMediaError'))
          }
        }
        if (type === 'VIDEO') {
          const validity = await isVideoValid(file)
          if (validity) {
            createFileData(file, mediaName)
            setQuestionMediaError('')
          } else {
            setQuestionMediaError(t('uploadMediaError'))
          }
        }
      }
    },
    [createFileData, handleOnChange, setFileName, setQuestionMediaError, t, type]
  )

  const handleOnDeleteTheme = useCallback(() => {
    setFileName('')
    handleOnChange('')
  }, [handleOnChange, setFileName])

  const displayMedia = () => {
    switch (type) {
      case 'IMAGE':
        return (
          <img
            src={isNotEmpty(Source) ? Source : `https://licences.i-quiz.fr/dev/questions/${fileName}`}
            alt=""
            className={classes.image}
          />
        )
      case 'VIDEO':
        return (
          <video width="400" height="400" autoPlay controls muted className={classes.image}>
            <source
              src={isNotEmpty(Source) ? Source : `https://licences.i-quiz.fr/dev/questions/${fileName}`}
              type="video/mp4"
            />
          </video>
        )
      default:
        return null
    }
  }

  const displayIndications = () => {
    switch (type) {
      case 'IMAGE':
        return (
          <div>
            <div style={{ color: 'gray' }}>
              <b>- {t('type')}:</b> jpg
            </div>
            <div style={{ color: 'gray' }}>
              <b>- {t('tailleMax')} :</b> 300 ko
            </div>
            <div style={{ color: 'gray' }}>
              <b>
                - {t('largeur')} x {t('hauteur')} :
              </b>{' '}
              900 x 330 ({t('fixe')})
            </div>
          </div>
        )
      case 'VIDEO':
        return (
          <div>
            <div style={{ color: 'gray' }}>
              <b>- {t('type')}:</b> mp4
            </div>
            <div style={{ color: 'gray' }}>
              <b>- {t('tailleMax')} :</b> 10 Mo
            </div>
            <div style={{ color: 'gray' }}>
              <b>
                - {t('largeur')} x {t('hauteur')} :
              </b>{' '}
              900 x 330 ({t('fixe')})
            </div>
          </div>
        )
      default:
        return null
    }
  }
  const getAcceptedMedia = () => {
    switch (type) {
      case 'IMAGE':
        return mediaAccepted.IMAGE
      case 'VIDEO':
        return mediaAccepted.VIDEO
      default:
        return ''
    }
  }
  return (
    <div
      style={{
        marginBottom: '1rem',
      }}
    >
      <div className={classes.container}>
        <Typography className={classes.title}>{`${t('uploadQuestion')} - ${type} :`}</Typography>
        {fileName === '' ? (
          <label htmlFor="file" className={classes.question}>
            <div className={classes.iconCard}>
              <SaveAltIcon className={classes.icon} />
            </div>
          </label>
        ) : (
          <div className={classes.question}>
            <div className={classes.iconCard}>
              {displayMedia()}
              <span className={classes.name}>
                <CloudDoneTwoTone /> {`${fileName?.split('_')[1]}`}
              </span>
              <span style={{ color: 'red' }}>{questionMediaError}</span>
              <IconButton onClick={handleOnDeleteTheme} className={classes.delete}>
                <DeleteOutlined />
              </IconButton>
            </div>
          </div>
        )}

        <input className={classes.input} type="file" id="file" accept={getAcceptedMedia()} onChange={handleChange} />
      </div>
      {displayIndications()}
    </div>
  )
}

export default QuestionMediaPhoto
