/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardContent, IconButton, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { DragIndicator, ImageOutlined, OndemandVideo, VolumeUpOutlined } from '@material-ui/icons'
import React, { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import QuestionModel from '../../models/question.model'
import ActionQuestionItem from './action-question-item'

const useStyles = makeStyles((theme) => ({
  questionItem: {
    minWidth: '100%',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  questionCardContent: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '0 0 0 0rem',
    '&:last-child': {
      padding: '0 0 0 1rem',
    },
  },
  questionTitle: {
    fontSize: 22,
    color: '#000000',
  },
  delete: {
    color: red[500],
  },
  questionnaireItem: {
    minWidth: '100%',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    color: '#000000',
  },
  questionnaireContent: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
  },
  dragAndTitleContainer: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: '50%',
  },
  questionTypeIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  questionActionIconContainer: {},
  link: {
    flex: 1,
  },
}))

interface Props {
  question: QuestionModel
  onDeleteQuestion: (question: QuestionModel) => void
  index: number
}

function getQuestionTypeIcon(questionType: string | undefined): JSX.Element | undefined {
  if (questionType === 'video') {
    return <OndemandVideo />
  }
  if (questionType === 'audio') {
    return <VolumeUpOutlined />
  }
  if (questionType === 'image') {
    return <ImageOutlined color="action" />
  }
  return undefined
}

const QuestionItem = ({ question, onDeleteQuestion, index }: Props) => {
  const { title, questionType, id, idQuestionnaire } = question
  const classes = useStyles()

  console.log('+questions item 1 ')
  const handleOnDeleteQuestion = useCallback(() => {
    console.log('+handi=ling delete 2 ')
    if (typeof id !== 'undefined') {
      console.log('+handi=ling delete 3')
      onDeleteQuestion({ ...question, visible: false, lastModifiedAt: new Date(Date.now()) })
    }
  }, [id, onDeleteQuestion, question])

  return (
    <Draggable key={question.id} draggableId={String(question.id)} index={index}>
      {(provided) => (
        <Card
          className={classes.questionnaireItem}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <CardContent className={classes.questionCardContent}>
            <div className={classes.dragAndTitleContainer}>
              <IconButton aria-label="sign out" component="span">
                <DragIndicator />
              </IconButton>
              <Link
                to={{
                  pathname: `/questionnaire/${idQuestionnaire}/question/update/${id}`,
                  state: {
                    tab: 1,
                    page: 'question/update',
                    data: idQuestionnaire,
                    question,
                  },
                }}
                className={classes.link}
              >
                <p className={classes.questionnaireContent}>
                  <span>{title}</span>
                </p>
              </Link>
            </div>
            <div className={classes.questionTypeIconContainer}>{getQuestionTypeIcon(questionType)}</div>
            <div className={classes.questionActionIconContainer}>
              <ActionQuestionItem question={question} onDelete={handleOnDeleteQuestion} />
            </div>
          </CardContent>
        </Card>
      )}
    </Draggable>
  )
}

export default QuestionItem
