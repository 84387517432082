import { createAction } from 'redux-actions'

import Client from '../../models/client.model'
import * as CONSTANTS from './constants'

export const getClientsAction = createAction(CONSTANTS.GET_CLIENTS)
export const getClientsActionDone = createAction<Client[]>(CONSTANTS.GET_CLIENTS_DONE)
export const getClientsActionFailed = createAction<Error>(CONSTANTS.GET_CLIENTS_FAILED)

export const loginClientAction = createAction<{ mail: string; motDePasse: string }>(CONSTANTS.LOGIN_CLIENT)
export const loginClientActionDone = createAction<Client>(CONSTANTS.LOGIN_CLIENT_DONE)
export const loginClientActionFailed = createAction<Error>(CONSTANTS.LOGIN_CLIENT_FAILED)

export const logoutClientAction = createAction(CONSTANTS.LOGOUT_CLIENT)

export const authClientAction = createAction(CONSTANTS.AUTH_CLIENT)

export const loadingStart = createAction(CONSTANTS.LOADING_START)
export const loadingDone = createAction(CONSTANTS.LOADING_DONE)

export const getClientAction = createAction<number>(CONSTANTS.GET_CLIENT)
export const getClientActionDone = createAction<Client>(CONSTANTS.GET_CLIENT_DONE)
export const getClientActionFailed = createAction<Error>(CONSTANTS.GET_CLIENT_FAILED)

export const addClientAction = createAction<Client>(CONSTANTS.ADD_CLIENT)
export const addClientActionDone = createAction<Client>(CONSTANTS.ADD_CLIENT_DONE)
export const addClientActionFailed = createAction<Error>(CONSTANTS.ADD_CLIENT_FAILED)

export const updateClientAction = createAction<Client>(CONSTANTS.UPDATE_CLIENT)
export const updateClientActionDone = createAction<Client>(CONSTANTS.UPDATE_CLIENT_DONE)
export const updateClientActionFailed = createAction<Error>(CONSTANTS.UPDATE_CLIENT_FAILED)

export const deleteClientAction = createAction<number>(CONSTANTS.DELETE_CLIENT)
export const deleteClientActionDone = createAction<number>(CONSTANTS.DELETE_CLIENT_DONE)
export const deleteClientActionFailed = createAction<Error>(CONSTANTS.DELETE_CLIENT_FAILED)
