import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'

import IHistoryCalculations from '../../../interfaces/IHistoryCalculations'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    marginTop: '1rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
  },
  text: {
    paddingTop: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  red: {
    color: red[500],
  },
}))

export interface OwnProps {
  historyCalculations: IHistoryCalculations | undefined
}

export type Props = OwnProps

const SessionsSimplifiee = ({ historyCalculations }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <span>{`- ${t('nombreSessionsPendantPeriode')} : `}</span>
        <span className={classes.red}>{historyCalculations?.sessionLaunch}</span>
      </div>
      <div className={classes.text}>
        <span>{`- ${t('sessionsValidePendantPeriode')} : `}</span>
        <span className={classes.red}>{historyCalculations?.sessionValide}</span>
      </div>
      <div className={classes.text}>
        <span>{`- ${t('equipesavecSessionPendantPeriode')} : `}</span>
        <span className={classes.red}>{historyCalculations?.teamSessionLaunch}</span>
      </div>
      <div className={classes.text}>
        <span>{`- ${t('equipesAvecSessionValidee')} : `}</span>
        <span className={classes.red}>{historyCalculations?.teamSessionValide}</span>
      </div>
      <div className={classes.text}>
        <span>{`- ${t('joueursAvecSessionLancee')} : `}</span>
        <span className={classes.red}>{historyCalculations?.joueurUniqueSessionLaunch}</span>
      </div>
      <div className={classes.text}>
        <span>{`- ${t('joueursAvecSessionValidee')} : `}</span>
        <span className={classes.red}>{historyCalculations?.joueurUniqueSessionValide}</span>
      </div>
      {/* a la demande du client nous avons masqué le nombre de joueur
       mais je pense que le client va vouloir l'afficher prochainement */}
      {/* <div className={classes.text}>
        <span>{'- Nombre de nouveaux profils créés pendant la période : '}</span>
        <span className={classes.red}>
          {totalPlayerCreatedMemo}
        </span>
      </div> */}
    </div>
  )
}

export default SessionsSimplifiee
