import { Checkbox, makeStyles } from '@material-ui/core'
import { ChildCare } from '@material-ui/icons'
import React, { useCallback } from 'react'

export interface KidsStyle {
  fullWidth: boolean | undefined
  backgroundColor: string | undefined
}
const useStyles = makeStyles(() => ({
  kidIcon: {
    color: 'black',
    backgroundColor: 'yellow',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    padding: '.2rem',
  },
  kidsSelectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props: KidsStyle) => (typeof props.fullWidth !== 'undefined' ? '85%' : '6rem'),
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: (props: KidsStyle) => (typeof props.backgroundColor !== 'undefined' ? props.backgroundColor : 'white'),
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    height: '100%',
    margin: '0 auto',
  },
  kidCheckBox: {
    padding: '.4rem',
    color: '#7d7b7b !important',
  },
}))

export interface OwnProps {
  onClick: (isChecked: boolean) => void
  currentChecked: boolean
  fullWidth?: boolean
  backgroundColor?: string
}

export type Props = OwnProps

const KidCheckBox = ({ onClick, currentChecked, fullWidth, backgroundColor }: Props) => {
  const classes = useStyles({ fullWidth, backgroundColor })

  const handlOnChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onClick(checked)
    },
    [onClick]
  )

  return (
    <div className={classes.kidsSelectContainer}>
      <ChildCare className={classes.kidIcon} />
      <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }}
        onChange={handlOnChange}
        checked={currentChecked}
        className={classes.kidCheckBox}
      />
    </div>
  )
}
export default KidCheckBox
