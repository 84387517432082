import { createAction } from 'redux-actions'

import Question from '../../models/question.model'
import * as CONSTANTS from './constants'

export const getQuestionsAction = createAction<number>(CONSTANTS.GET_QUESTIONS)
export const getQuestionsActionDone = createAction<Question[]>(CONSTANTS.GET_QUESTIONS_DONE)
export const getQuestionsActionFailed = createAction<Error>(CONSTANTS.GET_QUESTIONS_FAILED)

export const getQuestionAction = createAction<number>(CONSTANTS.GET_QUESTION)
export const getQuestionActionDone = createAction<Question>(CONSTANTS.GET_QUESTION_DONE)
export const getQuestionActionFailed = createAction<Error>(CONSTANTS.GET_QUESTION_FAILED)

export const addQuestionAction = createAction<Question>(CONSTANTS.ADD_QUESTION)
export const addQuestionActionDone = createAction<Question>(CONSTANTS.ADD_QUESTION_DONE)
export const addQuestionActionFailed = createAction<Error>(CONSTANTS.ADD_QUESTION_FAILED)

export const updateQuestionAction = createAction<Question>(CONSTANTS.UPDATE_QUESTION)
export const updateQuestionActionDone = createAction<Question>(CONSTANTS.UPDATE_QUESTION_DONE)
export const updateQuestionActionFailed = createAction<Error>(CONSTANTS.UPDATE_QUESTION_FAILED)

export const updateMultipleQuestionsAction = createAction<Question[]>(CONSTANTS.UPDATE_MULTIPLE_QUESTIONS)
export const updateMultipleQuestionsActionDone = createAction<Question[]>(CONSTANTS.UPDATE_MULTIPLE_QUESTIONS_DONE)
export const updateMultipleQuestionsActionFailed = createAction<Error>(CONSTANTS.UPDATE_MULTIPLE_QUESTIONS_FAILED)

export const deleteQuestionAction = createAction<number>(CONSTANTS.DELETE_QUESTION)
export const deleteQuestionActionDone = createAction<number>(CONSTANTS.DELETE_QUESTION_DONE)
export const deleteQuestionActionFailed = createAction<Error>(CONSTANTS.DELETE_QUESTION_FAILED)

// Upload Question Media
export const uploadQuestionMedia = createAction<any>(CONSTANTS.UPLOAD_QUESTION_MEDIA)
export const uploadQuestionMediaDone = createAction<any>(CONSTANTS.UPLOAD_QUESTION_MEDIA_DONE)
export const uploadQuestionMediaFailed = createAction<Error>(CONSTANTS.UPLOAD_QUESTION_MEDIA_FAILED)

// Upload Answer Media
export const uploadAnswerMedia = createAction<any>(CONSTANTS.UPLOAD_ANSWER_MEDIA)
export const uploadAnswerMediaDone = createAction<any>(CONSTANTS.UPLOAD_ANSWER_MEDIA_DONE)
export const uploadAnswerMediaFailed = createAction<Error>(CONSTANTS.UPLOAD_ANSWER_MEDIA_FAILED)
