import { createAction } from 'redux-actions'

import Tag from '../../models/tag.model'
import * as CONSTANTS from './constants'

export const getTagsAction = createAction(CONSTANTS.GET_TAGS)
export const getTagsActionDone = createAction<Tag[]>(CONSTANTS.GET_TAGS_DONE)
export const getTagsActionFailed = createAction<Error>(CONSTANTS.GET_TAGS_FAILED)

export const getTagAction = createAction<number>(CONSTANTS.GET_TAG)
export const getTagActionDone = createAction<Tag>(CONSTANTS.GET_TAG_DONE)
export const getTagActionFailed = createAction<Error>(CONSTANTS.GET_TAG_FAILED)

export const addTagAction = createAction<Tag>(CONSTANTS.ADD_TAG)
export const addTagActionDone = createAction<Tag>(CONSTANTS.ADD_TAG_DONE)
export const addTagActionFailed = createAction<Error>(CONSTANTS.ADD_TAG_FAILED)

export const addMultipleTagsAction = createAction<Tag[]>(CONSTANTS.ADD_MULTIPLE_TAGS)
export const addMultipleTagsActionDone = createAction<Tag[]>(CONSTANTS.ADD_MULTIPLE_TAGS_DONE)
export const addMultipleTagsActionFailed = createAction<Error>(CONSTANTS.ADD_MULTIPLE_TAGS_FAILED)

export const deleteTagAction = createAction<number>(CONSTANTS.DELETE_TAG)
export const deleteTagActionDone = createAction<number>(CONSTANTS.DELETE_TAG_DONE)
export const deleteTagActionFailed = createAction<Error>(CONSTANTS.DELETE_TAG_FAILED)
