import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ClientModel from '../../../models/client.model'
import {
  addClientAction,
  updateClientAction,
} from '../../../redux/actions/client.actions'
import { RootStore } from '../../../redux/store/root.store'
import ClientForm, { StateProps, DispatchesProps } from './index'

function mapStateToProps(
  store: RootStore,
): StateProps {
  return {
    emails: store.clients.emails,
    error: store.clients.error,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    addNewClient: (client: ClientModel) => dispatch(addClientAction(client)),
    updateClient: (client: ClientModel) => (
      dispatch(updateClientAction(client))
    ),
  }
}

const ClientFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientForm)

export default ClientFormContainer
