import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import errorMiddleware from '../middlewares/error.middleware'
import sucessMiddleware from '../middlewares/success.middleware'
import clientReducer, { ClientsState } from '../reducer/client.reducer'
import errorsReducer, { ErrorsState } from '../reducer/errors.reducer'
import questionReducer, { QuestionsState } from '../reducer/question.reducer'
import questionnaireReducer, { QuestionnairesState } from '../reducer/questionnaire.reducer'
import sessionReducer, { SessionState } from '../reducer/session.reducer'
import tagReducer, { TagState } from '../reducer/tag.reducer'
import themeReducer, { ThemeState } from '../reducer/theme.reducer'
import rootSaga from '../saga/root.saga'

export interface RootStore {
  errors: ErrorsState
  questionnaires: QuestionnairesState
  questions: QuestionsState
  themes: ThemeState
  tags: TagState
  clients: ClientsState
  sessions: SessionState
}

function createRootStore() {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    combineReducers({
      questionnaires: questionnaireReducer,
      errors: errorsReducer,
      questions: questionReducer,
      themes: themeReducer,
      tags: tagReducer,
      clients: clientReducer,
      sessions: sessionReducer,
    }),
    composeWithDevTools(applyMiddleware(sagaMiddleware, errorMiddleware, sucessMiddleware)),
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default createRootStore
