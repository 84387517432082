import { AnyAction } from 'redux'

import * as CONSTANTS from '../actions/constants'

export type ErrorsState = Error[]

const initialState: ErrorsState = []

export default function errorsReducer(state = initialState, action: AnyAction) {
  if (action.type === CONSTANTS.APP_ERROR) {
    return [...state, action.payload as Error]
  }

  if (action.type === CONSTANTS.APP_ERROR_CLEAN) {
    return []
  }

  return state
}
