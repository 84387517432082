import { makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  textField: {
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  title: {
    fontSize: '1.5rem',
    padding: '0.66rem 0',
    color: 'black',
    fontWeight: 'bold',
  },
}))

export interface OwnProps {
  firstAnswer: string
  secondAnswer: string
  thirdAnswer: string
  forthAnswer: string
  onChangeAnswers: (first: string, second: string, third: string, forth: string) => void
}

export type Props = OwnProps

const QuestionAnswers = ({ firstAnswer, secondAnswer, thirdAnswer, forthAnswer, onChangeAnswers }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentFirstAnswer, setCurrentFirstAnswern] = useState<string>(firstAnswer)
  const [currentSecondAnswer, setCurrentSecondAnswern] = useState<string>(secondAnswer)
  const [currentThirdAnswer, setCurrentThirdAnswern] = useState<string>(thirdAnswer)
  const [currentForthAnswer, setCurrentForthAnswern] = useState<string>(forthAnswer)
  useEffect(() => {
    setCurrentFirstAnswern(firstAnswer)
    setCurrentSecondAnswern(secondAnswer)
    setCurrentThirdAnswern(thirdAnswer)
    setCurrentForthAnswern(forthAnswer)
  }, [firstAnswer, forthAnswer, secondAnswer, thirdAnswer])
  const handleChangeFirstAnswer = useCallback(
    (e) => {
      e.preventDefault()
      const first = e.target.value as string
      setCurrentFirstAnswern(first)
      onChangeAnswers(first, currentSecondAnswer, currentThirdAnswer, currentForthAnswer)
    },
    [currentForthAnswer, currentSecondAnswer, currentThirdAnswer, onChangeAnswers]
  )

  const handleChangeSecondAnswer = useCallback(
    (e) => {
      e.preventDefault()
      const second = e.target.value as string
      setCurrentSecondAnswern(second)
      onChangeAnswers(currentFirstAnswer, second, currentThirdAnswer, currentForthAnswer)
    },
    [currentFirstAnswer, currentForthAnswer, currentThirdAnswer, onChangeAnswers]
  )

  const handleChangeThirdAnswer = useCallback(
    (e) => {
      e.preventDefault()
      const third = e.target.value as string
      setCurrentThirdAnswern(third)
      onChangeAnswers(currentFirstAnswer, currentSecondAnswer, third, currentForthAnswer)
    },
    [currentFirstAnswer, currentForthAnswer, currentSecondAnswer, onChangeAnswers]
  )

  const handleChangeForthAnswer = useCallback(
    (e) => {
      e.preventDefault()
      const forth = e.target.value as string
      setCurrentForthAnswern(e.target.value as string)
      onChangeAnswers(currentFirstAnswer, currentSecondAnswer, currentThirdAnswer, forth)
    },
    [currentFirstAnswer, currentSecondAnswer, currentThirdAnswer, onChangeAnswers]
  )

  return (
    <>
      <Typography className={classes.title}>{`${t('addAnswers')} :`}</Typography>
      <TextField
        label={`${t('reponse')} 1`}
        variant="outlined"
        className={classes.textField}
        color="secondary"
        fullWidth
        value={currentFirstAnswer}
        onChange={handleChangeFirstAnswer}
        inputProps={{ maxLength: 45 }}
      />
      <TextField
        label={`${t('reponse')} 2`}
        variant="outlined"
        className={classes.textField}
        color="secondary"
        fullWidth
        value={currentSecondAnswer}
        onChange={handleChangeSecondAnswer}
        inputProps={{ maxLength: 45 }}
      />
      <TextField
        label={`${t('reponse')} 3`}
        variant="outlined"
        className={classes.textField}
        color="secondary"
        fullWidth
        value={currentThirdAnswer}
        onChange={handleChangeThirdAnswer}
        inputProps={{ maxLength: 45 }}
      />
      <TextField
        label={`${t('reponse')} 4`}
        variant="outlined"
        className={classes.textField}
        color="secondary"
        fullWidth
        value={currentForthAnswer}
        onChange={handleChangeForthAnswer}
        inputProps={{ maxLength: 45 }}
      />
    </>
  )
}

export default QuestionAnswers
