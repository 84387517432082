import { Card, CardContent, Grid, IconButton } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { DeleteOutlined, Lock } from '@material-ui/icons'
import React, { useCallback } from 'react'

import ThemeModel from '../../models/theme.model'

const useStyles = makeStyles(() => ({
  textTheme: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
  },
  card: {
    minWidth: '100%',
    width: '100%',
    marginTop: '1rem',
    height: '3rem',
  },
  darkCard: {
    background: '#c5c5c5',
  },
  cardContent: {
    padding: '0 0 0 1rem',
    '&:last-child': {
      padding: '0 0 0 1rem',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    width: '48px',
    marginLeft: 0,
  },
  delete: {
    color: red[500],
  },
}))

export interface StateProps {
  theme: ThemeModel
  isAdmin?: boolean
  deleteTheme: (idTheme: number) => void
}

export type Props = StateProps

const ThemeItem = ({ theme, deleteTheme, isAdmin = false }: Props) => {
  const classes = useStyles()

  const handleOnDeleteTheme = useCallback(
    (e) => {
      e.preventDefault()
      if (typeof theme.id !== 'undefined') {
        deleteTheme(theme.id)
      }
    },
    [deleteTheme, theme.id]
  )

  return (
    <>
      <Card className={`${classes.card} ${!isAdmin && theme.idClient === -1 ? classes.darkCard : null}`}>
        <CardContent className={classes.cardContent}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <p className={classes.textTheme}>
                <span>{theme.label}</span>
              </p>
            </Grid>
            <Grid item xs={1}>
              {!isAdmin && theme.idClient === -1 ? (
                <div className={classes.iconContainer}>
                  <Lock />
                </div>
              ) : (
                <IconButton onClick={handleOnDeleteTheme} className={classes.delete}>
                  <DeleteOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default ThemeItem
