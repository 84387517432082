import { Button, Checkbox, createTheme, makeStyles, Snackbar, TextField, ThemeProvider } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import bcrypt from 'bcryptjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ClientModel from '../../../models/client.model'
import isNotEmpty from '../../../utils/string-utils'
import Alert from '../../alert'

const useStyles = makeStyles(() => ({
  leClient: {
    padding: '2rem',
  },
  container: {
    padding: '2rem',
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'start',
    minWidth: '100%',
  },
  textField: {
    width: '44%',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontWeight: '600',
      color: '#333',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
      fontSize: '1rem',
      color: '#555',
    },
  },
  containerInfos: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: '#CCC',
    minWidth: '100%',
    borderRadius: '1rem',
    padding: '1rem 1rem 0 1rem',
    margin: '1rem 0',
  },
  buttonsContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    padding: '2rem',
  },
  button: {
    margin: '1rem',
    color: 'white',
    fontWeight: 'bold',
  },
  password: {
    display: 'flex',
    padding: '2rem',
  },
}))

const theme = createTheme({
  palette: {
    primary: red,
  },
})

export type Color = 'success' | 'info' | 'warning' | 'error'

export interface OwnProps {
  idClient?: number
  client?: ClientModel
  toEdit: boolean
}

export interface StateProps {
  emails: string[] | undefined
  error: string | undefined
}

export interface DispatchesProps {
  addNewClient: (client: ClientModel) => void
  updateClient: (client: ClientModel) => void
}
export type Props = OwnProps & StateProps & DispatchesProps

const ClientForm = ({ emails, client, toEdit, addNewClient, updateClient, error }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentClient, setCurrentClient] = useState<ClientModel>({ ...client, isAdmin: client?.isAdmin || false })
  const [password, setPassword] = useState<string>('')
  const [openValidationSnackbar, setOpenValidationSnackbar] = useState<boolean>(false)
  const [snackSeverity, setSnackSeverity] = useState<Color>('success')
  const [edit, setEdit] = useState<boolean>(toEdit)
  const allEmails = emails

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationSnackbar(false)
  }

  const handleOnChangeRaisonSocial = useCallback(
    (e) => {
      const RaisonSocial = e.target.value as string
      setCurrentClient({ ...currentClient, raisonSocial: RaisonSocial })
    },
    [currentClient]
  )

  const handleOnChangeNom = useCallback(
    (e) => {
      const Nom = e.target.value as string
      setCurrentClient({ ...currentClient, nom: Nom })
    },
    [currentClient]
  )

  const handleOnChangePreNom = useCallback(
    (e) => {
      const PreNom = e.target.value as string
      setCurrentClient({ ...currentClient, prenom: PreNom })
    },
    [currentClient]
  )

  const handleOnChangeMail = useCallback(
    (e) => {
      const Mail = e.target.value as string
      setCurrentClient({ ...currentClient, mail: Mail })
    },
    [currentClient]
  )

  const handleOnChangeTelephone = useCallback(
    (e) => {
      const Telephone = e.target.value as string
      setCurrentClient({ ...currentClient, telephone: Telephone })
    },
    [currentClient]
  )

  const handleOnChangeAdress = useCallback(
    (e) => {
      const Adress = e.target.value as string
      setCurrentClient({ ...currentClient, adress: Adress })
    },
    [currentClient]
  )

  const handleOnChangeCP = useCallback(
    (e) => {
      const newCP = e.target.value as string
      if (newCP === '') {
        setCurrentClient({ ...currentClient, cp: undefined })
      } else if (!Number.isNaN(parseFloat(newCP))) {
        setCurrentClient({
          ...currentClient,
          cp: parseFloat(newCP),
        })
      }
    },
    [currentClient]
  )

  const handleOnChangeVille = useCallback(
    (e) => {
      const Ville = e.target.value as string
      setCurrentClient({ ...currentClient, ville: Ville })
    },
    [currentClient]
  )

  const handleOnChangePays = useCallback(
    (e) => {
      const Pays = e.target.value as string
      setCurrentClient({ ...currentClient, pays: Pays })
    },
    [currentClient]
  )

  const handleOnChangeMotDePasse = useCallback((e) => {
    const Password = e.target.value as string
    setPassword(Password)
  }, [])

  const handleOnClickSave = async () => {
    if (
      isNotEmpty(currentClient.raisonSocial) &&
      isNotEmpty(currentClient.nom) &&
      isNotEmpty(currentClient.prenom) &&
      isNotEmpty(currentClient.mail) &&
      isNotEmpty(currentClient.telephone) &&
      isNotEmpty(currentClient.adress) &&
      isNotEmpty(currentClient.ville) &&
      isNotEmpty(currentClient.pays)
    ) {
      // hashing The Password
      const salt = await bcrypt.genSalt()
      const hashedPassword = await bcrypt.hash(password, salt)
      setSnackSeverity('success')
      if (edit) {
        updateClient({
          ...currentClient,
          motDePasse: isNotEmpty(password) ? hashedPassword : currentClient.motDePasse,
        })
      } else if (currentClient.mail && !allEmails?.includes(currentClient.mail) && isNotEmpty(password)) {
        const myClient = {
          ...currentClient,
          motDePasse: hashedPassword,
          enProd: false,
          createdAt: new Date(Date.now()),
        }
        addNewClient(myClient)
        allEmails?.push(currentClient.mail)
        setEdit(true)
      }
    } else {
      setSnackSeverity('error')
    }
    setOpenValidationSnackbar(true)
  }

  const getAlertContent = useCallback(() => {
    if (error) {
      return error
    }
    let phrase: string = ''
    if (snackSeverity === 'success') {
      if (typeof currentClient.id !== 'undefined') {
        phrase = t('editClientSuccess')
      } else {
        phrase = t('addClientSuccess')
      }
      return (
        <>
          {`${t('theClient')} `}
          <strong>
            <em>{currentClient.raisonSocial}</em>
          </strong>{' '}
          {phrase}
        </>
      )
    }
    if (currentClient.mail && allEmails?.includes(currentClient.mail)) {
      phrase = t('existingEmail')
    } else {
      phrase = t('fillAllFields')
    }
    return <>{phrase}</>
  }, [allEmails, currentClient.id, currentClient.mail, currentClient.raisonSocial, error, snackSeverity, t])

  useEffect(() => {
    if (error) {
      setOpenValidationSnackbar(true)
    }
  }, [error])

  return (
    <div className={classes.leClient}>
      <div className={classes.container}>
        <div className={classes.header}>
          <TextField
            label={t('raisonSocial')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.raisonSocial}
            onChange={handleOnChangeRaisonSocial}
          />
        </div>
        <div className={classes.containerInfos}>
          <TextField
            label={t('nom')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.nom}
            onChange={handleOnChangeNom}
          />
          <TextField
            label={t('prenom')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.prenom}
            onChange={handleOnChangePreNom}
          />
          <TextField
            label={t('mail')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.mail}
            onChange={handleOnChangeMail}
          />
          <TextField
            label={t('telephone')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.telephone}
            onChange={handleOnChangeTelephone}
          />
        </div>
        <div className={classes.containerInfos}>
          <TextField
            label={t('adress')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.adress}
            onChange={handleOnChangeAdress}
          />
          <TextField
            label={t('cp')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.cp || 0}
            onChange={handleOnChangeCP}
          />
          <TextField
            label={t('ville')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.ville}
            onChange={handleOnChangeVille}
          />
          <TextField
            label={t('pays')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={currentClient.pays}
            onChange={handleOnChangePays}
          />
        </div>
        <div className={classes.password}>
          <TextField
            label={t('password')}
            variant="outlined"
            className={classes.textField}
            color="primary"
            fullWidth
            value={password}
            onChange={handleOnChangeMotDePasse}
          />
        </div>
        <div className={classes.buttonsContainer}>
          <ThemeProvider theme={theme}>
            <Link
              to={{
                pathname: '/clients',
                state: { tab: 0, page: 'clients' },
              }}
            >
              <Button variant="contained" className={classes.button} color="primary" startIcon={<BlockOutlinedIcon />}>
                {t('annuler')}
              </Button>
            </Link>
          </ThemeProvider>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<PersonAddOutlinedIcon />}
            onClick={handleOnClickSave}
          >
            {edit ? t('mettreAjour') : t('create')}
          </Button>
        </div>
      </div>
      <Snackbar open={openValidationSnackbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={error ? 'error' : snackSeverity}>
          {getAlertContent()}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ClientForm
