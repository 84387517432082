import { AnyAction } from 'redux'
import {
  call, put, takeLatest,
} from 'redux-saga/effects'

import TagModel from '../../models/tag.model'
import * as CONSTANTS from '../actions/constants'
import {
  addMultipleTagsActionDone,
  addMultipleTagsActionFailed,
  addTagActionDone,
  addTagActionFailed,
  deleteTagActionDone,
  deleteTagActionFailed, getTagActionDone,
  getTagActionFailed, getTagsActionDone,
  getTagsActionFailed,
} from '../actions/tag.actions'
import Api from '../apis/api-factory'

function* getTags() {
  try {
    const tags: TagModel[] | [] = yield call(Api.getTags)
    yield put(getTagsActionDone(tags))
  } catch (e) {
    yield put(getTagsActionFailed(e as Error))
  }
}

function* getTag(action: AnyAction) {
  try {
    const tagId = action.payload
    const tag: TagModel = yield call(Api.getTag, tagId)
    yield put(getTagActionDone(tag))
  } catch (e) {
    yield put(getTagActionFailed(e as Error))
  }
}

function* addTag(action: AnyAction) {
  try {
    const tag = action.payload
    const tagSaved: TagModel = yield call(Api.addTag, tag)
    yield put(addTagActionDone(tagSaved))
  } catch (e) {
    yield put(addTagActionFailed(e as Error))
  }
}

function* addMultipleTags(action: AnyAction) {
  try {
    const tags: TagModel[] = action.payload
    for (let i = 0; i < tags.length; i += 1) {
      tags[i] = yield call(Api.addTag, tags[i])
    }
    yield put(addMultipleTagsActionDone(tags))
  } catch (e) {
    yield put(addMultipleTagsActionFailed(e as Error))
  }
}

function* deleteTag(action: AnyAction) {
  try {
    const tagId = action.payload
    yield call(Api.deleteTag, tagId)
    yield put(deleteTagActionDone(tagId))
  } catch (e) {
    yield put(deleteTagActionFailed(e as Error))
  }
}

export default function* tagSaga() {
  yield takeLatest(CONSTANTS.GET_TAGS, getTags)
  yield takeLatest(CONSTANTS.GET_TAG, getTag)
  yield takeLatest(CONSTANTS.ADD_TAG, addTag)
  yield takeLatest(CONSTANTS.ADD_MULTIPLE_TAGS, addMultipleTags)
  yield takeLatest(CONSTANTS.DELETE_TAG, deleteTag)
}
