import { AnyAction, Middleware } from 'redux'

function createMessage(action: AnyAction): string {
  const message = Object.prototype.hasOwnProperty.call(action, 'payload')
    ? action.payload.message || 'Aucun détail.' : 'Aucun détail.'

  return `\nSUCCESS : Une action a été déclanchée avec succès : ${action.type}.
Voici les résultats de l'action: ${JSON.stringify(action.payload)}
Détail : ${message}\n`
}

const successMiddleware: Middleware = () => (next) => (action) => {
  if (!/DONE/.test(action.type)) {
    return next(action)
  }
  console.log(createMessage(action))
  return next(action)
}

export default successMiddleware
