/* eslint-disable react/jsx-wrap-multilines */
import { Container, makeStyles, TextField } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { ArrowBackIos } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import QuestionModel from '../../../models/question.model'
import QuestionnaireModel from '../../../models/questionnaire.model'
import TagModel from '../../../models/tag.model'
import QuestionRadioButton from './question-radio-button'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    color: '#000000',
  },
  rootContainer: {
    color: '#717171',
    fontStyle: 'italic',
    '& a': {
      textDecoration: 'none',
    },
  },
  titleIconContainer: {
    display: 'flex',
    padding: '1rem 0',
  },
  titleContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    padding: '.2rem 2rem 0rem 1rem',
    color: '#000000DE',
    minHeight: '50px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  iconContainer: {
    backgroundColor: '#f3e703',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    color: 'rgba(0, 0, 0, 0.7)',
    alignSelf: 'center',
    minHeight: '50px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
    display: 'grid',
    placeContent: 'center',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingBottom: '3rem',
    '& > *': {
      flex: '1 66% 33%',
    },
  },
  leftFormulaire: {
    padding: '1rem',
  },
  rigthFormulaire: {
    // flexGrow: 1,
    // flexBasis: 0,
  },
  textField: {
    paddingBottom: '1.3rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  questionnaireRed: {
    color: red[500],
  },
  questionnaireContent: {
    fontSize: '.9rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
  },
  formRadio: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  radioLabel: {
    background: 'white',
    borderRadius: '5px',
    border: 'rgba(0, 0, 0, 0.23) 1px solid',
    paddingRight: '1rem',
  },
  formInputLabel: {
    background: 'white',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  formSelect: {
    background: 'white',
  },
  formControl: {
    paddingBottom: '1rem',
    color: '#ffffff',
    display: 'flex',
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },
  labelForm: {
    color: 'gray',
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingRight: '2rem',
    textTransform: 'uppercase',
    minWidth: '110px',
    textAlign: 'end',
  },
  possibleAnswers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      gap: '2rem',
      alignItems: 'center',
      '& > div': {
        border: '1px solid',
        borderRadius: '5px',
        padding: '.1rem 1rem',
        background: 'white',
      },
      '& input': {
        color: '#5a5a5a',
      },
    },
  },
  mediaAnswers: {
    display: 'flex',
    gap: '.5rem',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '10rem',
      borderRadius: '10px',
      '& img': {
        width: '9rem',
        height: '9rem',
        objectFit: 'fill',
        borderRadius: '10px',
      },
    },
  },
  mediaQuestion: {
    display: 'grid',
    placeContent: 'center',
    marginBottom: '1rem',
    '& img': {
      borderRadius: '10px',
      height: '15rem',
    },
  },
}))

export interface OwnProps {
  idQuestionnaire: number
  question: QuestionModel | undefined
}

export interface StateProps {
  questionnaire: QuestionnaireModel | undefined
}

export interface DispatchesProps {
  getQuestion: (idQuestion: number) => void
}
export type Props = OwnProps & DispatchesProps & StateProps

const defaultCurrentQuestion: QuestionModel = {
  id: -1,
  visible: false,
  title: '',
  theme: '',
  questionType: '',
  answerType: '',
  explanation: '',
  firstAnswer: '',
  secondAnswer: '',
  thirdAnswer: '',
  forthAnswer: '',
  firstAnswerMedia: '',
  secondAnswerMedia: '',
  thirdAnswerMedia: '',
  forthAnswerMedia: '',
  correctAnswer: 0,
  position: 0,
  questionMedia: '',
  points: 0,
  timing: 0,
}

const QuestionForm = ({ idQuestionnaire, questionnaire, question }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentQuestion, setCurrentQuestion] = useState<QuestionModel>(question || defaultCurrentQuestion)

  useEffect(() => {
    if (typeof questionnaire !== 'undefined') {
      setCurrentQuestion({
        ...defaultCurrentQuestion,
        ...question,
        idQuestionnaire,
      })
    } else {
      setCurrentQuestion({ ...defaultCurrentQuestion, idQuestionnaire })
    }
  }, [idQuestionnaire, question, questionnaire])

  // Handlers

  const isQuestionnaireFinal = () => {
    let isFinal = false
    if (questionnaire) {
      questionnaire.tags.forEach((tag: TagModel) => {
        if (tag.label.toLocaleLowerCase() === 'final') {
          isFinal = true
          return isFinal
        }
        return 0
      })
    }
    return isFinal
  }

  const displayMediaQuestion = () => {
    switch (currentQuestion.questionType) {
      case 'image':
        return (
          <div className={classes.mediaQuestion}>
            <img
              src={`https://licences.i-quiz.fr/dev/questions/${currentQuestion.questionMedia}`}
              alt={currentQuestion.questionMedia}
            />
          </div>
        )
      case 'video':
        return (
          <div className={classes.mediaQuestion}>
            <video width="400" height="400" autoPlay controls muted>
              <source src={`https://licences.i-quiz.fr/dev/questions/${currentQuestion.questionMedia}`} type="video/mp4" />
            </video>
          </div>
        )
      default:
        return <div />
    }
  }

  return (
    <div className={classes.rootContainer}>
      <Link
        to={{
          pathname: `/questionnaire/${questionnaire?.id}`,
          state: { tab: 1, page: 'questionnaire', data: questionnaire?.id },
        }}
      >
        <div className={classes.titleIconContainer}>
          <div className={classes.iconContainer}>
            <ArrowBackIos />
          </div>
          <div className={classes.titleContainer}>
            <p className={classes.questionnaireContent}>
              <span>{currentQuestion.title}</span>
            </p>
          </div>
        </div>
      </Link>
      <Container className={classes.formContainer}>
        <div className={classes.leftFormulaire}>
          <QuestionRadioButton correctAnswer={currentQuestion.correctAnswer} />
          <TextField
            label={t('duration')}
            variant="outlined"
            className={classes.textField}
            color="secondary"
            fullWidth
            value={currentQuestion.timing}
            disabled
          />

          {isQuestionnaireFinal() && (
            <TextField
              label={t('theme')}
              variant="outlined"
              className={classes.textField}
              color="secondary"
              fullWidth
              value={currentQuestion.theme}
              disabled
            />
          )}

          <TextField
            label={t('explication')}
            className={classes.textField}
            multiline
            color="secondary"
            fullWidth
            rows={8}
            value={currentQuestion.explanation}
            variant="outlined"
            disabled
          />
        </div>
        <div className={classes.rigthFormulaire}>
          {currentQuestion.questionType && currentQuestion.questionType !== 'text' && displayMediaQuestion()}
          <div>
            {currentQuestion.answerType === 'text' ? (
              <div className={classes.possibleAnswers}>
                <div style={{ width: '100%' }}>
                  <h3 style={{ width: '20%' }}>{`${t('reponse')} 1`}</h3>
                  <TextField style={{ width: '80%' }} value={currentQuestion.firstAnswer} disabled />
                </div>
                <div style={{ width: '100%' }}>
                  <h3 style={{ width: '20%' }}>{`${t('reponse')} 2`}</h3>
                  <TextField style={{ width: '80%' }} value={currentQuestion.secondAnswer} disabled />
                </div>
                <div style={{ width: '100%' }}>
                  <h3 style={{ width: '20%' }}>{`${t('reponse')} 3`}</h3>
                  <TextField style={{ width: '80%' }} value={currentQuestion.thirdAnswer} disabled />
                </div>
                <div style={{ width: '100%' }}>
                  <h3 style={{ width: '20%' }}>{`${t('reponse')} 4`}</h3>
                  <TextField style={{ width: '80%' }} value={currentQuestion.forthAnswer} disabled />
                </div>
              </div>
            ) : (
              <div className={classes.mediaAnswers}>
                <div>
                  <img
                    src={`https://licences.i-quiz.fr/dev/answers/${currentQuestion.firstAnswerMedia}`}
                    alt={currentQuestion.firstAnswerMedia}
                  />
                  <h3>{`${t('reponse')} 1`}</h3>
                </div>
                <div>
                  <img
                    src={`https://licences.i-quiz.fr/dev/answers/${currentQuestion.secondAnswerMedia}`}
                    alt={currentQuestion.secondAnswerMedia}
                  />
                  <h3>{`${t('reponse')} 2`}</h3>
                </div>
                <div>
                  <img
                    src={`https://licences.i-quiz.fr/dev/answers/${currentQuestion.thirdAnswerMedia}`}
                    alt={currentQuestion.thirdAnswerMedia}
                  />
                  <h3>{`${t('reponse')} 3`}</h3>
                </div>
                <div>
                  <img
                    src={`https://licences.i-quiz.fr/dev/answers/${currentQuestion.forthAnswerMedia}`}
                    alt={currentQuestion.forthAnswerMedia}
                  />
                  <h3>{`${t('reponse')} 4`}</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default QuestionForm
