import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  containerTab: {
    height: '100%',
  },
}))

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: any;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, className,
  } = props
  const classes = useStyles()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
    >
      {value === index && <Box className={classes.containerTab}>{children}</Box>}
    </div>
  )
}
TabPanel.defaultProps = {
  children: undefined,
}
export default TabPanel
