import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import React, { useCallback } from 'react'

import SessionModel from '../../../models/session.model'
import SessionCompleteItem from './session-complete-item'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    paddingTop: '2rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  red: {

    color: red[500],
  },
}))

export interface OwnProps {
  sessions: SessionModel[]
}

export type Props = OwnProps

const SessionsCompletes = ({ sessions }: Props) => {
  const classes = useStyles()

  const displaySessions = useCallback(() => sessions.map((session) => (
    <SessionCompleteItem session={session} key={session.id} />
  )), [sessions])

  return (
    <div className={classes.container}>
      {displaySessions()}
    </div>
  )
}

export default SessionsCompletes
