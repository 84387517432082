// Date Formating
export function getDate(date: Date) {
  const [year, month, day] = date.toString().slice(0, 10).split('-')
  return `${day}/${month}/${year}`
}

function formatDate(date: Date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const monthFormatted = month.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  const year = date.getFullYear()

  return `${day}/${monthFormatted}/${year}`
}

function formatHour(date: Date) {
  const fullDate = date.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })
  const fullDateSplitted = fullDate.split(' ')
  const time = fullDateSplitted[fullDateSplitted.length - 1]

  const hour = time.substring(0, 2)
  const min = time.substring(3, 5)
  return `${hour}H${min}`
}

export function getDateFormatted(date: Date | undefined | null) {
  if (typeof date === 'undefined' || date === null) {
    return '__ / __ / ____'
  }
  if (typeof date === 'string') {
    return formatDate(new Date(date))
  }
  return formatDate(date)
}

export function getTimeFormatted(date: Date | undefined) {
  if (typeof date === 'undefined') {
    return '__ : __'
  }
  if (typeof date === 'string') {
    return formatHour(new Date(date))
  }
  return formatHour(date)
}

export function getOldDate(): Date {
  return new Date('1970-01-01T00:00:00.000Z')
}

export function getCurrentDateToLastMinute(): Date {
  const currentDate = new Date()
  currentDate.setUTCDate(currentDate.getDate())
  currentDate.setUTCHours(23, 59, 59, 999)
  currentDate.setUTCFullYear(currentDate.getFullYear())
  currentDate.setUTCMonth(currentDate.getMonth())
  return currentDate
}
