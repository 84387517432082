import { AnyAction, Middleware } from 'redux'

import { createError } from '../actions/error.actions'

function createMessage(action: AnyAction): string {
  const message = Object.prototype.hasOwnProperty.call(action, 'payload')
    ? action.payload.message || 'No response unavailable' : ''

  return `ERROR : Une erreur a été détecté durant l'aciton : ${action.type}. \n
   Détail : ${message} \n
   Voici les paramètre de l'action: ${action.payload}`
}

const errorMiddleware: Middleware = (store) => (next) => (action) => {
  if (!/FAILED/.test(action.type)) {
    return next(action)
  }
  const message = createMessage(action)
  console.error(message)
  store.dispatch(createError(new Error(message)))

  return next(action)
}

export default errorMiddleware
