import { AnyAction } from 'redux'

import IHistoryCalculations from '../../interfaces/IHistoryCalculations'
import SessionModel from '../../models/session.model'
import * as CONSTANTS from '../actions/constants'

export type SessionState = {
  sessions: SessionModel[];
  historyCalculations: IHistoryCalculations | undefined;
  sessionPaginationTotalItems: number | undefined;
}

const initialState: SessionState = {
  sessions: [],
  historyCalculations: undefined,
  sessionPaginationTotalItems: undefined,
}

export default function tagReducer(state = initialState, action: AnyAction): SessionState {
  switch (action.type) {
    case CONSTANTS.GET_SESSIONS_BY_ID_CLIENT_DONE:
      return {
        ...state,
        sessions: action.payload || [],
      }
    case CONSTANTS.GET_SESSIONS_PAGINATION_DONE:
      return {
        ...state,
        sessions: action.payload.sessions || [],
        sessionPaginationTotalItems: action.payload.total,
      }
    case CONSTANTS.GET_SESSIONS_HISTORY_DONE:
      return {
        ...state,
        historyCalculations: action.payload,
      }
    default:
      return state
  }
}
