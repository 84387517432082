/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux'
import {
  all,
  call, CallEffect, put, takeLatest, takeEvery,
} from 'redux-saga/effects'

import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import * as CONSTANTS from '../actions/constants'
import {
  addQuestionActionDone,
  addQuestionActionFailed, deleteQuestionActionDone,
  deleteQuestionActionFailed, getQuestionActionDone,
  getQuestionActionFailed, getQuestionsActionDone,
  getQuestionsActionFailed, updateMultipleQuestionsActionDone,
  updateMultipleQuestionsActionFailed, updateQuestionActionDone,
  updateQuestionActionFailed, uploadAnswerMediaFailed, uploadQuestionMediaFailed,
} from '../actions/question.actions'
import Api from '../apis/api-factory'

function* getQuestions(action: AnyAction) {
  try {
    const idQuestionnaire = action.payload
    const questions: QuestionModel[] | [] = yield call(Api.getQuestions, idQuestionnaire)
    for (let i = 0; i < questions.length; i += 1) {
      questions[i].idQuestionnaire = idQuestionnaire
    }
    yield put(getQuestionsActionDone(questions))
  } catch (e) {
    yield put(getQuestionsActionFailed(e as Error))
  }
}

function* getQuestion(action: AnyAction) {
  try {
    const questionId = action.payload
    const question: QuestionModel = yield call(Api.getQuestion, questionId)

    yield put(getQuestionActionDone(question))
  } catch (e) {
    yield put(getQuestionActionFailed(e as Error))
  }
}

function* addQuestion(action: AnyAction) {
  try {
    const question = action.payload
    const questionSaved: QuestionModel = yield call(Api.addQuestion, question)
    const lastModifiedAt = new Date()
    yield call(Api.updateQuestionnaire, { id: question.idQuestionnaire, lastModifiedAt } as QuestionnaireModel)
    yield put(addQuestionActionDone(questionSaved))
  } catch (e) {
    yield put(addQuestionActionFailed(e as Error))
  }
}

function* updateQuestion(action: AnyAction) {
  try {
    const question = action.payload
    const lastModifiedAt = new Date()
    const resultingQuestion: QuestionModel = yield call(Api.updateQuestion, { ...question, lastModifiedAt })
    yield call(Api.updateQuestionnaire, { id: question.idQuestionnaire, lastModifiedAt } as QuestionnaireModel)
    yield put(updateQuestionActionDone(resultingQuestion))
  } catch (e) {
    yield put(updateQuestionActionFailed(e as Error))
  }
}

function* updateMultipleQuestions(action: AnyAction) {
  try {
    const questions: QuestionModel[] = action.payload
    const questionsCalls: CallEffect<any>[] = []
    questions.forEach((o) => questionsCalls.push(call(Api.addQuestion, o)))
    const questionsSaved: QuestionModel[] = yield all(questionsCalls)
    yield put(updateMultipleQuestionsActionDone(questionsSaved))
  } catch (e) {
    yield put(updateMultipleQuestionsActionFailed(e as Error))
  }
}

function* deleteQuestion(action: AnyAction) {
  try {
    const questionId = action.payload
    const deleteQuestionId: number = yield call(Api.deleteQuestion, questionId)
    yield put(deleteQuestionActionDone(deleteQuestionId))
  } catch (e) {
    yield put(deleteQuestionActionFailed(e as Error))
  }
}

// upload question to ftp
function* uploadQuestionMedia(action: AnyAction) {
  try {
    const file = action.payload
    yield call(Api.uploadQuestionMedia, file)
  } catch (e) {
    yield put(uploadQuestionMediaFailed(e as Error))
    console.log('PROBLEM : question not uploaded from saga', e)
  }
}

// upload answer to ftp
function* uploadAnswerMedia(action: AnyAction) {
  try {
    const file = action.payload
    yield call(Api.uploadAnswersMedia, file)
  } catch (e) {
    yield put(uploadAnswerMediaFailed(e as Error))
    console.log('PROBLEM : answer not uploaded from saga', e)
  }
}

export default function* questionsSaga() {
  yield takeLatest(CONSTANTS.GET_QUESTIONS, getQuestions)
  yield takeLatest(CONSTANTS.GET_QUESTION, getQuestion)
  yield takeLatest(CONSTANTS.ADD_QUESTION, addQuestion)
  yield takeLatest(CONSTANTS.UPDATE_QUESTION, updateQuestion)
  yield takeEvery(CONSTANTS.UPDATE_MULTIPLE_QUESTIONS, updateMultipleQuestions)
  yield takeLatest(CONSTANTS.DELETE_QUESTION, deleteQuestion)
  yield takeLatest(CONSTANTS.UPLOAD_QUESTION_MEDIA, uploadQuestionMedia)
  yield takeLatest(CONSTANTS.UPLOAD_ANSWER_MEDIA, uploadAnswerMedia)
}
