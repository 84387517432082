import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { HashRouter, Route } from 'react-router-dom'

import App from './App'
import './i18n'
import Dashboard from './Dashboard'
import createRootStore from './redux/store/root.store'

// import reportWebVitals from './reportWebVitals';
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#f2e900',
    },
    secondary: {
      main: '#e5c219',
    },
  },
})

const store = createRootStore()

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <HashRouter>
        <Route path="/" exact>
          <App />
        </Route>
        <Route
          path={[
            '/home',
            '/questionnaire',
            '/questionnaires',
            '/questionnaire/:id',
            '/questionnaire/:id/question',
            '/questionnaire/:id/Editquestion/:id',
            '/themes',
            '/client',
            '/clients',
            '/client/:id',
            '/EditClient/:id',
            '/sessions',
          ]}
        >
          <Dashboard />
        </Route>
      </HashRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
