import { AnyAction } from 'redux'

import ClientModel from '../../models/client.model'
import * as CONSTANTS from '../actions/constants'

export type ClientsState = {
  clients: ClientModel[]
  emails: string[]
  selectedClient: ClientModel | undefined
  error: string | undefined
  currentUser?: ClientModel
  loading: boolean
}

export type ClientState = ClientModel

const initialState: ClientsState = {
  clients: [],
  emails: [],
  selectedClient: undefined,
  error: undefined,
  currentUser: undefined,
  loading: false,
}

export default function clientReducer(state = initialState, action: AnyAction): ClientsState {
  switch (action.type) {
    case CONSTANTS.LOADING_START:
      return {
        ...state,
        loading: true,
      }
    case CONSTANTS.LOADING_DONE:
      return {
        ...state,
        loading: false,
      }
    case CONSTANTS.LOGOUT_CLIENT:
      return {
        ...initialState,
      }
    case CONSTANTS.LOGIN_CLIENT_DONE:
      return {
        ...state,
        currentUser: action.payload,
        error: undefined,
      }
    case CONSTANTS.LOGIN_CLIENT_FAILED:
      return {
        ...state,
        currentUser: undefined,
        error: action.payload.message,
      }
    case CONSTANTS.GET_CLIENTS_DONE:
      return {
        ...state,
        clients: action.payload || [],
        emails: action.payload.map((client: ClientModel) => client.mail) || [],
      }
    case CONSTANTS.GET_CLIENT_DONE:
      return {
        ...state,
        selectedClient: action.payload,
      }
    case CONSTANTS.ADD_CLIENT_DONE:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        selectedClient: action.payload,
      }
    case CONSTANTS.DELETE_CLIENT_DONE:
      return {
        ...state,
        clients: state.clients.filter((client: ClientModel) => client.id !== action.payload),
      }

    case CONSTANTS.UPDATE_CLIENT_DONE:
      return {
        ...state,
        clients: state.clients.map((client) => {
          if (client.id === action.payload.id) {
            return action.payload
          }
          return client
        }),
        emails: state.emails.map((email) => {
          if (email === action.payload.mail) {
            return action.payload.mail
          }
          return email
        }),
      }
    case CONSTANTS.UPDATE_CLIENT_FAILED:
      return {
        ...state,
        error: action.payload.message,
      }
    default:
      return state
  }
}
