import { createAction } from 'redux-actions'

import Theme from '../../models/theme.model'
import * as CONSTANTS from './constants'

export const getThemesAction = createAction<number>(CONSTANTS.GET_THEMES)
export const getThemesActionDone = createAction<Theme[]>(CONSTANTS.GET_THEMES_DONE)
export const getThemesActionFailed = createAction<Error>(CONSTANTS.GET_THEMES_FAILED)

export const getThemeAction = createAction<number>(CONSTANTS.GET_THEME)
export const getThemeActionDone = createAction<Theme>(CONSTANTS.GET_THEME_DONE)
export const getThemeActionFailed = createAction<Error>(CONSTANTS.GET_THEME_FAILED)

export const addThemeAction = createAction<Theme>(CONSTANTS.ADD_THEME)
export const addThemeActionDone = createAction<Theme>(CONSTANTS.ADD_THEME_DONE)
export const addThemeActionFailed = createAction<Error>(CONSTANTS.ADD_THEME_FAILED)

export const updateThemeAction = createAction<Theme>(CONSTANTS.UPDATE_THEME)
export const updateThemeActionDone = createAction<Theme>(CONSTANTS.UPDATE_THEME_DONE)
export const updateThemeActionFailed = createAction<Error>(CONSTANTS.UPDATE_THEME_FAILED)

export const deleteThemeAction = createAction<number>(CONSTANTS.DELETE_THEME)
export const deleteThemeActionDone = createAction<number>(CONSTANTS.DELETE_THEME_DONE)
export const deleteThemeActionFailed = createAction<Error>(CONSTANTS.DELETE_THEME_FAILED)
