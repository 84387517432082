import DateFnsUtils from '@date-io/date-fns'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  Typography,
  Switch,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import Pagination from '@material-ui/lab/Pagination'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IHistoryCalculations from '../../interfaces/IHistoryCalculations'
import ISessionPaginationRequest from '../../interfaces/ISessionPaginationRequest'
import SessionModel from '../../models/session.model'
import { getDateFormatted, getOldDate, getCurrentDateToLastMinute } from '../../utils/date-format'
import SessionsCompletes from './session-complete'
import SessionsSimplifiee from './session-simple'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1.5rem',
  },
  title: {
    fontSize: '1.5rem',
    color: 'black',
    fontWeight: 'bold',
  },
  switchVersionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  datePicker: {
    marginTop: 'unset',
    marginBottom: 'unset',
    paddingRight: '3rem',
  },
  titleAndFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateAndKeyKPIContainer: {
    paddingTop: '2rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  red: {
    color: red[500],
  },
  detailsAndFacturationFilterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    minWidth: 120,
    color: '#ffffff',
    display: 'flex',
    '& > label': {
      fontWeight: 900,
    },
    '& > div': {
      height: '3.4rem',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '26px',
    },
    '& .MuiFormLabel-root': {
      fontSize: 'inherit',
    },
  },
  session_pagination_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1.5em',
    '& > nav': {
      width: 'fit-content',
    },
  },
}))

export interface StateProps {
  sessions: SessionModel[]
  sessionsTotal: number | undefined
  historyCalculations: IHistoryCalculations | undefined
}

export interface DispatchesProps {
  getSessionsPagination: (sessionPaginationRequest: ISessionPaginationRequest) => void
  getSessionsHistory: (clientId: number, startDate: string, endDate: string) => void
}

export interface OwnProps {
  idClient: number
  nameClient: string
}

export type Props = StateProps & DispatchesProps & OwnProps

const Sessions = ({
  nameClient,
  sessions,
  sessionsTotal,
  historyCalculations,
  idClient,
  getSessionsPagination,
  getSessionsHistory,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(getCurrentDateToLastMinute())
  const [selectedDisplayStartDate, setSelectedDisplayStartDate] = useState<Date | null>(null)
  const [selectedDisplayEndDate, setSelectedDisplayEndDate] = useState<Date | null>(new Date())
  const [isCompleteVersion, setCompleteVersion] = useState<boolean>(false)
  const [isFacturableSessions, setIsFacturableSessions] = useState<boolean>(false)

  const [paginationLimit, setPaginationLimit] = useState<number>(10)
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(1)
  const [paginationPagesCount, setPaginationPagesCount] = useState<number | undefined>()

  useEffect(() => {
    if (idClient && isCompleteVersion && paginationLimit >= 0 && paginationCurrentPage > 0) {
      const startDate = selectedStartDate === null ? getOldDate().toISOString() : selectedStartDate?.toISOString()
      const endDate: string = selectedEndDate?.toISOString() || ''
      getSessionsPagination({
        limit: paginationLimit,
        page: paginationCurrentPage,
        clientId: idClient,
        startDate,
        endDate,
        isFacturable: isFacturableSessions,
      })
    }
  }, [
    paginationLimit,
    paginationCurrentPage,
    idClient,
    selectedStartDate,
    selectedEndDate,
    getSessionsHistory,
    getSessionsPagination,
    isCompleteVersion,
    isFacturableSessions,
  ])

  useEffect(() => {
    if (sessionsTotal) {
      setPaginationPagesCount(Math.ceil(sessionsTotal / paginationLimit))
    }
  }, [sessionsTotal, paginationLimit])

  useEffect(() => {
    if (idClient && !isCompleteVersion) {
      const startDate = selectedStartDate === null ? getOldDate().toISOString() : selectedStartDate?.toISOString()
      const endDate: string = selectedEndDate?.toISOString() || ''
      getSessionsHistory(idClient, startDate, endDate)
    }
  }, [idClient, selectedEndDate, getSessionsHistory, isCompleteVersion, selectedStartDate])

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const propsDate = new Date(date as Date)
      propsDate.setUTCHours(0, 0, 0, 0)
      propsDate.setUTCDate(date.getDate())

      setSelectedStartDate(propsDate)
      setSelectedDisplayStartDate(date)
      setPaginationCurrentPage(1)
    }
  }
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const propsDate = new Date(date as Date)
      propsDate.setUTCHours(23, 59, 59, 999)
      propsDate.setUTCDate(date.getDate())
      setSelectedEndDate(propsDate)
      setSelectedDisplayEndDate(date)
      setPaginationCurrentPage(1)
    }
  }
  const handleChangeSwitchVersion = useCallback(() => {
    setCompleteVersion(!isCompleteVersion)
  }, [isCompleteVersion])

  const handleChangeIsFacturableSessions = useCallback(() => {
    setIsFacturableSessions((previous) => !previous)
    setPaginationCurrentPage(1)
  }, [])

  const CustomGreenAndBlackSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 36,
        height: 22,
        padding: 0,
        display: 'flex',
        marginInline: '.5rem',
      },
      switchBase: {
        padding: 2,
        color: '#393939',
        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: '#82ff38', // theme.palette.primary.main,
            borderColor: '#393939', // theme.palette.primary.main,
            borderWidth: 3,
          },
        },
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: 'none',
        margin: 2,
        backgroundColor: '#393939',
      },
      track: {
        border: '3px solid #393939',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
    })
  )(Switch)

  const handleOnChangePaginationCurrentPage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPaginationCurrentPage(page)
  }

  const handleChangePaginationLimit = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const currentPaginationLimit = event.target.value as number
    setPaginationLimit(currentPaginationLimit)
    setPaginationCurrentPage(1)
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.titleAndFilterContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{nameClient.toUpperCase()}</Typography>
        </div>
        <div className={classes.filterContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-start-inline"
              label={t('filtreDateDebut')}
              value={selectedDisplayStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.datePicker}
              maxDate={new Date(selectedEndDate as Date).toLocaleDateString('en')}
              showTodayButton
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-end-inline"
              label={t('filtreDateFin')}
              value={selectedDisplayEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.datePicker}
              minDate={new Date(selectedStartDate as Date).toLocaleDateString('en')}
              showTodayButton
            />
          </MuiPickersUtilsProvider>
          <div className={classes.switchVersionContainer}>
            <span>{t('versionSimplifiee')}</span>
            <CustomGreenAndBlackSwitch
              checked={isCompleteVersion}
              onChange={handleChangeSwitchVersion}
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <span>{t('versionComplete')}</span>
          </div>
        </div>
      </div>
      <div className={classes.detailsAndFacturationFilterContainer}>
        <div className={classes.dateAndKeyKPIContainer}>
          <span>
            {getDateFormatted(selectedDisplayStartDate)}
            {' - '}
            {getDateFormatted(selectedDisplayEndDate)}
          </span>
        </div>
        {isCompleteVersion && (
          <div className={classes.switchVersionContainer}>
            <span>{t('toutesSessions')}</span>
            <CustomGreenAndBlackSwitch
              checked={isFacturableSessions}
              onChange={handleChangeIsFacturableSessions}
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <span>{t('sessionFacturables')}</span>
          </div>
        )}
      </div>
      {isCompleteVersion ? (
        <>
          <SessionsCompletes sessions={sessions} />
          <div className={classes.session_pagination_container}>
            <Pagination
              page={paginationCurrentPage}
              count={paginationPagesCount}
              onChange={handleOnChangePaginationCurrentPage}
              showFirstButton
              showLastButton
              color="secondary"
              shape="rounded"
            />

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Items {t('in')} page</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paginationLimit}
                onChange={handleChangePaginationLimit}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      ) : (
        <SessionsSimplifiee historyCalculations={historyCalculations} />
      )}
    </div>
  )
}

export default Sessions
