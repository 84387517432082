/* eslint-disable no-console */
import { Button, Container, Grid, Paper, Snackbar, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import QuestionnairesModel from '../../models/questionnaire.model'
import ThemeModel from '../../models/theme.model'
import { RootStore } from '../../redux/store/root.store'
import isNotEmpty from '../../utils/string-utils'
import Alert from '../alert'
import DialogDelete from '../dialog-delete'
import ThemeItem from './theme-item'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '1.5rem',
    maxWidth: 'unset',
    height: '100%',
  },
  button: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#e5c219',
    color: '#FFFFFF',
    fontWeight: 'bold',
    minHeight: '40px',
    minWidth: '8rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: '#ffffff',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    padding: '2rem',
    background: 'darkgrey',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  themeNameTextFieldContainer: {
    flexGrow: 6,
    padding: '3rem 2rem',
    display: 'flex',
    gap: '.5rem',
  },
  textField: {
    minWidth: '25rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      fontStyle: 'italic',
      fontSize: '.85rem',
    },
    '& input': {
      padding: '15.5px 14px',
      height: '11px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(24px, -4.5px) scale(0.75) !important',
    },
  },
}))

export interface StateProps {
  themes: ThemeModel[]
  questionnaires: QuestionnairesModel[]
}

export interface DispatchesProps {
  getThemes: (idClient: number) => void
  addTheme: (theme: ThemeModel) => void
  deleteTheme: (theme: ThemeModel) => void
}

export type Props = StateProps & DispatchesProps
export type Color = 'success' | 'info' | 'warning' | 'error'
export type Validation = 'add' | 'delete'

const Themes = ({ themes, getThemes, addTheme, deleteTheme }: Props) => {
  // Translation
  const { t } = useTranslation()

  const [themeToDelete, setThemeToDelete] = useState<ThemeModel>()
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false)
  const [openValidationSnackbar, setOpenValidationSnackbar] = useState<boolean>(false)
  const [newThemeTitle, setNewThemeTitle] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState<string>('')
  const [snackSeverity, setSnackSeverity] = useState<Color>('success')
  const [validation, setValidation] = useState<Validation>('add')
  const classes = useStyles()

  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)
  const allQuestionnaires = useSelector((state: RootStore) => state.questionnaires.questionnaires)

  const handleDeleteTheme = useCallback(
    (id: number) => {
      const theme = themes.filter((themeTemp) => themeTemp.id === id)[0]
      if (theme.inUse) {
        setValidation('delete')
        setSnackSeverity('warning')
        setOpenValidationSnackbar(true)
      } else {
        setThemeToDelete(theme)
        setOpenDialog(true)
      }
    },
    [themes]
  )

  const displayThemes = useCallback(
    () =>
      themes.map((theme) => {
        if (theme.visible) {
          if (currentClient?.isAdmin) {
            if (theme.idClient === -1 || (theme.idClient !== -1 && theme.inProd)) {
              return <ThemeItem theme={theme} isAdmin deleteTheme={handleDeleteTheme} key={theme.id} />
            }
          } else {
            return <ThemeItem theme={theme} isAdmin={false} deleteTheme={handleDeleteTheme} key={theme.id} />
          }
        }
        return null
      }),
    [currentClient?.isAdmin, handleDeleteTheme, themes]
  )

  const confirmeDeleteTheme = useCallback(() => {
    if (typeof themeToDelete !== 'undefined' && typeof themeToDelete.id !== 'undefined') {
      setValidation('delete')
      deleteTheme({ ...themeToDelete, visible: false })
      setSnackSeverity('success')
      setOpenValidationSnackbar(true)
      setOpenDialog(false)
    }
  }, [themeToDelete, deleteTheme])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationSnackbar(false)
  }

  const getAlertContent = useCallback(() => {
    if (snackSeverity === 'success' && validation === 'add') {
      if (isNotEmpty(newThemeTitle)) {
        return (
          <>
            {`${t('leTheme')} `}
            <strong>
              <em>{newThemeTitle}</em>
            </strong>
            {` ${t('addQuestionnaire')}`}
          </>
        )
      }
    }
    if (snackSeverity === 'success' && validation === 'delete') {
      return (
        <>
          {`${t('leTheme')} `}
          <strong>
            <em>{themeToDelete?.label}</em>
          </strong>
          {` ${t('suppressionsuccess')} `}
        </>
      )
    }
    if (snackSeverity === 'warning' && validation === 'delete') {
      return (
        <>
          {`${t('leTheme')} `}
          <strong>
            <em>{themeToDelete?.label}</em>
          </strong>
          {` ${t('themeReference')} `}
        </>
      )
    }
    return null
  }, [newThemeTitle, snackSeverity, t, themeToDelete?.label, validation])

  const handleChangeThemeTitle = useCallback((e) => {
    const newTitle = e.target.value as string
    setNewThemeTitle(newTitle)
  }, [])

  const themeAlreayExist = useCallback(() => {
    const filterTemp = themes.filter(
      (theme) => theme.label.toLocaleLowerCase() === newThemeTitle.toLocaleLowerCase() && theme.visible === true
    )
    return filterTemp.length !== 0
  }, [newThemeTitle, themes])

  const handleOnClickAddTheme = useCallback(
    (e) => {
      e.preventDefault()
      if (themeAlreayExist()) {
        setIsError(true)
        setHelperText(`${t('leTheme')} "${newThemeTitle.toLocaleLowerCase()}" ${t('existeDeja')}`)
      } else if (!isNotEmpty(newThemeTitle)) {
        setIsError(true)
        setHelperText(t('champVide'))
      } else {
        setValidation('add')
        addTheme({ label: newThemeTitle, visible: true, idClient: currentClient?.isAdmin ? -1 : currentClient?.id })
        setSnackSeverity('success')
        setOpenValidationSnackbar(true)
      }
    },
    [addTheme, currentClient?.id, currentClient?.isAdmin, newThemeTitle, t, themeAlreayExist]
  )

  const handleOnFocus = useCallback(() => {
    setHelperText('')
    setIsError(false)
  }, [])

  useEffect(() => {
    if (currentClient && currentClient?.id) {
      getThemes(currentClient?.isAdmin ? -1 : currentClient?.id)
    }
  }, [currentClient, getThemes, allQuestionnaires])

  return (
    <Container className={classes.rootContainer}>
      <DialogDelete
        title={themeToDelete?.label || ''}
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
        confirmeDelete={confirmeDeleteTheme}
        validationText={t('themeDeleteValidation')}
        questiontitle={`${t('themeDeleteTitle')} `}
      />
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.themeNameTextFieldContainer}>
            <TextField
              error={isError}
              helperText={helperText}
              label={t('nomTheme')}
              variant="outlined"
              className={classes.textField}
              color="secondary"
              onChange={handleChangeThemeTitle}
              value={newThemeTitle}
              onFocus={handleOnFocus}
            />
            <Button variant="contained" color="secondary" className={classes.button} onClick={handleOnClickAddTheme}>
              {t('create')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.buttonContainer}>{displayThemes()}</Paper>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Snackbar open={openValidationSnackbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {getAlertContent()}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Themes
