import { createAction } from 'redux-actions'

import IHistoryCalculations from '../../interfaces/IHistoryCalculations'
import IHistoryCalculationsRequest from '../../interfaces/IHistoryCalculationsRequest'
import ISessionPaginationRequest from '../../interfaces/ISessionPaginationRequest'
import ISessionPaginationResults from '../../interfaces/ISessionPaginationResults'
import SessionModel from '../../models/session.model'
import * as CONSTANTS from './constants'

export const getSessionsByIdClientAction = createAction<number>(CONSTANTS.GET_SESSIONS_BY_ID_CLIENT)
export const getSessionsByIdClientActionDone = createAction<SessionModel[]>(CONSTANTS.GET_SESSIONS_BY_ID_CLIENT_DONE)
export const getSessionsByIdClientActionFailed = createAction<Error>(CONSTANTS.GET_SESSIONS_BY_ID_CLIENT_FAILED)

export const getSessionsPaginationAction = createAction<ISessionPaginationRequest>(
  CONSTANTS.GET_SESSIONS_PAGINATION,
)
export const getSessionsPaginationActionDone = createAction<ISessionPaginationResults>(
  CONSTANTS.GET_SESSIONS_PAGINATION_DONE,
)
export const getSessionsPaginationActionFailed = createAction<Error>(
  CONSTANTS.GET_SESSIONS_PAGINATION_FAILED,
)

export const getSessionsHistoryAction = createAction<IHistoryCalculationsRequest>(CONSTANTS.GET_SESSIONS_HISTORY)
export const getSessionsHistoryActionDone = createAction<IHistoryCalculations>(CONSTANTS.GET_SESSIONS_HISTORY_DONE)
export const getSessionsHistoryActionFailed = createAction<Error>(CONSTANTS.GET_SESSIONS_HISTORY_FAILED)
