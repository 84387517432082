import { Button, createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Update, Block, AddCircle } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    padding: '2rem',
  },
  button: {
    margin: '1rem',
    color: 'white',
    fontWeight: 'bold',
  },
}))
const theme = createTheme({
  palette: {
    primary: red,
  },
})

export interface OwnProps {
  currentyEditing: boolean
  handleOnClickQuestionnaireAddAndUpdate: () => void
  showActionButtonAddOrUpdate: boolean
}

export type Props = OwnProps

const ActionButtonQuestion = ({
  currentyEditing,
  handleOnClickQuestionnaireAddAndUpdate,
  showActionButtonAddOrUpdate,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [isCurrentyEditing, setIsCurrentyEditing] = useState<boolean>(currentyEditing)

  useEffect(() => {
    setIsCurrentyEditing(currentyEditing)
  }, [currentyEditing])

  const handleOnClickUpdate = useCallback(
    (e) => {
      e.preventDefault()
      handleOnClickQuestionnaireAddAndUpdate()
    },
    [handleOnClickQuestionnaireAddAndUpdate]
  )
  return (
    <div className={classes.buttonContainer}>
      {showActionButtonAddOrUpdate && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={currentyEditing ? <Update /> : <AddCircle />}
          onClick={handleOnClickUpdate}
        >
          {isCurrentyEditing ? t('mettreAjour') : t('create')}
        </Button>
      )}
      <ThemeProvider theme={theme}>
        <Link
          to={{
            pathname: '/questionnaires',
            state: { tab: 1, page: 'questionnaires' },
          }}
        >
          <Button variant="contained" className={classes.button} color="primary" startIcon={<Block />}>
            {t('annuler')}
          </Button>
        </Link>
      </ThemeProvider>
    </div>
  )
}

export default ActionButtonQuestion
