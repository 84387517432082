import HttpApi from './api'

const apiUrl = process.env.REACT_APP_ADMIN_API_URL || ''

class HttpApiFactory {
  private apiClientUrl: string

  constructor(apiClientUrl: string) {
    this.apiClientUrl = apiClientUrl
  }

  create() {
    return new HttpApi(this.apiClientUrl)
  }
}
const api = new HttpApiFactory(apiUrl!).create()

export default api
