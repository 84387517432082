import * as localStorage from 'local-storage'

import { AnyObject } from '../../interfaces/any-object'

enum HttpMethod {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
}

export default class HttpClient {
  static post<R = any>(url: string, body?: string | object, requireToken?: boolean): Promise<R> {
    return HttpClient.request(url, HttpMethod.POST, body, requireToken)
  }

  static postArray(
    url: string,
    body?: string | object,
    headers?: string[],
    requireToken?: boolean
  ): Promise<ArrayBuffer | void> {
    return HttpClient.requestArray(url, HttpMethod.POST, body, headers, requireToken)
  }

  static get<R = any>(url: string, requireToken?: boolean): Promise<R> {
    return HttpClient.request(url, HttpMethod.GET, undefined, requireToken)
  }

  static delete<R = any>(url: string, body?: string | object, requireToken?: boolean): Promise<R> {
    return HttpClient.request(url, HttpMethod.DELETE, body, requireToken)
  }

  static put<R = any>(url: string, body?: string | object, requireToken?: boolean): Promise<R> {
    return HttpClient.request(url, HttpMethod.PUT, body, requireToken)
  }

  static patch<R = any>(url: string, body?: string | object, requireToken?: boolean): Promise<R> {
    return HttpClient.request(url, HttpMethod.PATCH, body, requireToken)
  }

  private static getFinalBody(body?: string | object): string | undefined {
    if (typeof body !== 'undefined') {
      if (typeof body === 'string') {
        return body
      }
      return JSON.stringify(body)
    }
    return undefined
  }

  private static request<R>(
    url: string,
    method: HttpMethod,
    body?: string | object,
    requireToken?: boolean
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {
            const contentType = xhr.getResponseHeader('Content-Type')

            if (contentType !== null && /application\/json/i.test(contentType) && xhr.status !== 204) {
              resolve(JSON.parse(xhr.response))
            }
          } else {
            reject(JSON.parse(xhr.response))
          }
        }
      }

      xhr.open(method, url, true)

      const finalBody = HttpClient.getFinalBody(body)

      xhr.setRequestHeader('Accept', 'application/json')

      if (requireToken) {
        xhr.setRequestHeader('quiz-access-token', localStorage.get<string>('quiz.admin.jwt'))
      }

      if (typeof finalBody !== 'undefined') {
        xhr.setRequestHeader('Content-Type', 'application/json')
      }

      xhr.send(finalBody)
    })
  }

  private static requestArray(
    url: string,
    method: HttpMethod,
    body?: string | object,
    headers?: string[],
    requireToken?: boolean
  ): Promise<ArrayBuffer | void> {
    const defaultHeaders: AnyObject = {}

    const finalHeaders = headers
      ? headers.reduce((acc, header) => {
          const [key, value] = header.split(':')

          acc[key] = value

          return acc
        }, defaultHeaders)
      : null

    // eslint-disable-next-line no-undef
    let options: RequestInit = {
      method,
      headers: { 'Content-Type': 'application/json', ...finalHeaders },
    }

    if (requireToken) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          'quiz-access-token': localStorage.get<string>('quiz.admin.jwt'),
        },
      }
    }

    if (method !== HttpMethod.GET) {
      options.body = JSON.stringify(body)
    }

    return fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP ERROR. STATUS ${response.status} ${response.statusText}`)
        }
        return response
      })
      .then((response) => response.arrayBuffer())
      .catch((error: Error) => {
        console.error(error)
      })
  }
}
