import { Button, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ClientModel from '../../models/client.model'
import DialogDelete from '../dialog-delete'
import ClientItem from './client-item'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '1.5rem',
    maxWidth: 'unset',
    height: '100%',
    fontSize: '.85rem',
  },
  container: {
    color: 'red',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#000',
    fontWeight: 'bold',
    padding: '4px 10px',
    borderRadius: '9px',
    '& svg': {
      transform: 'scaleX(-1)',
      fontSize: '2rem !important',
    },
  },
}))

export interface StateProps {
  clients: ClientModel[]
}

export interface DispatchesProps {
  getClients: () => void
  deleteClient: (idClient: number) => void
  UpdateClient: (client: ClientModel) => void
}

export type Props = StateProps & DispatchesProps

const Clients = ({ clients, getClients, deleteClient, UpdateClient }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const [currentClients, setCurrentClients] = useState(clients)
  const [isOpenDialogDeleteClient, setOpenDialogDeleteClient] = useState(false)
  const [titleDeleteClient, setTitleDeleteClient] = useState<string>('')
  const [idClientToDelete, setIdClientToDelete] = useState<number>()

  const handleClickDeleteClient = useCallback(
    (idClient: number) => {
      setIdClientToDelete(idClient)
      const clientToDelete = clients.filter((client) => client.id === idClient)
      if (typeof clientToDelete[0].raisonSocial !== 'undefined') {
        setTitleDeleteClient(clientToDelete[0].raisonSocial)
      }
      setOpenDialogDeleteClient(true)
    },
    [clients]
  )

  const confirmeDeleteClient = useCallback(() => {
    if (typeof idClientToDelete !== 'undefined') {
      deleteClient(idClientToDelete)
      const newClients = currentClients.filter((client: ClientModel) => client.id !== idClientToDelete)
      setCurrentClients(newClients)
      setOpenDialogDeleteClient(false)
    }
  }, [currentClients, deleteClient, idClientToDelete])

  // TODO change sessions={client.sessions.length} by sessions={client.countSessions}
  const displayClients = useCallback(() => {
    if (typeof currentClients !== 'undefined') {
      return currentClients.map((client) => (
        <ClientItem
          key={client.id}
          client={client}
          // eslint-disable-next-line no-irregular-whitespace
          sessions={client.countSessions || 0}
          handleDelete={handleClickDeleteClient}
          UpdateClient={UpdateClient}
        />
      ))
    }
    return null
  }, [UpdateClient, currentClients, handleClickDeleteClient])

  useEffect(() => {
    getClients()
  }, [getClients])
  useEffect(() => {
    setCurrentClients(clients)
  }, [clients])

  return (
    <Container className={classes.rootContainer}>
      <DialogDelete
        title={titleDeleteClient}
        isOpenDialog={isOpenDialogDeleteClient}
        setOpenDialog={setOpenDialogDeleteClient}
        confirmeDelete={confirmeDeleteClient}
        validationText={t('clientValidationText')}
        questiontitle={t('clientDialogDelete')}
      />
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Link
            to={{
              pathname: '/client',
              state: {
                tab: 1,
                page: 'client',
              },
            }}
          >
            <Button color="secondary" variant="contained" className={classes.button} startIcon={<PersonAddOutlinedIcon />}>
              {t('newClient')}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <div className={classes.container}>{displayClients()}</div>
    </Container>
  )
}

export default Clients
