/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Container, Grid, makeStyles, Snackbar } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import ClientModel from '../../models/client.model'
import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import TagModel from '../../models/tag.model'
import ThemeModel from '../../models/theme.model'
import { RootStore } from '../../redux/store/root.store'
import isNotEmpty from '../../utils/string-utils'
import Alert from '../alert'
import DialogDelete from '../dialog-delete'
import QuestionItem from './question-item'
import QuestionnaireActionButtons from './questionnaire-action-buttons'
import QuestionnaireHeader from './questionnaire-header'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  questionsItemsContainer: {
    maxWidth: 'unset',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: '#ffffff',
  },
  title: {
    fontSize: 22,
    color: '#000000',
  },
  delete: {
    color: red[500],
  },
  headerContainer: {
    backgroundColor: '#dadadb',
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

export interface StateProps {
  questions: QuestionModel[]
  questionnaire: QuestionnaireModel | undefined
  themes: ThemeModel[]
  tags: TagModel[]
  currentUser: ClientModel | undefined
}

export interface DispatchesProps {
  getQuestions: (idQuestionnaire: number) => void
  getQuestionnaire: (idQuestionnaire: number) => void
  addQuestionnaire: (questionnaire: QuestionnaireModel) => void
  getThemes: (idClient: number) => void
  getTags: () => void
  updateQuestion: (question: QuestionModel) => void
  updateMultipleQuestions: (questions: QuestionModel[]) => void
  updateQuestionnaire: (questionnaire: QuestionnaireModel) => void
}

export interface OwnProps {
  idQuestionnaire?: number
  toEdit: boolean
}

export type Props = StateProps & DispatchesProps & OwnProps

const Questions = ({
  idQuestionnaire,
  questions,
  questionnaire,
  themes,
  tags,
  toEdit,
  currentUser,
  getQuestions,
  addQuestionnaire,
  getQuestionnaire,
  getThemes,
  getTags,
  updateQuestion,
  updateMultipleQuestions,
  updateQuestionnaire,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  // const [myQuestions, setMyQuestions] = useState(questions)
  const [currentQuestions, setCurrentQuestions] = useState(questions.filter(({ visible }) => visible))
  const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false)
  const [titleCreatedQuestionnaire, setTilteCreatedQuestionnaire] = useState('')
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<QuestionnaireModel>({
    tags: [],
    title: '',
    lang: '',
    theme: {
      label: '',
    },
    child: false,
    questions: [],
    idClient: -1,
    isTarget: false,
    isMono: false,
    isCustom: false,
    raisonSocial: '',
  })
  const [isOpenDialog, setisOpenDialog] = useState(false)
  const [questionToDelete, setQuestionToDelete] = useState<QuestionModel>()
  const [titleDeleteQuestion, setTitleDeleteQuestion] = useState<string>('')
  const [showActionButtonAddOrUpdate, setShowActionButtonAddOrUpdate] = useState<boolean>(false)
  const [currentyEditing, setCurrentyEditing] = useState<boolean>(toEdit)
  const [isMissingFieldsError, setIsMissingFieldsError] = useState<boolean>(false)
  const [isTargetNotFinal, setIsTargetNotFinal] = useState<boolean>(false)

  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)

  useEffect(() => {
    if (questionnaire) {
      setCurrentQuestionnaire(questionnaire)
    }
  }, [questionnaire])

  useEffect(() => {
    setCurrentQuestions(questions.filter(({ visible }) => visible))
  }, [questions])

  useEffect(() => {
    if (typeof idQuestionnaire !== 'undefined') {
      getQuestions(idQuestionnaire)
    }
  }, [getQuestions, idQuestionnaire])

  useEffect(() => {
    if (typeof idQuestionnaire !== 'undefined') {
      getQuestionnaire(idQuestionnaire)
    }
  }, [getQuestionnaire, idQuestionnaire])

  useEffect(() => {
    if (currentClient && currentClient.id) {
      getThemes(currentClient.isAdmin ? -1 : currentClient.id)
      getTags()
    }
  }, [currentClient, getTags, getThemes])

  useEffect(() => {
    if (currentQuestionnaire.isTarget && !currentQuestionnaire.tags.some((tg) => tg.label.toLocaleLowerCase() === 'final')) {
      setIsTargetNotFinal(true)
    } else {
      setIsTargetNotFinal(false)
    }
  }, [currentQuestionnaire.isTarget, currentQuestionnaire.tags])


  const handleOnDeleteQuestion = useCallback(
    (myQuestion: QuestionModel) => {
      setQuestionToDelete(myQuestion)
      const myQuestionToDelete = questions.filter((question) => question.id === myQuestion.id)
      setTitleDeleteQuestion(myQuestionToDelete[0].title)
      setisOpenDialog(true)
    },
    [questions]
  )

  const handleDragNDropChanges = (result: DropResult) => {
    if (!result.destination) return
    const items = Array.from(currentQuestionnaire.questions.filter((o) => o.visible).sort((a, b) => a.position - b.position))
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    const targets = items.map((item: QuestionModel, index: number) => {
      if (item) {
        return {
          ...item,
          position: index + 1,
          lastModifiedAt: new Date(),
        }
      }
      return item
    })
    if (targets.length === 0 || targets[0] === undefined) return
    updateMultipleQuestions(targets)
    updateQuestionnaire({
      ...questionnaire,
      questions: [...currentQuestionnaire.questions.filter((o) => !o.visible), ...targets],
    } as QuestionnaireModel)
  }

  const displayQuestion = () => {
    if (typeof idQuestionnaire !== 'undefined') {
      return currentQuestions
        .filter(({ visible }) => visible)
        .sort((a, b) => a.position - b.position)
        .map((question: QuestionModel, index: number) => (
          <QuestionItem
            key={question.id}
            question={{ ...question, idQuestionnaire }}
            onDeleteQuestion={handleOnDeleteQuestion}
            index={index}
          />
        ))
    }
    return null
  }

  const isQuestionnaireFinal = () => {
    let isFinal = false
    if (currentQuestionnaire) {
      currentQuestionnaire.tags.forEach((tag: TagModel) => {
        if (tag.label.toLocaleLowerCase() === 'final') {
          isFinal = true
          return isFinal
        }
        return 0
      })
    }
    return isFinal
  }

    function isFinalAndFinal(string: string | undefined, finalTheme: boolean): boolean {
    let stateString:boolean=true
    console.log(string)
    console.log(" / ")
    console.log (isQuestionnaireFinal())
    if (string === 'Final' && isQuestionnaireFinal()===false) {
      stateString=false
    }
   return stateString
  }

  const handleOnClickQuestionnaireAddAndUpdate = useCallback(() => {
    if (
      typeof currentQuestionnaire !== 'undefined' &&
      typeof currentQuestionnaire.theme !== 'undefined' &&
      typeof currentQuestionnaire.theme.id !== 'undefined' &&
      isNotEmpty(currentQuestionnaire.title) &&
      isNotEmpty(currentQuestionnaire.lang) &&
      typeof currentQuestionnaire.child !== 'undefined' &&
      typeof currentQuestionnaire.tags !== 'undefined' &&
      currentQuestionnaire.tags.length > 0 &&
       isFinalAndFinal(currentQuestionnaire.theme.label, isQuestionnaireFinal())
    ) {
      if (isTargetNotFinal) {
        setOpenValidationSnackbar(true)
      } else {
        if (currentyEditing) {
          updateQuestionnaire({ ...currentQuestionnaire, lastModifiedAt: new Date(Date.now()) })
        } else {
          addQuestionnaire({
            ...currentQuestionnaire,
            visible: true,
            lastModifiedAt: new Date(Date.now()),
            idClient: currentClient?.isAdmin ? -1 : currentClient?.id!,
            isCustom: !currentClient?.isAdmin,
            raisonSocial: currentClient?.raisonSocial || '',
          })
        }
        setOpenValidationSnackbar(true)
        setTilteCreatedQuestionnaire(currentQuestionnaire.title)
        setShowActionButtonAddOrUpdate(false)
        setTimeout(() => {
          setCurrentyEditing(true)
        }, 3000)
      }
    } else {
      setOpenValidationSnackbar(true)
      setIsMissingFieldsError(true)
    }
  }, [addQuestionnaire, currentClient, currentQuestionnaire, currentyEditing, isTargetNotFinal, updateQuestionnaire])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationSnackbar(false)
  }

  const updateCurrentQuestionnaire = useCallback(
    (questionnaireCreated: QuestionnaireModel) => {
      const newQuestionnaire = {
        ...currentQuestionnaire,
        ...questionnaireCreated,
      }
      setCurrentQuestionnaire(newQuestionnaire)
    },
    [currentQuestionnaire]
  )

  const confirmeDeleteQuestion = useCallback(() => {
    if (typeof questionToDelete !== 'undefined') {
      updateQuestion(questionToDelete)
      setisOpenDialog(false)
      const newList = currentQuestionnaire.questions.filter((question: QuestionModel) => question.id !== questionToDelete.id)
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: newList,
      })
    }
  }, [currentQuestionnaire, questionToDelete, updateQuestion])

  return (
    <div className={classes.questionsContainer}>
      <DialogDelete
        title={titleDeleteQuestion}
        isOpenDialog={isOpenDialog}
        setOpenDialog={setisOpenDialog}
        confirmeDelete={confirmeDeleteQuestion}
        validationText={t('questionDeleteValidation')}
        questiontitle={`${t('questionDeleteTitle')} `}
      />
      <QuestionnaireHeader
        themes={themes}
        tags={tags}
        questionnaire={currentQuestionnaire}
        updateQuestionnaire={updateCurrentQuestionnaire}
        setShowActionButtonAddOrUpdate={setShowActionButtonAddOrUpdate}
        setIsMissingFieldsError={setIsMissingFieldsError}
        currentUser={currentUser}
      />
      <DragDropContext onDragEnd={handleDragNDropChanges}>
        <Droppable droppableId="questionsDroppable">
          {(provided) => (
            <Container className={classes.questionsItemsContainer}>
              <Grid
                className="questionsDroppable"
                container
                alignItems="center"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {displayQuestion()}
                {provided.placeholder}
              </Grid>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <Container>
        <QuestionnaireActionButtons
          currentyEditing={currentyEditing}
          handleOnClickQuestionnaireAddAndUpdate={handleOnClickQuestionnaireAddAndUpdate}
          showActionButtonAddOrUpdate={showActionButtonAddOrUpdate}
        />
      </Container>
      <Snackbar open={openValidationSnackbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={isMissingFieldsError ? 'error' : isTargetNotFinal ? 'warning' : 'success'}>
          {isMissingFieldsError ? (
            <strong>
              <em>{t('questionnaireMissingField')}</em>
            </strong>
          ) : isTargetNotFinal ? (
            <strong>
              <em>{t('errorTargetFinal')}</em>
            </strong>
          ) : (
            <>
              {`${t('leQuestionnaire')} `}
              <strong>
                <em>{`${titleCreatedQuestionnaire.toUpperCase()} `}</em>
              </strong>
              {currentyEditing ? t('updateQuestionnaire') : t('addQuestionnaire')}
            </>
          )}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Questions
