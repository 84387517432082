import { connect } from 'react-redux'
import { Dispatch } from 'redux'

// import AnswerModel from '../../models/answer.model'
import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import {
  addQuestionAction,
  updateQuestionAction,
  uploadQuestionMedia,
  uploadAnswerMedia,
} from '../../redux/actions/question.actions'
import {
  updateQuestionnaireAction,
} from '../../redux/actions/questionnaire.actions'
import { RootStore } from '../../redux/store/root.store'
import QuestionFormPage, {
  DispatchesProps,
  StateProps,
  OwnProps,
} from './index'

function mapStateToProps(
  store: RootStore,
  own: OwnProps,
): StateProps & OwnProps {
  return {
    questionnaire: store.questionnaires.selectedQuestionnaire,
    question: own.question,
    questions: store.questions,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    addQuestion: (question: QuestionModel) => dispatch(addQuestionAction(question)),
    updateQuestion: (question: QuestionModel) => (
      dispatch(updateQuestionAction(question))
    ),
    uploadQuestionMedia: (file: FormData | undefined) => dispatch(uploadQuestionMedia(file)),
    uploadAnswerMedia: (file: FormData | undefined) => dispatch(uploadAnswerMedia(file)),
    updateQuestionnaire: (questionnaire: QuestionnaireModel) => dispatch(updateQuestionnaireAction(questionnaire)),
  }
}

const QuestionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionFormPage)

export default QuestionFormContainer
