import { AnyAction } from 'redux'

import ThemeModel from '../../models/theme.model'
import * as CONSTANTS from '../actions/constants'

export type ThemeState = ThemeModel[]

const initialState: ThemeState = []

export default function themeReducer(state = initialState, action: AnyAction): ThemeState {
  switch (action.type) {
    case CONSTANTS.GET_THEMES_DONE:
      return action.payload || []
    case CONSTANTS.GET_THEME_DONE:
      return action.payload || []
    case CONSTANTS.ADD_THEME_DONE:
      return [...state, action.payload]
    case CONSTANTS.DELETE_THEME_DONE:
      return state.filter((theme) => theme.id !== action.payload)
    case CONSTANTS.UPDATE_THEME_DONE:
      return state.map((theme) => {
        if (theme.id === action.payload.id) {
          return action.payload
        }
        return theme
      })
    default:
      return state
  }
}
