/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardContent, Checkbox, Grid, IconButton, makeStyles, Switch } from '@material-ui/core'
import {
  DragIndicator,
  ArrowBackIos,
  ChildCare,
  DeleteOutline,
  ImageOutlined,
  OndemandVideo,
  VolumeUpOutlined,
} from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import QuestionModel from '../../../models/question.model'
import QuestionnaireModel from '../../../models/questionnaire.model'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    color: '#4c4c4c',
    display: 'flex',
    flexDirection: 'column',
  },
  questionnaireTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#ffff00',
    width: 'fit-content',
    marginTop: '1rem',
    fontSize: '.5rem',
    gap: '.4rem',
    paddingLeft: '1.3rem',
    borderBottom: '1px solid #b3b3b3',
    cursor: 'pointer',
    fontStyle: 'italic',
    '& h1': {
      margin: '0',
      background: '#dbdbdb',
      height: '3.2rem',
      padding: '0 2rem',
      display: 'grid',
      placeContent: 'center',
      color: '#363636',
      textTransform: 'uppercase',
    },
  },
  questionsList: {
    padding: '0 4rem',
  },
  questionItem: {
    color: 'orange',
  },

  questionnaireCard: {
    minWidth: '100%',
    width: '100%',
    marginTop: '1rem',
    cursor: 'pointer',
  },
  questionnaireCardContent: {
    padding: '0 0 0 1rem',
    '&:last-child': {
      padding: '0 0 0 1rem',
    },
  },
  questionnaireContent: {
    fontSize: '.85rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
  },
  descriptiveIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    '& svg': {
      marginRight: '0.75rem',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconContainer: {
    color: 'red',
  },
}))

export interface StateProps {
  questionnaire: QuestionnaireModel | undefined
}

export interface DispatchesProps {
  getQuestionnaire: (idQuestionnaire: number) => void
}

export interface OwnProps {
  idQuestionnaire?: number
}

export type Props = StateProps & DispatchesProps & OwnProps

const Questions = ({ idQuestionnaire, questionnaire, getQuestionnaire }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<QuestionnaireModel | undefined>(questionnaire)

  useEffect(() => {
    if (typeof idQuestionnaire !== 'undefined') {
      getQuestionnaire(idQuestionnaire)
    }
  }, [getQuestionnaire, idQuestionnaire])

  useEffect(() => {
    setCurrentQuestionnaire(questionnaire)
  }, [questionnaire])

  const handleQuestionClick = useCallback(
    (questionId) => {
      history.push({
        pathname: `/questionnaire/${currentQuestionnaire?.id}/question/${questionId}`,
        state: { page: 'questions-details', idQuestion: questionId },
      })
    },
    [history, currentQuestionnaire?.id]
  )

  const handleGoBackClick = useCallback(() => {
    history.push({
      pathname: '/questionnaire',
      state: { page: 'questionnaires' },
    })
  }, [history])

  return (
    <div className={classes.rootContainer}>
      <Grid container className={classes.questionnaireTitle} onClick={handleGoBackClick}>
        <ArrowBackIos />
        <h1>{currentQuestionnaire?.title}</h1>
      </Grid>
      <Grid container className={classes.questionsList}>
        {currentQuestionnaire?.questions
          .filter(({ visible }) => visible)
          .sort((a, b) => a.position - b.position)
          .map((question) => (
            <Card className={classes.questionnaireCard} key={question.id} onClick={() => handleQuestionClick(question.id)}>
              <CardContent className={classes.questionnaireCardContent}>
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <IconButton aria-label="disabled-drag-and-drop" component="span" disabled>
                      <DragIndicator />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.questionnaireContent}>
                      <span>{question.title}</span>
                    </p>
                  </Grid>
                  <Grid item xs={3} className={classes.descriptiveIcons}>
                    <div>
                      {question.questionType?.includes('video') && (
                        <div className={classes.iconContainer}>
                          <OndemandVideo color="action" />
                        </div>
                      )}
                      {question.questionType?.includes('image') && (
                        <div className={classes.iconContainer}>
                          <ImageOutlined color="action" />
                        </div>
                      )}
                      {question.questionType?.includes('audio') && (
                        <div className={classes.iconContainer}>
                          <VolumeUpOutlined color="action" />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
      </Grid>
    </div>
  )
}

export default Questions
