/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, IconButton, Switch } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { DeleteOutline, SettingsOutlined, Videocam, VideocamOff } from '@material-ui/icons'
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ClientModel from '../../../models/client.model'
import { getDateFormatted } from '../../../utils/date-format'

const useStyles = makeStyles(() => ({
  clientCard: {
    fontSize: '1rem',
    minWidth: '100%',
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  clientCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 0 0 1rem',
    height: '3rem',
  },
  clientName: {
    textTransform: 'uppercase',
    width: '100%',
    fontWeight: 'bold',
  },
  params: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'left',
  },
  sessionRed: {
    color: red[500],
  },
  clientContent: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
    width: '50%',
  },
  feat: {
    maxWidth: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginInline: '.5rem',
    '& > span.MuiIconButton-root, & a > div > span.MuiIconButton-root': {
      padding: 0,
    },
  },
  icon: {
    fontSize: '2rem',
  },
  iconDarkColor: {
    color: '#393939',
  },
  media: {
    border: '2px solid',
    height: '26px',
    width: '29px',
    borderRadius: '7px 0 0 0',
    cursor: 'pointer',
    '&:hover': {
      color: '#b5871a',
    },
  },
}))

interface Props {
  client: ClientModel
  sessions: number
  handleDelete: (idClient: number) => void
  UpdateClient: (client: ClientModel) => void
}

const ClientItem = ({ client, sessions, handleDelete, UpdateClient }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const { id, raisonSocial, enProd, createdAt, media, countSessious } = client
  const [active, setActive] = useState(enProd)
  const [hasMedia, setHasMedia] = useState(media)

  const handleChangeEnProd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked)
    const newClient = { ...client, enProd: event.target.checked }
    UpdateClient(newClient)
  }

  const handleClickMedia = () => {
    setHasMedia((previousValue) => !previousValue)
    const newClient = { ...client, media: !hasMedia }
    UpdateClient(newClient)
  }

  const handleClickDeleteButton = useCallback(() => {
    if (typeof id !== 'undefined') {
      handleDelete(id)
    }
  }, [handleDelete, id])

  const CustomGreenAndBlackSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 36,
        height: 22,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        color: '#393939',
        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: '#82ff38', // theme.palette.primary.main,
            borderColor: '#393939', // theme.palette.primary.main,
            borderWidth: 3,
          },
        },
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: 'none',
        margin: 2,
        backgroundColor: '#393939',
      },
      track: {
        border: '3px solid #393939',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
    })
  )(Switch)

  return (
    <>
      <Card className={classes.clientCard}>
        <div className={classes.clientCardContent}>
          <Link
            to={{
              pathname: '/sessions',
              state: {
                tab: 1,
                page: 'sessions',
                client,
              },
            }}
            className={classes.clientName}
          >
            {raisonSocial}
          </Link>
          <div className={classes.params}>
            <div className={classes.clientContent}>
              <span className={classes.sessionRed}>{client?.countSessious || 0}</span>
              
              {` ${t('sessionsSince')} `}
              <span>{typeof createdAt !== 'undefined' && getDateFormatted(createdAt)} </span>
            </div>
            <div className={classes.feat}>
              <span className={classes.media} onClick={handleClickMedia}>
                {hasMedia ? <Videocam /> : <VideocamOff />}
              </span>
            </div>
            <div className={classes.feat}>
              <div>
                <CustomGreenAndBlackSwitch checked={active} onChange={handleChangeEnProd} name="en Prod" color="secondary" />
              </div>
            </div>
            <div className={classes.feat}>
              <Link
                to={{
                  pathname: `/Editclient/${id}`,
                  state: {
                    tab: 1,
                    page: 'EditClient',
                    data: id,
                    client,
                  },
                }}
              >
                <div>
                  <IconButton aria-label="settings" component="span">
                    <SettingsOutlined className={`${classes.icon} ${classes.iconDarkColor}`} />
                  </IconButton>
                </div>
              </Link>
            </div>
            <div className={classes.feat}>
              <IconButton aria-label="delete" component="span" onClick={handleClickDeleteButton}>
                <DeleteOutline className={`${classes.sessionRed} ${classes.icon}`} />
              </IconButton>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}
export default ClientItem
