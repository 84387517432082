import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import IQuestionnairePaginationRequest from '../../interfaces/IQuestionnairePaginationRequest'
import QuestionnaireModel from '../../models/questionnaire.model'
import {
  getQuestionnairesPaginationAction,
  cleanQuestionnaireSelectedAction,
  updateQuestionnaireAction,
  deleteQuestionnaireAction,
} from '../../redux/actions/questionnaire.actions'
import { getThemesAction } from '../../redux/actions/theme.actions'
import { RootStore } from '../../redux/store/root.store'
import QuestionnairePage, { DispatchesProps, StateProps } from './index'

function mapStateToProps(store: RootStore): StateProps {
  return {
    questionnaires: store.questionnaires.questionnaires,
    questionnairesTotal: store.questionnaires.questionnairePaginationTotalItems,
    themes: store.themes,
    currentUser: store.clients.currentUser,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchesProps {
  return {
    getQuestionnairesPagination: (ipaginationRequestInput: IQuestionnairePaginationRequest) =>
      dispatch(getQuestionnairesPaginationAction(ipaginationRequestInput)),
    // eslint-disable-next-line max-len
    updateQuestionnaire: (Questionnaire: QuestionnaireModel) => dispatch(updateQuestionnaireAction(Questionnaire)),
    cleanSelectedQuestionnaire: () => dispatch(cleanQuestionnaireSelectedAction()),
    getThemes: (idClient: number) => dispatch(getThemesAction(idClient)),
    deleteQuestionnaire: (Questionnaire: QuestionnaireModel) => dispatch(deleteQuestionnaireAction(Questionnaire)),
  }
}

const QuestionnaireContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionnairePage)

export default QuestionnaireContainer
