import { AnyAction } from 'redux'

import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import * as CONSTANTS from '../actions/constants'

export type QuestionnairesState = {
  questionnaires: QuestionnaireModel[];
  selectedQuestionnaire: QuestionnaireModel | undefined;
  questionnairePaginationTotalItems: number | undefined;
};

export type QuestionnaireState = QuestionnaireModel;

export const initialState: QuestionnairesState = {
  questionnaires: [],
  selectedQuestionnaire: undefined,
  questionnairePaginationTotalItems: undefined,
}

export default function questionnaireReducer(
  state = initialState,
  action: AnyAction,
): QuestionnairesState {
  switch (action.type) {
    case CONSTANTS.GET_QUESTIONNAIRES_DONE:
      return {
        ...state,
        questionnaires: action.payload || [],
      }
    case CONSTANTS.GET_QUESTIONNAIRES_PAGINATION_DONE:
      return {
        ...state,
        questionnaires: action.payload.questionnaires || [],
        questionnairePaginationTotalItems: action.payload.total,
      }
    case CONSTANTS.GET_QUESTIONNAIRE_DONE:
      return {
        ...state,
        selectedQuestionnaire: action.payload,
      }
    case CONSTANTS.ADD_QUESTIONNAIRE_DONE:
      return {
        ...state,
        questionnaires:
          [
            ...state.questionnaires,
            action.payload,
          ],
        selectedQuestionnaire: action.payload,
      }
    case CONSTANTS.DELETE_QUESTIONNAIRE_DONE:
      return {
        ...state,
        questionnaires: [...state.questionnaires, action.payload],
      }

    case CONSTANTS.UPDATE_QUESTIONNAIRE_DONE:
      return {
        ...state,
        questionnaires: state.questionnaires.map((questionnaire) => {
          if (questionnaire.id === action.payload.id) {
            return action.payload
          }
          return questionnaire
        }),
        selectedQuestionnaire: action.payload,
      }
    case CONSTANTS.CLEAN_QUESTIONNAIRE_SELECTED:
      return {
        ...state,
        selectedQuestionnaire: undefined,
      }
    case CONSTANTS.ADD_QUESTION_DONE:
      return {
        ...state,
        questionnaires: state.questionnaires.map((questionnaire) => {
          if (questionnaire.id === action.payload.idQuestionnaire) {
            return {
              ...questionnaire,
              lastModifiedAt: new Date(),
              questions: [
                ...(
                  questionnaire.questions
                    ? questionnaire.questions.filter((o: QuestionModel) => o.id !== action.payload.id)
                    : []
                ),
                { ...action.payload, questionnaire: undefined },
              ],
            }
          }
          return questionnaire
        }),
        selectedQuestionnaire: {
          ...state.selectedQuestionnaire,
          lastModifiedAt: new Date(),
          questions: [
            ...(state.selectedQuestionnaire?.questions || []).filter((o: QuestionModel) => o.id !== action.payload.id),
            { ...action.payload, questionnaire: undefined },
          ],
        } as QuestionnaireModel,
      }
    case CONSTANTS.UPDATE_QUESTION_DONE:
      return {
        ...state,
        questionnaires: state.questionnaires.map((questionnaire) => {
          if (questionnaire.id === action.payload.idQuestionnaire) {
            return {
              ...questionnaire,
              lastModifiedAt: new Date(),
              questions: questionnaire.questions
                ? questionnaire.questions.map((o) => {
                  if (o.id === action.payload.id) {
                    return { ...action.payload, questionnaire: undefined }
                  }
                  return o
                })
                : [],
            }
          }
          return questionnaire
        }),
        selectedQuestionnaire: {
          ...state.selectedQuestionnaire,
          lastModifiedAt: new Date(),
          questions: (state.selectedQuestionnaire?.questions || [action.payload]).map((o) => {
            if (o.id === action.payload.id) {
              return { ...action.payload, questionnaire: undefined }
            }
            return o
          }),
        } as QuestionnaireModel,
      }
    default:
      return state
  }
}
