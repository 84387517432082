import { Card, IconButton, Tooltip } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { People } from '@material-ui/icons'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import SessionModel from '../../../../models/session.model'
import TeamModel from '../../../../models/team.model'
import { getDateFormatted, getTimeFormatted } from '../../../../utils/date-format'
import TooltipSessionComplete from '../tooltip-session-complete'

const useStyles = makeStyles(() => ({
  clientCard: {
    minWidth: '100%',
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  clientCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 0 0 1rem',
    fontWeight: 'bold',
  },
  red: {
    color: red[500],
  },
  text: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: '#000000',
  },
  icon: {
    fontSize: '2rem',
  },
  separateur: {
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  feat: {
    maxWidth: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '1rem',
  },
  textContainer: {
    display: 'flex',
  },
  spaceId: {
    color: red[500],
    minWidth: '1rem',
  },
}))

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: '#5c5c5c',
    backgroundColor: '#d2d2d2',
    fontWeight: 900,
  },
})(Tooltip)

interface Props {
  session: SessionModel
}

const SessionCompleteItem = ({ session }: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const { heureLancement, heureValidation, heureFin, teams, clientSessionId } = session
  const [totalJoueur, setTotalJoeur] = React.useState<number>(0)

  useEffect(() => {
    let totalJoueurTemp = 0
    if (typeof session?.teams !== 'undefined') {
      session.teams.forEach((team: TeamModel) => {
        if (typeof team?.players !== 'undefined') {
          totalJoueurTemp += team.players.length
        }
      })
    }

    setTotalJoeur(totalJoueurTemp)
  }, [session.teams])

  const memoizedNumeroSession: number = useMemo(() => clientSessionId || 0, [clientSessionId])
  return (
    <>
      <Card className={classes.clientCard}>
        <div className={classes.clientCardContent}>
          <div className={classes.textContainer}>
            <div className={`${classes.text} ${classes.spaceId}`}>{memoizedNumeroSession}</div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>{getDateFormatted(heureLancement)}</div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>
              {`${t('heureDepart')} : `}
              <span className={classes.red}>{heureLancement ? getTimeFormatted(heureLancement) : '---'}</span>
            </div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>
              {`${t('heureValidation')} : `}
              <span className={classes.red}>{heureValidation ? getTimeFormatted(heureValidation) : '---'}</span>
            </div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>
              {`${t('heureFin')} : `}
              <span className={classes.red}>{heureFin ? getTimeFormatted(heureFin) : '---'}</span>
            </div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>
              <span className={classes.red}>{totalJoueur}</span>
              {` ${t('joueur')}`}S
            </div>
            <div className={classes.separateur}>{' | '}</div>
            <div className={classes.text}>
              <span className={classes.red}>{teams?.length || 0}</span>
              {` ${t('equipe')}`}S
            </div>
          </div>
          <div className={classes.feat}>
            <BlueOnGreenTooltip title={<TooltipSessionComplete teams={session.teams} />} aria-label="add">
              <IconButton aria-label="people" component="span">
                <People className={classes.icon} />
              </IconButton>
            </BlueOnGreenTooltip>
          </div>
        </div>
      </Card>
    </>
  )
}

export default SessionCompleteItem
