/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TagModel from '../../models/tag.model'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
    },
  })
)

export interface OwnProps {
  color?: string
  handleTags: (TagsArr: TagModel[]) => void
  Tags: TagModel[]
  allTags: TagModel[]
}

export type Props = OwnProps

function TagsTextFields({ Tags, allTags, handleTags }: OwnProps) {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  const getOptions = (lesTags: TagModel[]) => lesTags.map((tag) => tag.label)

  const [myTags, setmyTags] = useState(getOptions(Tags))

  // turning the strings into tag model
  const toTags = (arr: string[]): TagModel[] =>
    arr.map((tag: string) => {
      const newArr = allTags.filter((leTag) => leTag.label.toUpperCase() === tag.toUpperCase())
      if (newArr.length !== 0) {
        return newArr[0]
      }
      return {
        label: tag,
      }
    })

  const handleChange = (event: any, newValue: string[]) => {
    event.preventDefault()
    setmyTags(newValue)
    handleTags([...toTags(newValue)])
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={getOptions(allTags)}
        freeSolo
        value={myTags.length === 0 ? getOptions(Tags) : myTags}
        onChange={handleChange}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            className={classes.textField}
            {...params}
            variant="outlined"
            label="TAGS"
            placeholder={`${t('enter')} tag`}
            color="secondary"
          />
        )}
      />
    </div>
  )
}
export default React.memo(TagsTextFields)
