import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import IQuestionnairePaginationResults from '../../interfaces/IQuestionnairePaginationResults'
import QuestionnaireModel from '../../models/questionnaire.model'
import * as CONSTANTS from '../actions/constants'
import {
  addQuestionnaireActionDone,
  addQuestionnaireActionFailed,
  getQuestionnaireActionDone,
  getQuestionnaireActionFailed,
  getQuestionnairesActionDone,
  getQuestionnairesActionFailed,
  updateQuestionnaireActionDone,
  updateQuestionnaireActionFailed,
  getQuestionnairesPaginationActionDone,
  getQuestionnairesPaginationActionFailed,
} from '../actions/questionnaire.actions'
import Api from '../apis/api-factory'

function* getQuestionnaires(action: AnyAction) {
  try {
    const clientId = action.payload
    const questionnaires: QuestionnaireModel[] | [] = yield call(Api.getQuestionnaires, clientId)

    yield put(getQuestionnairesActionDone(questionnaires))
  } catch (e) {
    yield put(getQuestionnairesActionFailed(e as Error))
  }
}

function* getQuestionnairesPagination(action: AnyAction) {
  try {
    const { limit, page, child, lang, themeId, idClient, isTarget, isCustom } = action.payload
    const results: IQuestionnairePaginationResults = yield call(
      Api.getQuestionnairesPagination,
      limit,
      page,
      child,
      lang,
      themeId,
      idClient,
      isTarget,
      isCustom
    )

    if (results.error) {
      yield put(getQuestionnairesPaginationActionFailed(new Error(results.message)))
    } else {
      yield put(
        getQuestionnairesPaginationActionDone({
          questionnaires: results.questionnaires,
          total: results.total,
        })
      )
    }
  } catch (e) {
    yield put(getQuestionnairesPaginationActionFailed(e as Error))
  }
}

function* getQuestionnaire(action: AnyAction) {
  try {
    const idQuestionnaire = action.payload
    const questionnaire: QuestionnaireModel = yield call(Api.getQuestionnaire, idQuestionnaire)
    for (let i = 0; i < questionnaire.questions.length; i += 1) {
      questionnaire.questions[i].idQuestionnaire = idQuestionnaire
    }
    yield put(getQuestionnaireActionDone(questionnaire))
  } catch (e) {
    yield put(getQuestionnaireActionFailed(e as Error))
  }
}

function* publicQuestionnaire(action: AnyAction) {
  try {
    const idQuestionnaire = action.payload
    const questionnaire: QuestionnaireModel = yield call(Api.publicQuestionnaire, idQuestionnaire)
    const lastModifiedAt = new Date()
    // save tags first
    const { tags } = questionnaire
    for (let i = 0; i < tags.length; i += 1) {
      tags[i] = yield call(Api.addTag, tags[i])
    }
    // end save tags
    const data: QuestionnaireModel = { ...questionnaire, lastModifiedAt, tags }
    yield put(updateQuestionnaireActionDone(data))
  } catch (e) {
    yield put(updateQuestionnaireActionFailed(e as Error))
  }
}

function* addQuestionnaire(action: AnyAction) {
  try {
    const questionnaire: QuestionnaireModel = action.payload
    // save tags first
    const { tags } = questionnaire
    for (let i = 0; i < tags.length; i += 1) {
      tags[i] = yield call(Api.addTag, tags[i])
    }
    // end save tags
    const questionnaireSaved: QuestionnaireModel = yield call(Api.addQuestionnaire, { ...questionnaire, tags })
    yield put(addQuestionnaireActionDone(questionnaireSaved))
  } catch (e) {
    yield put(addQuestionnaireActionFailed(e as Error))
  }
}

export function* updateQuestionnaire(action: AnyAction) {
  try {
    const questionnaire: QuestionnaireModel = action.payload
    const lastModifiedAt = new Date()
    // save tags first
    const { tags } = questionnaire
    for (let i = 0; i < tags.length; i += 1) {
      tags[i] = yield call(Api.addTag, tags[i])
    }
    // end save tags
    // check if theme is still inUse

    // end check theme inUse
    const data: QuestionnaireModel = yield call(Api.updateQuestionnaire, { ...questionnaire, lastModifiedAt, tags })
    yield put(updateQuestionnaireActionDone(data))
  } catch (e) {
    yield put(updateQuestionnaireActionFailed(e as Error))
  }
}

function* deleteQuestionnaire(action: AnyAction) {
  // a delete is just an update that switch the 'visible' attribute to 'false' in the questionnaire and check if the theme is still in use
  try {
    const questionnaire: QuestionnaireModel = action.payload
    const lastModifiedAt = new Date()
    const data: QuestionnaireModel = yield call(Api.deleteQuestionnaire, { ...questionnaire, lastModifiedAt })
    yield put(updateQuestionnaireActionDone(data))
  } catch (e) {
    yield put(updateQuestionnaireActionFailed(e as Error))
  }
}

export default function* questionnairesSaga() {
  yield takeLatest(CONSTANTS.PUBLIC_QUESTIONNAIRE, publicQuestionnaire)
  yield takeLatest(CONSTANTS.GET_QUESTIONNAIRES, getQuestionnaires)
  yield takeLatest(CONSTANTS.GET_QUESTIONNAIRES_PAGINATION, getQuestionnairesPagination)
  yield takeLatest(CONSTANTS.GET_QUESTIONNAIRE, getQuestionnaire)
  yield takeLatest(CONSTANTS.ADD_QUESTIONNAIRE, addQuestionnaire)
  yield takeLatest(CONSTANTS.UPDATE_QUESTIONNAIRE, updateQuestionnaire)
  yield takeLatest(CONSTANTS.DELETE_QUESTIONNAIRE, deleteQuestionnaire)
}
