import axios from 'axios'

import IQuestionnairePaginationResults from '../../interfaces/IQuestionnairePaginationResults'
import ISessionPaginationResults from '../../interfaces/ISessionPaginationResults'
import ClientModel from '../../models/client.model'
import QuestionModel from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import TagModel from '../../models/tag.model'
import ThemeModel from '../../models/theme.model'
import HttpClient from './http-client'

interface LoginClientFrom {
  mail: string
  motDePasse: string
}

class HttpApi {
  private baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  // Questionnaire

  getQuestionnaires = (clientId: number) =>
    HttpClient.get<QuestionnaireModel[]>(`${this.baseUrl}/questionnaire/${clientId}`, true)

  getQuestionnairesPagination = (
    limit: number,
    page: number,
    child: boolean,
    lang: string,
    themeId: number,
    idClient: number,
    isTarget: boolean,
    isCustom: boolean
  ) =>
    HttpClient.post<IQuestionnairePaginationResults>(
      `${this.baseUrl}/questionnaire/pagination/${limit}/${page}/${idClient}`,
      {
        child,
        lang,
        themeId,
        isTarget,
        isCustom,
      },
      true
    )

  getQuestionnaire = (questionnaireId: string) =>
    HttpClient.get<QuestionnaireModel>(`${this.baseUrl}/questionnaire/single/${questionnaireId}`, true)

  publicQuestionnaire = (questionnaireId: string) =>
    HttpClient.get<QuestionnaireModel>(`${this.baseUrl}/questionnaire/public/${questionnaireId}`, true)

  addQuestionnaire = (questionnaire: QuestionnaireModel) =>
    HttpClient.post<QuestionnaireModel>(`${this.baseUrl}/questionnaire`, questionnaire, true)

  updateQuestionnaire = (questionnaire: QuestionnaireModel) =>
    HttpClient.put(`${this.baseUrl}/questionnaire/${questionnaire.id}`, questionnaire, true)

  deleteQuestionnaire = (questionnaire: QuestionnaireModel) =>
    HttpClient.delete(`${this.baseUrl}/questionnaire/${questionnaire.id}`, questionnaire, true)

  // Question

  getQuestions = (questionnaireId: string) =>
    HttpClient.get<QuestionModel[]>(`${this.baseUrl}/question/byQuestionnaire/${questionnaireId}`, true)

  getQuestion = (questionId: string) => HttpClient.get<QuestionModel>(`${this.baseUrl}/question/${questionId}`, true)

  addQuestion = (question: QuestionModel) => HttpClient.post<QuestionModel>(`${this.baseUrl}/question`, question, true)

  updateQuestion = (question: QuestionModel) => HttpClient.put(`${this.baseUrl}/question/${question.id}`, question, true)

  deleteQuestion = (questionId: string) => HttpClient.delete(`${this.baseUrl}/question/${questionId}`, undefined, true)

  // Themes

  getThemes = (idClient: number) => HttpClient.get<ThemeModel[]>(`${this.baseUrl}/theme/${idClient}`, true)

  getTheme = (themeId: string) => HttpClient.get<ThemeModel>(`${this.baseUrl}/theme/single/${themeId}`, true)

  addTheme = (theme: ThemeModel) => HttpClient.post<ThemeModel>(`${this.baseUrl}/theme`, theme, true)

  updateTheme = (theme: ThemeModel) => HttpClient.put(`${this.baseUrl}/theme/${theme.id}`, theme, true)

  deleteTheme = (themeId: string) => HttpClient.delete(`${this.baseUrl}/theme/${themeId}`, undefined, true)

  // Tags

  getTags = () => HttpClient.get<TagModel[]>(`${this.baseUrl}/tag`, true)

  getTag = (tagId: string) => HttpClient.get<TagModel>(`${this.baseUrl}/tag/${tagId}`, true)

  addTag = (tag: TagModel) => HttpClient.post<TagModel>(`${this.baseUrl}/tag`, tag, true)

  deleteTag = (tagId: string) => HttpClient.delete(`${this.baseUrl}/tag/${tagId}`, undefined, true)

  // On utilise axios et non httpClient car la librairy d'upload
  // n'accept que le content type multipart/form-data

  // Question
  uploadQuestionMedia = (file: FormData) =>
    axios
      .post(`${this.baseUrl}/question/upload/question`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => console.log('uploadQuestionMedia - response -', res))
      .catch((e) => console.log('uploadQuestionMedia - error -', e))

  // Answers
  uploadAnswersMedia = (file: FormData) => {
    axios
      .post(`${this.baseUrl}/question/upload/answer`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => console.log('uploadAnswersMedia - response -', res))
      .catch((e) => console.log('uploadAnswersMedia - error -', e))
  }

  // Client

  authClient = () => HttpClient.get<any>(`${this.baseUrl}/isUserAuth`, true)

  loginClient = (body: LoginClientFrom) => HttpClient.post<ClientModel>(`${this.baseUrl}/loginClient`, body)

  getClients = () => HttpClient.get<ClientModel[]>(`${this.baseUrl}/client`, true)

  getClient = (clientId: string) => HttpClient.get<ClientModel>(`${this.baseUrl}/client/${clientId}`, true)

  addClient = (client: ClientModel) => HttpClient.post<ClientModel>(`${this.baseUrl}/client`, client, true)

  updateClient = (client: ClientModel) => HttpClient.put(`${this.baseUrl}/client/${client.id}`, client, true)

  deleteClient = (clientId: string) => HttpClient.delete(`${this.baseUrl}/client/${clientId}`, undefined, true)

  getSessionsByIdClient = (clientId: string) => HttpClient.get(`${this.baseUrl}/session/byClient/${clientId}`, true)

  getSessionsPagination = (
    limit: number,
    page: number,
    clientId: number,
    startDate: string,
    endDate: string,
    isFacturable: boolean
  ) =>
    HttpClient.post<ISessionPaginationResults>(
      `${this.baseUrl}/session/pagination/${clientId}/${limit}/${page}`,
      {
        startDate,
        endDate,
        isFacturable,
      },
      true
    )

  getSessionsHistory = (clientId: number, startDate: string, endDate: string) =>
    HttpClient.post(
      `${this.baseUrl}/session/history/${clientId}`,
      {
        startDate,
        endDate,
      },
      true
    )
}

export default HttpApi
