import { AnyAction } from 'redux'

import TagModel from '../../models/tag.model'
import * as CONSTANTS from '../actions/constants'

export type TagState = TagModel[]

const initialState: TagState = []

export default function tagReducer(state = initialState, action: AnyAction): TagState {
  switch (action.type) {
    case CONSTANTS.GET_TAGS_DONE:
      return action.payload || []
    case CONSTANTS.GET_TAG_DONE:
      return action.payload || []
    case CONSTANTS.ADD_TAG_DONE:
      return [...state, action.payload]
    case CONSTANTS.ADD_MULTIPLE_TAGS_DONE:
      return [
        ...state,
        ...action.payload.filter((o: TagModel) => !state.find((b) => b.id === o.id)),
      ]
    case CONSTANTS.ADD_QUESTIONNAIRE_DONE:
    case CONSTANTS.UPDATE_QUESTIONNAIRE_DONE:
      return [
        ...state,
        ...action.payload.tags.filter((o: TagModel) => !state.find((b) => b.id === o.id)),
      ]
    case CONSTANTS.DELETE_TAG_DONE:
      return state.filter((tag) => tag.id !== action.payload)

    default:
      return state
  }
}
