import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AnswerModel from '../../../models/answer.model'
import isNotEmpty from '../../../utils/string-utils'
import Reponse from './reponse'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    paddingTop: '1rem',
  },
}))

export interface OwnProps {
  firstAnswerMedia: string
  secondAnswerMedia: string
  thirdAnswerMedia: string
  forthAnswerMedia: string
  allAnswers: AnswerModel[]
  answerMediaError: string
  onChangeAnswersMedia: (first: string, second: string, third: string, forth: string) => void
  setMyFiles: (files: AnswerModel[]) => void
  setAnswerMediaError: (error: string) => void
}

export type Props = OwnProps

const QuestionsAnswersPhotos = ({
  firstAnswerMedia,
  secondAnswerMedia,
  thirdAnswerMedia,
  forthAnswerMedia,
  allAnswers,
  answerMediaError,
  onChangeAnswersMedia,
  setMyFiles,
  setAnswerMediaError,
}: Props) => {
  const classes = useStyles()

  // Translation
  const { t } = useTranslation()

  // useStates
  const [currentFirstAnswerMedia, setCurrentFirstAnswernMedia] = useState<string>(firstAnswerMedia)
  const [currentSecondAnswerMedia, setCurrentSecondAnswernMedia] = useState<string>(secondAnswerMedia)
  const [currentThirdAnswerMedia, setCurrentThirdAnswernMedia] = useState<string>(thirdAnswerMedia)
  const [currentForthAnswerMedia, setCurrentForthAnswernMedia] = useState<string>(forthAnswerMedia)
  const [currentFirstAnswerMediaError, setCurrentFirstAnswernMediaError] = useState<boolean>(false)
  const [currentSecondAnswerMediaError, setCurrentSecondAnswernMediaError] = useState<boolean>(false)
  const [currentThirdAnswerMediaError, setCurrentThirdAnswernMediaError] = useState<boolean>(false)
  const [currentForthAnswerMediaError, setCurrentForthAnswernMediaError] = useState<boolean>(false)
  // const [error, setError] = useState<string | null>()

  // UseEffects
  useEffect(() => {
    setCurrentFirstAnswernMedia(firstAnswerMedia)
    setCurrentSecondAnswernMedia(secondAnswerMedia)
    setCurrentThirdAnswernMedia(thirdAnswerMedia)
    setCurrentForthAnswernMedia(forthAnswerMedia)
  }, [firstAnswerMedia, forthAnswerMedia, secondAnswerMedia, thirdAnswerMedia])

  // Handle Changes
  const handleChangeFirstAnswerMedia = useCallback(
    (fileName: string) => {
      const first = fileName as string
      setCurrentFirstAnswernMedia(first)
      onChangeAnswersMedia(first, currentSecondAnswerMedia, currentThirdAnswerMedia, currentForthAnswerMedia)
    },
    [currentForthAnswerMedia, currentSecondAnswerMedia, currentThirdAnswerMedia, onChangeAnswersMedia]
  )

  const handleChangeSecondAnswerMedia = useCallback(
    (fileName: string) => {
      const second = fileName as string
      setCurrentSecondAnswernMedia(second)
      onChangeAnswersMedia(currentFirstAnswerMedia, second, currentThirdAnswerMedia, currentForthAnswerMedia)
    },
    [currentFirstAnswerMedia, currentForthAnswerMedia, currentThirdAnswerMedia, onChangeAnswersMedia]
  )

  const handleChangeThirdAnswerMedia = useCallback(
    (fileName: string) => {
      const third = fileName as string
      setCurrentThirdAnswernMedia(third)
      onChangeAnswersMedia(currentFirstAnswerMedia, currentSecondAnswerMedia, third, currentForthAnswerMedia)
    },
    [currentFirstAnswerMedia, currentForthAnswerMedia, currentSecondAnswerMedia, onChangeAnswersMedia]
  )

  const handleChangeForthAnswerMedia = useCallback(
    (fileName: string) => {
      const forth = fileName as string
      setCurrentForthAnswernMedia(forth)
      onChangeAnswersMedia(currentFirstAnswerMedia, currentSecondAnswerMedia, currentThirdAnswerMedia, forth)
    },
    [currentFirstAnswerMedia, currentSecondAnswerMedia, currentThirdAnswerMedia, onChangeAnswersMedia]
  )

  useEffect(() => {
    if (
      currentFirstAnswerMediaError ||
      currentSecondAnswerMediaError ||
      currentThirdAnswerMediaError ||
      currentForthAnswerMediaError
    )
      setAnswerMediaError(t('uploadImageError'))
    else setAnswerMediaError('')
  }, [
    currentFirstAnswerMediaError,
    currentForthAnswerMediaError,
    currentSecondAnswerMediaError,
    currentThirdAnswerMediaError,
    setAnswerMediaError,
    t,
  ])
  // Render
  return (
    <>
      <div className={classes.container}>
        <Reponse
          num={1}
          answer={currentFirstAnswerMedia}
          handleOnChange={handleChangeFirstAnswerMedia}
          allAnswers={allAnswers}
          setMyFiles={setMyFiles}
          setError={setCurrentFirstAnswernMediaError}
        />
        <Reponse
          num={2}
          answer={currentSecondAnswerMedia}
          handleOnChange={handleChangeSecondAnswerMedia}
          allAnswers={allAnswers}
          setMyFiles={setMyFiles}
          setError={setCurrentSecondAnswernMediaError}
        />
        <Reponse
          num={3}
          answer={currentThirdAnswerMedia}
          handleOnChange={handleChangeThirdAnswerMedia}
          allAnswers={allAnswers}
          setMyFiles={setMyFiles}
          setError={setCurrentThirdAnswernMediaError}
        />
        <Reponse
          num={4}
          answer={currentForthAnswerMedia}
          handleOnChange={handleChangeForthAnswerMedia}
          allAnswers={allAnswers}
          setMyFiles={setMyFiles}
          setError={setCurrentForthAnswernMediaError}
        />
      </div>
      {isNotEmpty(answerMediaError) && <div style={{ color: 'red', marginBottom: '1rem' }}>{answerMediaError}</div>}
      <div>
        <div style={{ color: 'gray' }}>
          <b>- {t('type')}:</b> jpg
        </div>
        <div style={{ color: 'gray' }}>
          <b>- {t('tailleMax')} :</b> 100 ko
        </div>
        <div style={{ color: 'gray' }}>
          <b>
            - {t('largeur')} x {t('hauteur')} :
          </b>{' '}
          455 x 255 ({t('fixe')})
        </div>
      </div>
    </>
  )
}

export default QuestionsAnswersPhotos
