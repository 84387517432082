/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card, CardContent, createStyles, Grid, IconButton, makeStyles, Switch, withStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import {
  ChildCare,
  DeleteOutline,
  ImageOutlined,
  OndemandVideo,
  VolumeUpOutlined,
  Lock,
  TrackChanges,
} from '@material-ui/icons'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import QuestionModel, { questionType } from '../../models/question.model'
import QuestionnaireModel from '../../models/questionnaire.model'
import { publicQuestionnaireAction } from '../../redux/actions/questionnaire.actions'
import { RootStore } from '../../redux/store/root.store'

const useStyles = makeStyles(() => ({
  questionnaireCard: {
    minWidth: '100%',
    width: '100%',
    marginTop: '1rem',
  },
  questionnaireCardContent: {
    height: '3rem',
    display: 'flex',
    padding: '0 0 0 1rem',
    '&:last-child': {
      padding: '0 0 0 1rem',
    },
    cursor: 'pointer',
  },
  questionnaireTitleAndTheme: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
  questionnaireContent: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  },
  questionnaireRed: {
    color: red[500],
  },
  delete: {
    color: red[500],
  },
  descriptiveIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    gap: '.8rem',
    '& svg': {
      marginRight: '0.75rem',
      fontSize: '1.5rem',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'default',
    paddingRight: '.5rem',
  },
  iconContainer: {
    color: red[500],
    '& > svg': {
      width: '1.7rem',
      height: '1.7rem',
    },
  },
  checkboxColor: {
    color: '#393939 !important',
  },
  lockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    width: '48px',
    marginRight: '25px',
  },
  isAdminQuestion: {
    background: '#c5c5c5',
  },
  isTargeted: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
  },
  hasTargetId: {
    background: 'gold',
    borderRadius: '0.5rem',
    padding: '0.2rem 0.3rem',
    minWidth: '55px',
    textAlign: 'center',
    fontWeight: 600,
  },
  turnPublic: {
    background: 'red',
    color: 'white',
    borderRadius: '0.5rem',
    padding: '0.3rem 0.9rem',
    width: 'fit-content',
    textAlign: 'center',
    fontWeight: 600,
    cursor: 'pointer',
    margin: '0 auto',
    zIndex: 3,
  },
}))

interface Props {
  questionnaire: QuestionnaireModel
  onClickDeleteQuestionnaire: (questionnaire: QuestionnaireModel) => void
  onUpdateQuestionnaire: (questionnaire: QuestionnaireModel) => void
}

const QuestionnaireItem = ({ questionnaire, onUpdateQuestionnaire, onClickDeleteQuestionnaire }: Props) => {
  // Translation
  const { t } = useTranslation()

  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(questionnaire)
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [mediaTypesArr, setMediaTypesArr] = useState<string[] | undefined>([])

  const currentClient = useSelector((state: RootStore) => state.clients.currentUser)

  const isAdmin = currentClient?.isAdmin

  const getTypeMedia = useCallback(() => {
    const arr: questionType[] = []
    currentQuestionnaire.questions?.forEach((quest: QuestionModel) => {
      if (!arr.includes(quest.questionType)) arr.push(quest.questionType)
    })
    return arr
  }, [currentQuestionnaire.questions])

  const handleEnProd = () => {
    const target = {
      ...currentQuestionnaire,
      enProd: !currentQuestionnaire.enProd,
      enTest: currentQuestionnaire.enProd ? currentQuestionnaire.enTest : false,
      enProdAt: !currentQuestionnaire.enProd ? new Date() : null,
    }
    setCurrentQuestionnaire(target)
    onUpdateQuestionnaire(target)
  }

  const handleClickDeleteButton = useCallback(() => {
    onClickDeleteQuestionnaire(currentQuestionnaire)
  }, [currentQuestionnaire, onClickDeleteQuestionnaire])

  const CustomGreenAndBlackSwitch = withStyles(() =>
    createStyles({
      root: {
        width: 36,
        height: 22,
        padding: 0,
        display: 'flex',
        marginInline: '.7rem',
      },
      switchBase: {
        padding: 2,
        color: '#393939',
        '&$checked': {
          transform: 'translateX(12px)',
          color: 'white',
          '& + $track': {
            opacity: 1,
            backgroundColor: '#82ff38',
            borderColor: '#393939',
            borderWidth: 3,
          },
        },
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: 'none',
        margin: 2,
        backgroundColor: '#393939',
      },
      track: {
        border: '3px solid #393939',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: 'white',
      },
      checked: {},
    })
  )(Switch)

  const handleClick = useCallback(() => {
    history.push({
      pathname: `/questionnaire/${currentQuestionnaire.id}`,
      state: {
        page: 'questionnaire',
        data: currentQuestionnaire.id,
        toEdit: true,
        adminDisplay: currentClient?.isAdmin || currentClient?.id === currentQuestionnaire.idClient,
      },
    })
  }, [history, currentQuestionnaire.id, currentQuestionnaire.idClient, currentClient])

  function formatDuration(durationToFormat: string | undefined) {
    if (typeof durationToFormat === 'undefined') {
      return "0'00"
    }
    const durationValue = parseFloat(durationToFormat)
    return `${Math.floor(durationValue / 60)}'${(durationValue % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`
  }

  const formatCountQuestion = (countQuestion: number | undefined) =>
    countQuestion && countQuestion < 10 ? `0${countQuestion}` : countQuestion

  const formatedDuration = useMemo(() => formatDuration(currentQuestionnaire.duration), [currentQuestionnaire.duration])

  const displayTags = () => {
    let myTags = ''
    currentQuestionnaire.tags.forEach((tag) => {
      myTags += `${tag.label} - `
    })
    if (myTags.length > 20) {
      myTags = myTags.slice(0, 20)
    }
    myTags = myTags.substring(0, myTags.length - 2)
    return myTags
  }

  const handlePublic = useCallback(() => {
    dispatch(publicQuestionnaireAction(currentQuestionnaire.id!))
  }, [currentQuestionnaire.id, dispatch])

  useEffect(() => {
    setMediaTypesArr(getTypeMedia())
    setCurrentQuestionnaire(questionnaire)
  }, [getTypeMedia, questionnaire])

  return (
    <Card className={classes.questionnaireCard}>
      <CardContent
        className={`${classes.questionnaireCardContent} ${
          !currentClient?.isAdmin && currentQuestionnaire.idClient === -1 ? classes.isAdminQuestion : ''
        }`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={3} className={classes.questionnaireTitleAndTheme} onClick={handleClick}>
            <span className={classes.questionnaireRed}>{` ${currentQuestionnaire?.theme?.label || 'N / A'}:`}</span>
            <span>{` ${currentQuestionnaire.title} `}</span>
           
          </Grid>
          <Grid item xs={2}>
            <div
              className={classes.hasTargetId}
              style={{ visibility: currentQuestionnaire.isCustom ? 'visible' : 'hidden', textAlign: 'center' }}
            >
              {currentQuestionnaire.raisonSocial}
            </div>
          </Grid>
          <Grid item className={classes.isTargeted}>
            <TrackChanges
              style={{
                visibility: currentQuestionnaire.isTarget ? 'visible' : 'hidden',
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.questionnaireContent} onClick={handleClick}>
            <div className={classes.questionnaireContent}>
              <span>{t('question')}s</span>
              <span>:</span>
              <span className={classes.questionnaireRed}>{formatCountQuestion(currentQuestionnaire.countQuestion)}</span>
              <span>|</span>
              <span className={classes.questionnaireRed}>{formatedDuration}</span>
              <span>|</span>
              <span className={classes.questionnaireRed}>{currentQuestionnaire.lang}</span>
              <span>|</span>
            </div>
            <div style={{ width: '100%', paddingLeft: '5px' }}>
              <span style={{ textAlign: 'center' }}>{displayTags()}</span>
            </div>
            <div
              className={classes.hasTargetId}
              style={{
                visibility:
                  (isAdmin && currentQuestionnaire.isTarget) ||
                  (currentQuestionnaire.idClient !== -1 && currentQuestionnaire.isTarget)
                    ? 'visible'
                    : 'hidden',
              }}
            >
              {currentQuestionnaire.id}
            </div>
          </Grid>
          {isAdmin && currentQuestionnaire.isCustom && !currentQuestionnaire.isTarget ? (
            <Grid item xs={2} justifyContent="center">
              <div className={classes.turnPublic} onClick={handlePublic}>
                {t('rendrePublic')}
              </div>
            </Grid>
          ) : (
            <Grid item xs={2} onClick={handleClick} className={classes.descriptiveIcon}>
              {currentQuestionnaire.child && (
                <div className={classes.iconContainer}>
                  <ChildCare color="action" />
                </div>
              )}
              {mediaTypesArr?.includes('video') && (
                <div className={classes.iconContainer}>
                  <OndemandVideo color="action" />
                </div>
              )}
              {mediaTypesArr?.includes('image') && (
                <div className={classes.iconContainer}>
                  <ImageOutlined color="action" />
                </div>
              )}
              {mediaTypesArr?.includes('audio') && (
                <div className={classes.iconContainer}>
                  <VolumeUpOutlined color="action" />
                </div>
              )}
            </Grid>
          )}
          <Grid item xs={1} className={classes.actionContainer}>
          {currentClient?.id === 915 && (
            <span> ID : {currentQuestionnaire.id} </span>)}
            {!currentClient?.isAdmin && currentQuestionnaire.idClient === -1 ? (
              <div className={classes.lockContainer}>

                <Lock />
              </div>
            ) : (
              <>
             
                <CustomGreenAndBlackSwitch
                  checked={currentQuestionnaire.enProd}
                  onChange={handleEnProd}
                  name="active"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <IconButton
                  className={classes.delete}
                  aria-label="sign out"
                  component="span"
                  onClick={handleClickDeleteButton}
                >
                  <DeleteOutline />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default QuestionnaireItem
