import React, { useEffect, useState } from 'react'

import QuestionnaireModel from '../../../models/questionnaire.model'
import QuestionDetails from '../../question-form/disabled/container'

export interface StateProps {
  questionnaire: QuestionnaireModel | undefined
  idQuestion: number | undefined
}

export interface DispatchesProps {
  getQuestionnaire: (idQuestionnaire: number) => void
}

export interface OwnProps {
  idQuestion?: number
}

export type Props = StateProps & DispatchesProps & OwnProps

const Questions = ({ questionnaire, idQuestion, getQuestionnaire }: Props) => {
  const currentQuestion = questionnaire?.questions.find((question) => question.id === idQuestion)
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(questionnaire)

  useEffect(() => {
    getQuestionnaire(questionnaire?.id!)
  }, [getQuestionnaire, questionnaire?.id])

  useEffect(() => {
    setCurrentQuestionnaire(questionnaire)
  }, [questionnaire])

  return <QuestionDetails idQuestionnaire={currentQuestionnaire?.id!} question={currentQuestion} />
}

export default Questions
